.contact{
  padding-top: 60px;

  &-submit{
    flex-direction: column;

    .btn-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 10px;
    }

  }

  .p-subtitle{
    order: unset;
    display: block;
  }

  .hideform{
    display: none;
  }

  .form-submit{
    margin-top: 15px;
    color: black;
  }

  .reservation-item-container{
    .btn-submit{
      margin: 25px auto 60px auto;
    }
  }

  .contact-ticket{
    a{
      text-decoration: underline;
      font-weight: bold;
    }

    &-header-list{
      margin: 20px 0;
      font-weight: bold;
    }

    &-list{
      list-style-type: circle;
      margin: 0 0 20px 40px;
      line-height: 1.5;
    }

    &-ref{
      font-size: 10px;
    }

    .btn-submit{
      margin-top: 30px;
    }
  }

  .contact-type-2-1, .contact-type-1-4{
    input {
      max-width: 320px;
    }

    .form-group{
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0;
    }
  }

  .contact-message{
    .form-group{
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0;
    }
  }

  .label-helper{
    font-size: 10px;
    font-weight: bold;
    font-family: $font-open;
    color: $flat-blue;
    letter-spacing: normal;
    text-transform: none;
  }
}