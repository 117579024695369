.evenements-pros {
  padding: 80px 0 90px 0;
  background-color: white;

  @media screen and (max-width: 767px) {
    padding: 60px 0;
  }

  &-title {
    margin-left: 329px / 1440px * 100%;
    @media screen and (max-width: 767px) {
      margin: 0 20px;
    }

    @media screen and (max-width: 359px) {
      font-size: 36px;
    }
  }

  &-subtitle {
    margin-left: 429px / 1440px * 100%;
    @media screen and (max-width: 767px) {
      margin: 15px 20px 0 20px;
    }
  }

  .grid-item{
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  }

  .button-promo-container{
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  a.button-promo{
    border-color: $purple;
    color: $purple;
    margin: 30px 20px 0 20px;

    &:hover{
      background-color: $purple;
      color: white;
    }
  }
}