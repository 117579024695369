/* ==========================================================================
   Components / Title
   ========================================================================== */

  .h1-title {

  }

  .h2-title {
    font-size: 57px;
    color: $purple;
    font-family: $font-second;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    padding-bottom: 15px;

    @media screen and (max-width: 767px) {
      font-size: 40px;
    }
    @media screen and (max-width: 465px) {
      font-size: 30px;
    }
  }

  .h3-title {
    font-size: 35px;
    font-weight: 300;
    color: black;
    font-style: normal;

    @media screen and (max-width: 767px) {
      font-size: 24px;
    }

  }

  .h4-title {

  }

  .p-subtitle {
      display: table;
      max-width: 100%;
      font-family: $font-open;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      line-height: 1.7;
      letter-spacing: 0.5px;
      color: black;
      margin-bottom: 15px;
  }