$colors: (
        facebook: #3b5998,
        vk: #45668e,
        twitter: #00aced,
        pinterest: #cb2027,
        linkedin: #007bb6,
        google-plus: #dd4b39
);

.social {
  top: 30px;
  text-align: center;
  position: absolute;
  background: #FFF;
  box-shadow: 0 0 7px 0 rgba(0,0,0,.2);
  border-radius: 5px;
  @include breakpoint(768px) {
    top: 15px;
    box-shadow: none;
  }
  &__item {
    display: inline-block;
    margin: 10px 5px;
  }
  .fa {
    border-radius: 35px;
    color: #818181;
    cursor: pointer;
    display: block;
    font-size: 20px;
    height: 40px;
    line-height: 44px;
    position: relative;
    text-align: center;
    transition: all .2s;
    width: 40px;
    svg {
      width: 20px;
      height: 20px;
      fill: #FFF;
    }
    &-facebook {
      background-color: $facebook;
    }
    &-twitter {
      background-color: $twitter;
    }
    &-linkedin {
      background-color: $linkedin;
    }
    &-pinterest {
      background-color: $pinterest;
    }
    &:after {
      color: #818181;
      font-family: Roboto, Arial, sans-serif;
      font-size: 14px;
      left: 0;
      line-height: 20px;
      position: absolute;
      text-align: center;
      top: 100%;
      width: 100%;
    }
  }
  @each $key, $color in $colors {
    .fa-#{$key}:hover {
      box-shadow: 0 0 8px rgba($color, 0.5);
      color: $color;
    }
  }
}