.product-price {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &-text {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    color: $dark-grey;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }

  &-amount {
    font-family: $font-open;
    font-size: 35px;
    font-weight: bold;
    font-style: normal;
    color: $purple;

    span {
      font-size: 23px;
    }
  }
}

.slider-section-produit {
  position: relative;
  top: -57px;
  left: 0;
  margin-bottom: -10px;
  z-index: 30;
  overflow: hidden;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 767px) {
    top: 0;
    left: 0;
    margin-bottom: 0;
    height: auto;
    overflow: auto;
    flex-direction: column;
  }

  .slider-image-container {
    width: 853px / 1440px * 100%;
    height: 727px;
    overflow: hidden;
    position: relative;
    left: 0;
    top: 0;
    background-color: white;

    .slider-arrow {
      display: none;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: white;
      padding: 15px 15px 11px 15px;
      flex-direction: row;
      align-items: center;
      z-index: 35;

      &.active {
        display: flex;

        @media screen and (max-width: 767px) {
          display: none;
        }
      }

      p {
        font-family: $font-open;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        letter-spacing: 2.5px;
        margin: 0 15px;
      }

      .slick-arrow {
        height: 25px;
        width: 25px;
        padding: 0;
      }

      .slick-prev .icon-arrow {
        transform: rotate(180deg);
      }

      .icon-arrow {
        width: 25px;
        height: 25px;
        fill: $dark-grey;
        background-image: url('../../front/images/arrow-right-grey.svg');
        background-position: center;
        background-color: transparent;
        background-size: 25px 25px;
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
      z-index: 32;
      pointer-events: none;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      //height: 470px;
      height: 280px;
    }

    .slick-slide > div {
      position: relative;
      overflow: hidden;
    }

    .slick-slider, .slick-slider div {
      height: 100%;
      width: 100%;
    }

    img {
      min-height: 100%;
      min-width: 100%;
      width: auto !important;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 31;
      transform: translate(-50%, -50%);
    }

    /*Style quand il y a une image dans le slider*/
    ~ .slider-info-container {
      margin: 0;
      width: 587px / 1440px * 100%;

      .product-title, .product-subtitle {
        text-align: left;
      }

      .product-controls {
        justify-content: flex-start;
      }

      @media screen and (max-width: 767px) {
        width: calc(100% - 40px);
        margin: 0 20px 20px 20px;
        top: -16px;
        //margin: 0 20px -212px 20px;
        //top: -232px;
      }

    }
  }

  /*Style quand il n'y a pas d'image dans le slider + style commun*/
  .slider-info-container {
    z-index: 33;
    width: 1260px / 1440px * 100%;
    padding: 57px ((50px / 1440px) * 100%) 25px ((50px / 1440px) * 100%);
    height: 100%;
    margin: 0 auto;

    .product-title, .product-subtitle {
      text-align: center;
    }

    @media screen and (max-width: 767px) {
      width: calc(100% - 40px);
      height: auto;
      position: relative;
      top: 0;
      left: 0;
      box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.2);
      margin: 0 20px;
      background-color: white;
      padding: 40px 20px 25px 20px;
    }

    .h2-title {
      margin: 60px 0;
      padding: 0;
    }

    .product-controls {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      position: relative;
      font-family: $font-lato;
      font-size: 13px;
      font-weight: bold;
      font-style: normal;
      color: $flat-blue;
      text-transform: uppercase;
      margin-top: 20px;
      letter-spacing: 0.5px;

      @media screen and (max-width: 767px) {
        position: absolute;
        top: 0;
        right: 0;
        margin: 10px;
      }

      > a {
        display: flex;
        align-items: center;

        &:hover {
          text-decoration: underline;
        }
      }

      a.product-controls-print {
        margin-right: 30px;

        @media screen and (max-width: 767px) {
          display: none;
        }
      }

      .icon-print, .icon-share {
        width: 13px;
        height: 13px;
        fill: $flat-blue;
        margin-left: 10px;
      }
    }

    .product-link {
      display: inline-flex;
      font-family: $font-lato;
      font-size: 15px;
      font-weight: bold;
      font-style: normal;
      margin-top: 16px;
      color: $flat-blue;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    .product-link-price {
      margin-top: 37px;
    }

    .product-price {
      margin-top: 32px;
    }
  }
}

/* Tab bar mobile */
.tab-bar {
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px 15px 18px;
    background: #fff;
  }

  .product-price {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .navigation-product-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $whiteColor;
    margin-top: 8px;
    text-align: center;

    li {
      width: calc(50% - 5px);
      line-height: 50px;
      border-radius: 50px;
      font-size: 15px;
      font-weight: 700;
      text-transform: uppercase;

      &.book {
        background-color: $purple;
        border: solid 2px $purple;

        &:hover {
          background-color: white;
          color: $purple;
        }
      }

      &.offer {
        margin-left: 3px;
        background-color: $flat-blue;
        border: solid 2px $flat-blue;

        &:hover {
          background-color: white;
          color: $flat-blue;
        }

        @media screen and (max-width: 767px) {
          margin-left: 10px;
        }
      }

      a {
        display: block;
        padding: 0;
      }
    }
  }
}

.navigation-product {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 14.5px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 1px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: white;
  z-index: 999;
  text-align: center;

  @media screen and (max-width: 767px) {
    box-shadow: none;
    background-color: transparent;
    justify-content: center;
    margin-top: 20px;
  }

  @media screen and (min-width: 991px) {
    &.sticky,
    .creation-compte &.sticky {
      position: fixed;
      top: 0;
      left: 0;
    }
  }

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  ul li a {
    display: block;
    padding: 23px 0;
  }

  ul.navigation-product-left {
    font-weight: 500;
    color: black;
    margin-left: 55px;
    justify-content: flex-start;

    @media screen and (max-width: 1199px) {
      margin-left: 40px;
    }

    @media screen and (max-width: 1049px) {
      margin-left: 30px;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }

    li {
      margin-right: 80px;
      border-top: 2px solid white;
      border-bottom: 2px solid white;

      &.active, &:hover {
        border-bottom: 2px solid $purple;
      }

      @media screen and (max-width: 1399px) {
        margin-right: 40px;
      }

      @media screen and (max-width: 1049px) {
        margin-right: 20px;
      }
    }
  }

  ul.navigation-product-right {
    font-weight: bold;
    color: white;
    justify-content: flex-end;
    text-align: center;

    li {
      width: 125px;

      @media screen and (max-width: 1199px) {
        width: 85px;
      }

      @media screen and (max-width: 767px) {
        height: 50px;
        border-radius: 50px;
        font-size: 15px;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 50px;
        width: 155px;

        a {
          padding: 0;
        }
      }

      @media screen and (max-width: 359px) {
        width: 135px;
      }
    }

    li.book {
      background-color: $purple;
      border: solid 2px $purple;

      &:hover {
        background-color: white;
        color: $purple;
      }
    }

    li.offer {
      margin-left: 3px;
      background-color: $flat-blue;
      border: solid 2px $flat-blue;

      &:hover {
        background-color: white;
        color: $flat-blue;
      }

      @media screen and (max-width: 767px) {
        margin-left: 10px;
      }
    }
  }
}

.navigation-product.sticky + .nos-prestations {
  padding-top: 150px;
}

.nos-prestations {
  padding: 80px (196px / 1440px * 100%) 0 (90px / 1440px * 100%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 1199px) {
    padding: 80px (90px / 1440px * 100%) 0 (90px / 1440px * 100%);
  }

  @media screen and (max-width: 767px) {
    padding: 40px 20px 20px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  select {
    margin: 40px 0 20px 0;
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  &-left {
    flex: 1 1 0%;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
    @media screen and (min-width: 767px) {
      flex: 1 1 50%;
    }

    .h2-title, .h3-title {
      padding-right: 20px;
    }

    .h3-title {
      @media screen and (max-width: 767px) {
        margin-top: 15px;
      }
    }
  }

  .list-prestations {

    margin-top: 110px;

    @media screen and (max-width: 767px) {
      //display: none;
      margin: 40px 0 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      min-width: 100%;
      overflow-x: scroll;

      li {
        font-size: 19px!important;
        //padding: 0!important;
        padding: 10px 18px!important;
        letter-spacing: 2px!important;
        white-space: nowrap;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        border: solid 1px transparent;
        border-bottom: none;

        &:not(:last-of-type) {
          margin-right: 10px;
        }

        /*&:not(.active) .list-prestations-elem {
          max-width: 36px;
          overflow: hidden;
          margin-right: 20px;
          position: relative;

          &:after {
            content: '[...]';
            position: absolute;
            top: 50%;
            right: 0;

          }
        }*/
      }

      li.active {
       // text-decoration: underline;
        border-color: $grey;
        box-shadow: 0 18px 20px 1px #000;

        &:before, &:after {
          display: none;
        }
      }
    }

    &-elem {
      flex: 1 0 auto;
    }

    li {
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 4px;
      color: $grey;
      text-transform: uppercase;
      padding: 15px 0;
      transition: all .4s;
    }

    li.active {
      font-weight: bold;
      color: $purple;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      align-content: flex-start;

      a {
        order: 1;
      }

      &:before {
        content: "";
        margin-left: 20px;
        height: 10px;
        width: 10px;
        background-color: white;
        border: 1px solid $dark-grey;
        border-radius: 50%;
        order: 2;
        flex: 1 0 auto;
      }

      &:after {
        content: "";
        height: 1px;
        width: 100%;
        background-color: $dark-grey;
        display: block;
        float: right;
        order: 3;
      }
    }
  }

  .info-prestations {
    font-family: $font-open;
    max-width: 400px;
    margin-top: 40px;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    color: $dark-blue;
    line-height: 1.8;

    ul {
      list-style-type: circle;
      margin-left: 36px;
    }

    li {
      margin-bottom: 10px;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }

    b {
      font-weight: bold;
    }
  }

  &-infos {
    padding: 30px 50px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    flex: 1 0 0;

    @media screen and (min-width: 767px) {
      flex: 1 1 50%;
    }

    > li {
      display: none;

      &.active {
        display: block;
      }
    }

    p{
      padding: 10px 0;
    }

    p a:not(.btn-underline){
      color: $flat-blue;
      text-decoration: none;

      &:hover{
        text-decoration: underline;
      }
    }

    @media screen and (max-width: 767px) {
      padding: 20px;
      width: 100%;
    }

    &-intro {
      font-family: $font-open;
      font-size: 18px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      color: $dark-blue;
      line-height: 1.7;
      margin-bottom: 20px;

      b {
        font-weight: bold;
      }
    }

    &-beforeul {
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 4px;
      color: $flat-blue;
      text-transform: uppercase;
    }

    ul, &-ul {
      margin: 0 0 0 36px;
      padding: 10px 0;
      font-family: $font-open;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      color: $dark-blue;
      list-style-type: circle;
      line-height: 1.8;
    }

    &-links {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: center;
      flex-wrap: wrap;

      text-transform: uppercase;
      color: $flat-blue;
      font-size: 15px;
      font-weight: bold;
      font-style: normal;

      a {
        margin: 0 50px 10px 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:hover {
          text-decoration: underline;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .icon-container {
        width: 36px;
        height: 36px;

        .icon-hat-menu, .icon-glass {
          width: 20px;
          height: 20px;
        }
      }

      &-text {
        margin-left: 20px;
      }
    }

    &-prices {
      margin-top: 20px;

      > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        @media screen and (max-width: 767px) {
          flex-direction: column;
          justify-content: center;
          padding: 10px 20px;
        }

        &:last-child {
          border-bottom: none;
        }

        &:not(:first-child) {
          .product-price-amount {
            font-size: 29px;
            color: $dark-grey;

            span {
              font-size: 16px;
            }
          }
        }

        .product-price-info {
          &:last-child {
            font-family: $font-lato;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            color: black;
            padding-left: 20px;
            flex: 1 0 0;

            @media screen and (max-width: 767px) {
              padding-left: 0;
            }
          }

          &:first-child {
            font-family: $font-stack-common;
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            color: $dark-grey;
            letter-spacing: 2px;
            @media screen and (min-width: 768px) {
              margin-right: 80px;
            }
          }
        }
      }
      .product-price {
        white-space: nowrap;
      }
    }
  }
}

.nos-prestations-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin-top: 35px;
  padding: 20px 0;
  background-color: $dark-grey;

  @media screen and (max-width: 767px) {
    display: none;
  }

  .btn-full {
    margin: 0 15px;
    padding: 0 65px;
  }

  .btn-blue {
    padding: 0 75px;
    background-color: $flat-blue;

    &:hover {
      color: $flat-blue;
      background-color: transparent;
      border: 2px solid $flat-blue;
    }
  }
  .btn-full:hover {
    background-color: white;
    border: solid 2px white;
  }
}

.section-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: stretch;

  div:first-child {
    flex-grow: 1;
  }

  &-after {
    display: none;
    background-image: url('../../front/images/arrow-down.svg');
    background-repeat: no-repeat;
    width: 24px;
    height: 19px;
    background-size: 14px 9px;
    background-position: center center;
    margin-right: 20px;
    flex-shrink: 0;

    @media screen and (max-width: 767px) {
      display: block;
    }
  }
}

.section-hidden {
  display: block;

  @media screen and (max-width: 767px) {
    display: none;
  }
}

section.active {
  .section-hidden {
    display: block;
  }
  .section-heading-after {
    transform: rotate(180deg);
  }
}

.experience {
  padding-top: 80px;

  @media screen and (max-width: 768px) {
    padding-top: 40px;
  }

  .h2-title {
    margin-left: 345px / 1440px * 100%;

    @media screen and (max-width: 768px) {
      margin: 0 20px;
    }
  }
  .h3-title {
    margin-left: 694px / 1440px * 100%;
    margin-bottom: 50px;

    @media screen and (max-width: 768px) {
      margin: 15px 20px 0 20px;
    }
  }

}

.experience-item-product.experience-item {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 600px;

  font-family: $font-open;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  color: black;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    height: auto;
    width: 100%;
  }

  b {
    font-weight: bold;
  }

  ul {
    padding: 10px 0;
    margin-left: 36px;
    font-weight: normal;
    font-style: normal;
    color: $dark-blue;
    list-style-type: circle;
    line-height: 1.8;
  }

  .experience-item-infos {
    width: 615px / 1440px * 100%;
    padding: 40px 55px 40px 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: 767px) {
      min-height: 600px;
    }

    @media screen and (max-width: 991px) {
      width: 100%;
      padding: 20px;
    }

    p {
      margin-bottom: 20px;
      line-height: 1.7;
    }

    a {
      color: $flat-blue;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    a.btn-underline {
      color: black;
      &:hover {
        text-decoration: none;
        color: $purple;
      }
    }

    &-title {
      font-family: $font-stack-common;
      margin: 10px 0 20px 0;
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: 4px;
      color: $flat-blue;
      text-transform: uppercase;
    }

    &-subtitle, h4 {
      font-family: $font-lato;
      font-size: 22px;
      font-weight: 300;
      font-style: normal;
      color: $dark-blue;
      margin-bottom: 17px;
    }
  }

  .experience-item-float {
    width: 825px / 1440px * 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: stretch;
    position: relative;

    @media screen and (max-width: 991px) {
      width: 100%;
      height: auto;
    }

    &:before {
      @media screen and (max-width: 991px) {
        content: "";
        padding-top: (315px / 560px * 100%);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    a img {
      max-height: 100%;
      min-width: auto;
      max-width: 100%;
      min-height: auto;
    }

    a .icon-container {
      z-index: 100;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 80px;
      width: 80px;
      background-color: white;

      .icon-zoom {
        width: 38px;
        height: 38px;
        fill: $flat-blue;
      }
    }

    a:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.2);
      height: 100%;
      width: 100%;
      z-index: 99;
    }

    &-img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
    }

    > iframe{
      position: absolute;
      top: 0;
      left: 0;
      border: 0;
      height: 100%;
      width: 100%;
      border: 0;
    }
    #map {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}

.experience-item.experience-item-product-reverse {
  .experience-item-float {
    order: 1;
    width: 615px / 1440px * 100%;

    @media screen and (max-width: 991px) {
      width: 100%;
      order: 2;
    }
  }

  .experience-item-infos {
    order: 2;
    padding: 40px 45px 40px 55px;
    width: 825px / 1440px * 100%;

    @media screen and (max-width: 991px) {
      padding: 20px;
      width: 100%;
      order: 1;
    }
  }
}

.informations-pratiques {
  padding: 80px 0 120px 0;

  @media screen and (max-width: 768px) {
    padding: 60px 0;
  }

  .h2-title {
    margin: 0 0 30px (406px / 1440px * 100%);

    @media screen and (max-width: 768px) {
      margin: 0 20px;
    }

    @media screen and (max-width: 359px) {
      font-size: 40px;
    }
  }
}
