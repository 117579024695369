/* ==========================================================================
   Components / Formulaires
   ========================================================================== */

  :-webkit-autofill {
    box-shadow: 0 0 0px 1000px $whiteColor inset;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance:textfield;
  }

  select::-ms-expand {
    display: none;
  }

  label {

  }

  input, textarea {

    &:focus {
      outline-style: none;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .code-response,
  .parsley-error-text,
  .alert-danger {
    margin-top: 8px;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;

    ul li {
      letter-spacing: -0.01rem;
    }
  }
  .parsley-error-text,
  .alert-danger {
    color: #d35b5b;
  }