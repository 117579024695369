/* ==========================================================================
   Layout / Header
   ========================================================================== */

$menu-animation-duration: 400ms;
$menu-animation-timing:   ease-out;

.header  {
  z-index: 1000;
  position: fixed;
  width: 100%;
  max-width: 100vw;

  @media screen and (min-width: 992px) {
    position: relative;
  }

  .topbar-center{
    z-index: 60;
    position: absolute;
    left: 0;
    top: 0;

    &.active{
        .logo-header{
          top: -100vh;
        }
        .topbar-center-text {
          top: 50%;
        }
    }

    @media screen and (max-width: 991px) {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 64px;
    }

    @media screen and (max-width: 767px) {
      top: 20px;
      left: 50%;
    }

    .logo-header{
      position: absolute;
      left: 84px;
      top: 17px;
      width: 162px;
      height: 89px;
      z-index: 45;
      transition: top $menu-animation-duration $menu-animation-timing;

      @media screen and (max-width: 1300px) {
        top: 8px;
        left: 84px;
        width: 90px;
        height: 49px;
      }

      @media screen and (max-width: 991px) {
        position: relative;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 84px;
        height: 45px;
      }

    }

    &-text{
      position: absolute;
      top: -100vh;
      left: 50%;
      transform: translate(-50%,-50%);
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      text-transform: uppercase;
      letter-spacing: 3px;
      transition: top $menu-animation-duration $menu-animation-timing;
    }
  }

  .country{
    margin: 18px 0 0 20px;
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 60;

    @media screen and (max-width: 991px) {
      display: none;
    }

    li:not(.active)
    {
      display: none;
    }

    &.active{
      li:not(.active)
      {
        display: block;
      }

      .icon-arrow{
        transform: rotate(-90deg);
      }
    }

    ul{
      overflow: hidden;
      display: flex;
      flex-direction: column;
      background-color: white;
      margin-right: 5px;

      li.active{
        order: -1;
        margin-bottom: 10px;
      }
    }

    .icon-drapeau-fr{
      display: block;
      width: 20px;
      height: 14px;
    }

    .icon-drapeau-en{
      display: block;
      width: 20px;
      height: 10px;
    }

    .tooglecountry{
      padding: 5px;
    }

    .icon-arrow{
      display: block;
      fill: #1d1d1b;
      transform: rotate(90deg);
      width: 8px;
      height: 8px;
    }
  }

  &:before{
    display: block;
    content: "";
    width: 100%;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.6);

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .topbar-right{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 60;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    @media screen and (max-width: 767px) {
      top: 20px;
    }

    .header-mobile {
      @media screen and (min-width: 992px) {
        display: none;
      }

      padding: 6px;

      .icon-container {
        height: 36px;
        width: 36px;
        margin-left: 5px;

        .icon {
          width: 60%;
          height: 60%;
        }
      }

      p {
        color: #94BEC5;
        font-family: $font-stack-common;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.96px;
        line-height: 15px;
        text-align: center;
      }
      //width: 26px;
      //height: 100%;
    }

    .client{
      @media screen and (max-width: 991px) {
        display: none;
      }

      padding-right: 25px;
      display: flex;
      font-size: 14px;
      color: black;
      font-weight: normal;
      align-items: center;
      letter-spacing: 1px;

      &-flex{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-right: 10px;

        a:last-child{
          color: $flat-blue;
          font-size: 12px;
          font-weight: bold;
        }
      }

      .icon-container{
        width: 26px;
        height: 26px;
        margin-left: 10px;

        .icon-account{
          height: 14px;
          width: 14px;
        }

        .icon-log-off{
          height: 14px;
          width: 14px;
        }

        .icon-phone{
          width: 14px;
          height: 15px;
        }

        .icon-mail{
          width: 14px;
          height: 11px;
        }

        .icon-faq{
          width: 14px;
          height: 14px;
        }
      }

      > span
      {
        line-height:49px;
      }

      &:hover a{
        text-decoration: underline;
      }
    }

    .client-service:after{
      content: "";
      display: block;
      width: 0;
      height: 20px;
      border-right: 1px solid $light-grey;
      padding-right: 25px;
    }

    a:hover{
      .booking{
        color: $purple;
        background-color: white;
      }
    }

    .booking {
      width: 226px;
      background-color: $purple;
      border: 2px solid $purple;
      height: 49px;
      line-height: 49px;
      vertical-align: middle;
      text-transform: uppercase;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 991px) {
        width: 76px;
        font-size: 12px;
        height: 64px;
        line-height: normal;
        text-transform: none;
      }

      .icon-container {

        @media screen and (min-width: 992px) {
          display: none;
        }

        width: 36px;
        height: 36px;
        background-color: white;

        .icon-calendar {
          fill: $purple;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .topbar{
    height: 49px;
    width: 100%;
    z-index: 40;
    position: relative;
    border-bottom: 1px solid $light-grey;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 991px) {
      height: 64px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
      border: none;
    }

  }

  .navigation{
    position: relative;
    z-index: 50;
    display: flex;
    height: 57px;
    font-size: 13px;
    font-weight: bold;
    color: $medium-grey;
    text-align: center;
    justify-content: flex-end;
    transition: top $menu-animation-duration $menu-animation-timing, height $menu-animation-duration $menu-animation-timing;

    &.active{
      max-height: 100vh;
      overflow: auto;
      top: 0;

      height: calc(100vh - 64px - 20px);


      @media screen and (min-width: 992px) {
        max-height: none;
        height: auto;
        overflow: inherit;
      }
    }

    @media screen and (max-width: 991px) {
      top: -1000vh;
      height: 0;
      font-size: 18px;
      font-weight: 500;
      flex-direction: column;
      justify-content: flex-start;
    }

    > ul{
      background-color: white;
      @media screen and (min-width: 991px) {
        display: flex;
      }
      align-items: center;
      justify-content: flex-end;
      padding-left: 20px;
      box-shadow: 10px 5px 20px -5px rgba(0, 0, 0, 0.1);
      position: relative;
      z-index: 58;

      @media screen and (max-width: 991px) {
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0;
      }

      > li{
        max-width: 100px;
        text-transform: uppercase;
        margin-right: 10px;
        position: relative;
        z-index: 53;
        @media screen and (min-width: 1160px) {
          max-width: 110px;
          margin-right: 20px;
        }

        &.push {
          border-top: 3px double #e0e0e0;
        }
        &.push a {
          color: #94bec5;
        }

        @media screen and (min-width: 991px) {
          &.push {
            padding: 0 15px;
            margin: 0;
            max-width: 160px;
            border-top: none;
            &:before {
              content: "";
              border-left: 1px solid #e0e0e0;
              position: absolute;
              height: 20px;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }

        > a{
          display: block;
          width: 100%;
          height: 100%;
          padding: 5px;
          letter-spacing: 0;
          @media screen and (min-width: 1160px) {
            letter-spacing: 1px;
          }
        }

        a:hover{
          color: $purple;
        }

        @media screen and (max-width: 991px) {
          max-width: none;
          width: 100%;
          margin: 0;
          text-transform: none;
          padding: 15px 20px 15px 20px;
          text-align: left;
        }

        &.mobile, &.country-mobile{
          @media screen and (min-width: 992px) {
            display: none;
          }
        }

        &:after{
          @media screen and (max-width: 991px) {
            content: "";
            position: absolute;
            left: 20px;
            bottom: 0;
            width: calc(100% - 40px);
            height: 1px;
            border-bottom: 1px solid $light-grey;
          }
        }
      }

      .country-mobile{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 30px 20px;

        @media screen and (max-width: 991px) {
          padding-bottom: 40px;
        }

        &.active{

          li.active{
            margin-bottom: 10px;
          }

          li:not(.active){
            display: flex;
          }

          .icon-arrow{
            transform: rotate(-90deg);
          }
        }

        ul{
          overflow: hidden;
          display: flex;
          flex-direction: column;
          background-color: white;
          margin-right: 10px;

          li {
            a {
              display: flex;
              flex-direction: row;
              align-items: center;
            }

            &.active {
              order: -1;
            }

            &:not(.active) {
              display: none;
            }
          }
        }

        .icon-drapeau-fr{
          display: block;
          width: 27px;
          height: 18px;
        }

        .icon-drapeau-en{
          display: block;
          width: 27px;
          height: 14px;
        }

        .country-text{
          font-size: 16px;
          font-weight: normal;
          color: black;
          margin-left: 10px;
        }

        .tooglecountry{
          margin-left: 10px;
          padding: 5px;
          width: auto;
          height: auto;
        }

        .icon-arrow{
          transform: rotate(90deg);
          width: 8px;
          height: 8px;
        }
      }

      .client{
        display: flex;
        font-size: 16px;
        color: black;
        font-weight: normal;
        align-items: center;
        background-color: $light-grey;

        > a  {
          width: auto;
          height: auto;
        }

        .icon-container{
          width: 35px;
          height: 35px;
          margin-left: 20px;

          .icon-account{
            height: 18px;
            width: 19px;
          }

          .icon-phone{
            width: 19px;
            height: 20px;
          }

          .icon-mail{
            width: 19px;
            height: 15px;
          }

          .icon-faq{
            width: 19px;
            height: 19px;
          }
        }

        a:hover{
          text-decoration: underline;
        }

        > *:first-child
        {
          margin-right: 33px;
        }
      }
    }

    &:before{
      @media screen and (min-width: 992px) {
        height: 0;
        width: 0;
        content: "";
        display: block;
        position: relative;
        border-top: 57px solid white;
        border-left: 57px solid transparent;
        filter: drop-shadow(0 20px 20px rgba(0, 0, 0, 0.1));
        z-index: 55;
      }
    }

    .pros-and-groups{
      color: $flat-blue;
    }

    ul li.active{
      color: $purple;
    }

    .client-service.mobile span {
      padding: 5px;
      min-width: 105px;
      margin-right: 0;
    }
    .client.mobile a:first-child {
      margin-right: 0;
      min-width: 105px;
    }
  }

  .breadcrumbs{
    position: absolute;
    top: 110px;
    left: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: $font-lato;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    color: white;
    letter-spacing: 0.4px;

    a:hover{
      text-decoration: underline;
    }

    &.breadcrumbs-alernative{

      li{
        color: $dark-blue;
      }

      li:after{
        background-image: url('../../front/images/arrow-grey.svg');
      }

      li:last-child{
        color: $flat-blue;
      }
    }

    @media screen and (max-width: 991px) {
      display: none;
    }

    li{
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    li:after{
      content: "";
      background-image: url('../../front/images/arrow-white.svg');
      background-position: center;
      background-color: transparent;
      background-size: 5px 8px;
      display: block;
      margin: 0 8px;
      width: 5px;
      height: 8px;
    }

    li:last-child{
      &:after{
        display: none;
      }
      color: #bfc9de;
    }
    @media screen and (min-width: 2000px) {
      li {
        color: #bfc9de;
      }
    }
  }
}

.burger-menu {
  margin-left: 20px;
  position: relative;
  width: 40px;
  height: 18px;

  @media screen and (min-width: 992px) {
    display: none;
  }
}

.burger-click-region {
  position: absolute;
  width: 24px;
  height: 18px;
  cursor: pointer;
}

header.notransition{

  .topbar-center.active{
    .logo-header{
      transition: none;
      top: 17px;

      @media screen and (max-width: 1300px) {
        top: 8px;
      }

      @media screen and (max-width: 991px) {
        top: 50%;
      }
    }
    .topbar-center-text{
      transition: none;
      top: -100vh;
    }
  }

  .navigation{
    transition: none;
  }

  .burger-menu-piece, .active .burger-menu-piece, .closing .burger-menu-piece{
    transition: none;
    animation: none;
  }
}

.burger-menu-piece {
  display: block;
  position: absolute;
  width: 24px;
  border-top: 2px solid black;
  transform-origin: 50% 50%;
  transition: transform $menu-animation-duration $menu-animation-timing;

  &:nth-child(1) {
    top: 0;
  }

  &:nth-child(2) {
    top: 8px;
    opacity: 1;
    transition: transform $menu-animation-duration $menu-animation-timing, opacity 0ms linear $menu-animation-duration / 2;
  }

  &:nth-child(3) {
    top: 16px;
  }

  .active & {

    &:nth-child(1) {
      animation: burger-open-top $menu-animation-duration $menu-animation-timing forwards;
    }

    &:nth-child(2) {
      opacity: 0;
      transition: transform $menu-animation-duration $menu-animation-timing, opacity 0ms linear $menu-animation-duration / 2;
    }

    &:nth-child(3) {
      animation: burger-open-bot $menu-animation-duration $menu-animation-timing forwards;
    }
  }

  .closing & {
    &:nth-child(1) {
      animation: burger-close-top $menu-animation-duration $menu-animation-timing forwards;
    }

    &:nth-child(3) {
      animation: burger-close-bot $menu-animation-duration $menu-animation-timing forwards;
    }
  }
}

@keyframes burger-open-top {
  50% {
    transform: translate3d(0, 8px, 0);
  }
  100% {
    transform: translate3d(0, 8px, 0) rotate(45deg);
  }
}

@keyframes burger-open-bot {
  50% {
    transform: translate3d(0, -8px, 0);
  }
  100% {
    transform: translate3d(0, -8px, 0) rotate(-45deg);
  }
}

@keyframes burger-close-top {
  0% {
    transform: translate3d(0, 8px, 0) rotate(45deg);
  }
  50% {
    transform: translate3d(0, 8px, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes burger-close-bot {
  0% {
    transform: translate3d(0, -8px, 0) rotate(-45deg);
  }
  50% {
    transform: translate3d(0, -8px, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
