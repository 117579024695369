.liste-produits{
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 60px 0 0 0;

  .h2-title{
    text-align: center;
  }

  &-subtitle{
    margin: 10px (400px / 1440px * 100%) 60px (400px / 1440px * 100%);
    text-align: center;

    @media screen and (max-width: 767px) {
      margin: 10px 20px 60px 20px;
    }

  }

  .featured-product{
    margin-top: 0;
    margin-bottom: 80px;

    &-info{
      a.btn-full{
        padding: 0 65px;

        @media screen and (max-width: 465px) {
          padding: 0 25px;
        }

        @media screen and (max-width: 359px) {
          padding: 0 13px;
        }
      }
    }
  }
}

.featured-product{
  margin: 80px (90px / 1440px * 100%) 0 (90px / 1440px * 100%);
  width: (100% - (180px / 1440px * 100%));
  max-width: 1440px;

  @media screen and (min-width: 1440px) {
    margin-left: auto;
    margin-right: auto;
  }
  height: 470px;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;

  &:nth-child(2n){

    .featured-product-info{
      right: 635px / 1260px * 100%;
      left: auto;

      @media screen and (max-width: 767px) {
        right: 0;
        left: 0;
      }
    }

    .featured-product-picture{
      right: 0;
      left: auto;

      @media screen and (max-width: 767px) {
        left: 0;
      }
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    margin: 0;
    width: 100%;
    height: auto;
  }


  &-picture {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    height: 470px;
    width: 836px / 1260px * 100%;

    @media screen and (max-width: 767px) {
      width: 100%;
      position: relative;
    }
  }

  &-img{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
  }

  &-info{
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 635px / 1260px * 100%;
    top: 50%;
    transform: translateY(-50%);
    max-width: 50%;

    @media screen and (max-width: 767px) {
      position: relative;
      top: -172px;
      left: auto;
      width: calc(100% - 40px);
      padding: 30px;
      margin: 0 20px -172px 20px;
      text-align: left;
      align-items: center;
      transform: none;
      max-width: 100%;
    }

    background-color: white;

    .item-price{
      display: flex;
      flex-direction: column;
      text-align: left;
      justify-content: flex-start;
      margin-left: (15px / 625px * 100%);

      @media screen and (max-width: 767px) {
        margin: 20px 0 0 0;
      }

      &-text{
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        letter-spacing: 1.5px;
        color: $dark-grey;
        text-transform: uppercase;
      }

      &-amount{
        font-family: $font-open;
        font-size: 27px;
        font-weight: bold;
        font-style: normal;
        color: $purple;
        white-space: nowrap;

        span{
          font-size: 16px;
        }

      }
    }

    &-heading{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

    }

    &-title{
      display: block;
      width: 100%;
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
      color: $flat-blue;
      text-transform: uppercase;
      letter-spacing: 4px;
    }

    &-text{
      margin-top: 15px;
      color: $dark-grey;
      max-width: 100%;

      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }

    .btn-container{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      margin-top: 16px;

      a{
        margin-top: 20px;
      }
    }

    a.btn-trans{
      padding: 0 55px;

      @media screen and (max-width: 465px) {
        padding: 0 20px;
      }

      @media screen and (max-width: 359px) {
        padding: 0 10px;
      }
    }

    a.btn-full{
      padding: 0 40px;

      @media screen and (max-width: 767px) {
        margin-top: 20px;
      }

      @media screen and (max-width: 465px) {
        padding: 0 25px;
      }

      @media screen and (max-width: 359px) {
        padding: 0 13px;
      }

    }
  }
}