.bon-cadeau{
  background-image: url('../../front/images/bon-cadeau.jpg');
  background-size: 100% auto;
  background-position: center;
  padding-top: 647px / 1440px * 100%;
  position: relative;

  @media screen and (max-width: 991px) {
    background-color: $light-grey;
    background-image: url('../../front/images/bon-cadeau-seul.png');
    background-blend-mode: normal;
    background-repeat: no-repeat;
    background-position: bottom;
    padding: 0;
    height: 640px;
  }

  .bon-cadeau-inside{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    > div{
      padding: 50px 0 0 (331px / 1440px * 100%);
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media screen and (max-width: 767px) {
        padding: 41px 20px 0 20px;
      }
    }

    .bon-cadeau-title{
      text-align: left;
      margin: 0;
    }

    .bon-cadeau-subtitle{
      max-width: 370px;
      margin-top: 20px;
    }

    a.btn-full{
      margin-top: 20px;
      padding: 0 75px;
    }
    a.btn-full:hover {
      background-color: #fff;
      border-color: #fff;
    }
  }
}