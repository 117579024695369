/* ==========================================================================
   Components / Buttons
   ========================================================================== */

button {
  background: transparent;
  border: 0;
  cursor: pointer;

  &:focus {
    outline-style: none;
  }
}

.btn {
  text-decoration: none;

  &:focus {
    outline-style: none;
  }
}

.btn-full {
  white-space: nowrap;
  font-family: $font-stack-common;
  display: block;
  height: 50px;
  border-radius: 25px;
  background-color: $purple;
  font-size: 15px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  text-align: center;
  line-height: 50px;
  border: solid 2px transparent;
  letter-spacing: 1px;
  padding: 0 13vw;
  @media screen and (min-width: 425px) {
    padding: 0 50px;
  }

  &:hover {
    color: $purple;
    background-color: transparent;
    border: solid 2px $purple;
  }
}

.btn-trans, .btn-trans-purple {
  white-space: nowrap;
  font-family: $font-stack-common;
  height: 50px;
  border-radius: 25px;
  border: solid 2px white;
  font-size: 15px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  padding: 0 50px;

  &:hover {
    background-color: white;
    color: $dark-grey;
  }

  @media screen and (max-width: 767px) {
    padding: 0 30px 0 30px;
  }
}

.btn-trans-purple {
  border: solid 2px $purple;
  color: $purple;

  &:hover {
    background-color: $purple;
    color: white;
  }
}

.btn-underline {
  margin-top: 30px;
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 5px;
  padding-bottom: 6px;
  border-bottom: 3px solid $purple;
  text-transform: uppercase;
  color: black;
  text-decoration: none;
  line-height: normal;

  &:hover {
    color: $purple;
    text-decoration: none;
  }
}

.reservation-ticket .form-check-container-checkbox .form-check-label {
  color: $whiteColor!important;
}

.form-check-container-checkbox {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;

  .form-check-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border: 1px solid $grey;
    z-index: 10;

    &:after {
      content: "";
      position: absolute;
      display: block;
      left: 7px;
      top: 4px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  input:checked + .form-check-checkmark {
    background-color: $flat-blue;
    border: none;
  }

  .form-check-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .form-check-label {
    padding-left: 40px;
    z-index: 20;
    cursor: pointer;

    b {
      font-weight: bold;
    }

    &.label-form-conditions {
      font-size: 10px;

      ul {
        list-style:disc;
        li {
          list-style-position: inside;
        }
      }
    }
  }
}

.form-check-container-radio {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;

  .form-check-checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 20px;
    width: 20px;
    transform: translateY(-50%);
    background-color: white;
    border: 1px solid $grey;
    border-radius: 50%;
    z-index: 10;

    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 6px;
      height: 6px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background-color: white;
    }
  }

  input:checked + .form-check-checkmark {
    background-color: $flat-blue;
    border: none;
  }

  .form-check-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .form-check-label {
    padding-left: 30px;
    z-index: 20;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

select.select-custom {
  height: 70px;
  width: 100%;
  line-height: 70px;
  font-family: $font-stack-common;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  color: $medium-grey;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  border: 0;
  outline: 0;
  padding: 0 54px 0 20px;
  background-color: white;
  background-image: url('../../front/images/arrow-down.svg');
  background-position: calc(100% - 20px) 50%;
  background-size: 14px 9px;
  background-repeat: no-repeat;

  *:focus {
    border: 0;
    outline: 0;
  }

  option {
    border: 0;
    outline: 0;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-family: $font-stack-common;

    &:disabled {
      color: $purple;
    }
  }
}

[data-tooltip] {
  cursor: pointer;
  position: relative;

  &:before, &:after {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all .15s cubic-bezier(.5, 1, .25, 1);
    z-index: 1;
  }

  &:before {
    font-weight: normal;
    padding: 5px;
    width: 110px;
    border-radius: 3px;
    background: $dark-grey;
    color: #fff;
    content: attr(data-tooltip);
    white-space: pre-wrap;
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
  }

  &:after {
    border: 8px solid transparent;
    width: 0;
    content: "";
    font-size: 0;
    line-height: 0;
  }

  &:hover:before, &:hover:after {
    visibility: visible;
    opacity: 1;
  }

  &.t-top {

    &:before {
      bottom: 100%;
      left: 50%;
      margin-bottom: 5px;
      transform: translateX(-50%);
    }

    &:after {
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-top: 8px solid $dark-grey;
      border-bottom: none;
    }

    &:hover:before, &:hover:after {
      transform: translateX(-50%) translateY(-5px);
    }
  }

  /********** Right Tooltip **********/
  &.t-right {

    &:before {
      top: 50%;
      left: 100%;
      margin-left: 5px;
      transform: translateY(-50%);
    }

    &:after {
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      border-right: 8px solid $dark-grey;
      border-left: none;
    }

    &:hover:before, &:hover:after {
      transform: translateX(5px) translateY(-50%);
    }

  }

  /********** Bottom Tooltip **********/
  &.t-bottom {

    &:before {
      top: 100%;
      left: 50%;
      margin-top: 5px;
      transform: translateX(-50%);
    }

    &:after {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: 8px solid $dark-grey;
      border-top: none;
    }

    &:hover:before, &:hover:after {
      transform: translateX(-50%) translateY(5px);
    }
  }

  /********** Left Tooltip **********/
  &.t-left {

    &:before {
      top: 50%;
      right: 100%;
      margin-right: 5px;
      transform: translateY(-50%);
    }

    &:after {
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      border-left: 8px solid $dark-grey;
      border-right: none;
    }

    &:hover:before, &:hover:after {
      transform: translateX(-5px) translateY(-50%);
    }
  }

  /********** Header Tooltip **********/
  &.t-header {

    &:before {
      font-family: $font-open;
      font-size: 12px;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.8);
      width: 270px;

      &::first-line {
        font-weight: bold;
      }
    }

    &:after {
      border-bottom: 8px solid rgba(0, 0, 0, 0.8);
    }

    &:hover:after {
      transform: translateX(-50%) translateY(2px);
    }

  }

}