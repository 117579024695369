.authentification{
  padding: 60px (90px / 1440px * 100%) 120px (90px / 1440px * 100%);
  .h2-title{
    margin-bottom: 50px;
    text-align: left;

    @media screen and (max-width: 379px) {
      font-size: 43px;
    }

    @media screen and (max-width: 359px) {
      font-size: 38px;
    }

  }

  &-flex-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -10px -5px;
  }

  .new-client{
    @media screen and (max-width: 767px) {
      order: 2;
    }
  }

  .current-client{
    padding: 35px 110px 10px 110px;

    @media screen and (max-width: 767px) {
      order: 1;
      padding: 35px (90px / 1440px * 100%) 10px (90px / 1440px * 100%);
    }

    form{
      width: 100%;
    }

    .btn-full{
      margin: 25px 0 40px 0;
    }

    .form-group{
      margin-bottom: 15px;
      width: 100%;
    }

    a.password-forgotten{
      font-family: $font-open;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      color: black;
      text-align: right;
      align-self: flex-end;

      &:hover{
        text-decoration: underline;
      }
    }

    .authentification-flex-title{
      margin-bottom: 20px;
    }
  }

  &-flex{
    padding: 35px 60px 10px 60px;
    margin: 10px 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 767px) {
      padding: 35px (90px / 1440px * 100%) 10px (90px / 1440px * 100%);
      flex: 1 1 100%;
    }

    .btn-full{
      width: 310px;
      padding: 0;

      @media screen and (max-width: 409px) {
        width: 100%;
      }
    }

    &-title{
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: 4px;
      text-align: center;
      color: $flat-blue;
      text-transform: uppercase;
    }

    .p-subtitle{
      margin-top: 30px;
      max-width: 100%;
      letter-spacing: normal;
      text-align: center;
    }


    .btn-subscribe-container{
      padding: 60px 0 40px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .btn-fb{
        position: relative;
        background-color: $fb-blue;

        &:hover{
          color: $fb-blue;
          background-color: transparent;
          border: solid 2px $fb-blue;
        }

        > span:last-child{
          margin-left: 30px;

          @media screen and (max-width: 380px) {
            margin-left: 0;
          }
        }

        .icon-fb-container{
          position: absolute;
          top: -2px;
          left: -2px;
          height: 50px;
          width: 50px;
          background-color: #5e7ebd;

          @media screen and (max-width: 380px) {
            display: none;
          }

          .icon-facebook{
            width: 9px;
            height: 21px;
          }
        }
      }

      .p-subtitle{
        margin: 0;
        padding: 8px 0;
      }
    }
  }

  .current-client{

    @media screen and (min-width: 1199px) {
      padding: 35px 50px 10px 50px;
      flex: 1 0 50%;
    }
    @media screen and (min-width: 1279px) {
      flex: 1 0 30%;
    }
    @media screen and (min-width: 1499px) {
      flex: 1 1 0;
    }

    .container-buttons-auth {
      @media screen and (min-width: 407px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      @media screen and (min-width: 960px) {
        flex-wrap: nowrap;
      }

      .btn-full:first-child {
        width: 100%;
        @media screen and (min-width: 407px) {
          width: 310px;
        }
        @media screen and (min-width: 960px) {
          width: auto;
          min-width: 35%;
          margin-right: 20px;
        }
      }
    }
    .btn-fb{
      position: relative;
      background-color: $fb-blue;

      @media screen and (min-width: 407px) {
        width: 310px;
        margin-top: 0;
      }
      @media screen and (min-width: 960px) {
        width: 65%;
        margin-top: 25px;
      }

      &:hover{
        color: $fb-blue;
        background-color: transparent;
        border: solid 2px $fb-blue;
      }

      > span:last-child{
        margin-left: 25px;

        @media screen and (max-width: 415px) {
          margin-left: 0;
        }
      }

      .icon-fb-container{
        position: absolute;
        top: -2px;
        left: -2px;
        height: 50px;
        width: 50px;
        background-color: #5e7ebd;

        @media screen and (max-width: 415px) {
          display: none;
        }

        .icon-facebook{
          width: 9px;
          height: 21px;
        }
      }
    }
  }
}