@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
  outline: none;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  /* Image border */
  border: 4px solid white;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  /* Background color behind image.
     This is visible during transitions. */
  background-color: white;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../images/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../images/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../images/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

code[class*=language-],
pre[class*=language-] {
  color: black;
  text-shadow: 0 1px white;
  font-family: Consolas, Monaco, "Andale Mono", monospace;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*=language-]::-moz-selection, pre[class*=language-] ::-moz-selection,
code[class*=language-]::-moz-selection, code[class*=language-] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*=language-]::selection, pre[class*=language-] ::selection,
code[class*=language-]::selection, code[class*=language-] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  code[class*=language-],
  pre[class*=language-] {
    text-shadow: none;
  }
}
/* Code blocks */
pre[class*=language-] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
}

:not(pre) > code[class*=language-],
pre[class*=language-] {
  background: #f5f2f0;
}

/* Inline code */
:not(pre) > code[class*=language-] {
  padding: 0.1em;
  border-radius: 0.3em;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray;
}

.token.punctuation {
  color: #999;
}

.namespace {
  opacity: 0.7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol {
  color: #905;
}

.token.selector,
.token.attr-name,
.token.string,
.token.builtin {
  color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
  color: #a67f59;
  background: hsla(0, 0%, 100%, 0.5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a;
}

.token.regex,
.token.important {
  color: #e90;
}

.token.important {
  font-weight: bold;
}

.token.entity {
  cursor: help;
}

.iti {
  position: relative;
  display: inline-block;
}

.iti * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.iti__hide {
  display: none;
}

.iti__v-hide {
  visibility: hidden;
}

.iti input, .iti input[type=text], .iti input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
}

.iti__flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px;
}

.iti__selected-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 51px;
  padding: 0 6px 0 8px;
}

.iti__arrow {
  margin-left: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555;
}

.iti__country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #CCC;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.iti__country-list--dropup {
  bottom: 100%;
  margin-bottom: -1px;
}

@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal;
  }
}
.iti__flag-box {
  display: inline-block;
  width: 20px;
}

.iti__divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CCC;
}

.iti__country {
  padding: 5px 10px;
  outline: none;
}

.iti__dial-code {
  color: #999;
}

.iti__country.iti__highlight {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti__flag-box, .iti__country-name, .iti__dial-code {
  vertical-align: middle;
}

.iti__flag-box, .iti__country-name {
  margin-right: 6px;
}

.iti--allow-dropdown input, .iti--allow-dropdown input[type=text], .iti--allow-dropdown input[type=tel], .iti--separate-dial-code input, .iti--separate-dial-code input[type=text], .iti--separate-dial-code input[type=tel] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
}

.iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
  right: auto;
  left: 0;
}

.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default;
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent;
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 6px;
}

.iti--container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px;
}

.iti--container:hover {
  cursor: pointer;
}

.iti-mobile .iti--container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed;
}

.iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%;
}

.iti-mobile .iti__country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti__flag {
  width: 20px;
}

.iti__flag.iti__be {
  width: 18px;
}

.iti__flag.iti__ch {
  width: 15px;
}

.iti__flag.iti__mc {
  width: 19px;
}

.iti__flag.iti__ne {
  width: 18px;
}

.iti__flag.iti__np {
  width: 13px;
}

.iti__flag.iti__va {
  width: 15px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-size: 5652px 15px;
  }
}
.iti__flag.iti__ac {
  height: 10px;
  background-position: 0px 0px;
}

.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0px;
}

.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0px;
}

.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0px;
}

.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0px;
}

.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0px;
}

.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0px;
}

.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0px;
}

.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0px;
}

.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0px;
}

.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0px;
}

.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0px;
}

.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0px;
}

.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0px;
}

.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0px;
}

.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0px;
}

.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0px;
}

.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0px;
}

.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0px;
}

.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0px;
}

.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0px;
}

.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0px;
}

.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0px;
}

.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0px;
}

.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0px;
}

.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0px;
}

.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0px;
}

.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0px;
}

.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0px;
}

.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0px;
}

.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0px;
}

.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0px;
}

.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0px;
}

.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0px;
}

.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0px;
}

.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0px;
}

.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0px;
}

.iti__flag.iti__bz {
  height: 14px;
  background-position: -812px 0px;
}

.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0px;
}

.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0px;
}

.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0px;
}

.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0px;
}

.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0px;
}

.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0px;
}

.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0px;
}

.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0px;
}

.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0px;
}

.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0px;
}

.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0px;
}

.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0px;
}

.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0px;
}

.iti__flag.iti__cr {
  height: 12px;
  background-position: -1115px 0px;
}

.iti__flag.iti__cu {
  height: 10px;
  background-position: -1137px 0px;
}

.iti__flag.iti__cv {
  height: 12px;
  background-position: -1159px 0px;
}

.iti__flag.iti__cw {
  height: 14px;
  background-position: -1181px 0px;
}

.iti__flag.iti__cx {
  height: 10px;
  background-position: -1203px 0px;
}

.iti__flag.iti__cy {
  height: 14px;
  background-position: -1225px 0px;
}

.iti__flag.iti__cz {
  height: 14px;
  background-position: -1247px 0px;
}

.iti__flag.iti__de {
  height: 12px;
  background-position: -1269px 0px;
}

.iti__flag.iti__dg {
  height: 10px;
  background-position: -1291px 0px;
}

.iti__flag.iti__dj {
  height: 14px;
  background-position: -1313px 0px;
}

.iti__flag.iti__dk {
  height: 15px;
  background-position: -1335px 0px;
}

.iti__flag.iti__dm {
  height: 10px;
  background-position: -1357px 0px;
}

.iti__flag.iti__do {
  height: 14px;
  background-position: -1379px 0px;
}

.iti__flag.iti__dz {
  height: 14px;
  background-position: -1401px 0px;
}

.iti__flag.iti__ea {
  height: 14px;
  background-position: -1423px 0px;
}

.iti__flag.iti__ec {
  height: 14px;
  background-position: -1445px 0px;
}

.iti__flag.iti__ee {
  height: 13px;
  background-position: -1467px 0px;
}

.iti__flag.iti__eg {
  height: 14px;
  background-position: -1489px 0px;
}

.iti__flag.iti__eh {
  height: 10px;
  background-position: -1511px 0px;
}

.iti__flag.iti__er {
  height: 10px;
  background-position: -1533px 0px;
}

.iti__flag.iti__es {
  height: 14px;
  background-position: -1555px 0px;
}

.iti__flag.iti__et {
  height: 10px;
  background-position: -1577px 0px;
}

.iti__flag.iti__eu {
  height: 14px;
  background-position: -1599px 0px;
}

.iti__flag.iti__fi {
  height: 12px;
  background-position: -1621px 0px;
}

.iti__flag.iti__fj {
  height: 10px;
  background-position: -1643px 0px;
}

.iti__flag.iti__fk {
  height: 10px;
  background-position: -1665px 0px;
}

.iti__flag.iti__fm {
  height: 11px;
  background-position: -1687px 0px;
}

.iti__flag.iti__fo {
  height: 15px;
  background-position: -1709px 0px;
}

.iti__flag.iti__fr {
  height: 14px;
  background-position: -1731px 0px;
}

.iti__flag.iti__ga {
  height: 15px;
  background-position: -1753px 0px;
}

.iti__flag.iti__gb {
  height: 10px;
  background-position: -1775px 0px;
}

.iti__flag.iti__gd {
  height: 12px;
  background-position: -1797px 0px;
}

.iti__flag.iti__ge {
  height: 14px;
  background-position: -1819px 0px;
}

.iti__flag.iti__gf {
  height: 14px;
  background-position: -1841px 0px;
}

.iti__flag.iti__gg {
  height: 14px;
  background-position: -1863px 0px;
}

.iti__flag.iti__gh {
  height: 14px;
  background-position: -1885px 0px;
}

.iti__flag.iti__gi {
  height: 10px;
  background-position: -1907px 0px;
}

.iti__flag.iti__gl {
  height: 14px;
  background-position: -1929px 0px;
}

.iti__flag.iti__gm {
  height: 14px;
  background-position: -1951px 0px;
}

.iti__flag.iti__gn {
  height: 14px;
  background-position: -1973px 0px;
}

.iti__flag.iti__gp {
  height: 14px;
  background-position: -1995px 0px;
}

.iti__flag.iti__gq {
  height: 14px;
  background-position: -2017px 0px;
}

.iti__flag.iti__gr {
  height: 14px;
  background-position: -2039px 0px;
}

.iti__flag.iti__gs {
  height: 10px;
  background-position: -2061px 0px;
}

.iti__flag.iti__gt {
  height: 13px;
  background-position: -2083px 0px;
}

.iti__flag.iti__gu {
  height: 11px;
  background-position: -2105px 0px;
}

.iti__flag.iti__gw {
  height: 10px;
  background-position: -2127px 0px;
}

.iti__flag.iti__gy {
  height: 12px;
  background-position: -2149px 0px;
}

.iti__flag.iti__hk {
  height: 14px;
  background-position: -2171px 0px;
}

.iti__flag.iti__hm {
  height: 10px;
  background-position: -2193px 0px;
}

.iti__flag.iti__hn {
  height: 10px;
  background-position: -2215px 0px;
}

.iti__flag.iti__hr {
  height: 10px;
  background-position: -2237px 0px;
}

.iti__flag.iti__ht {
  height: 12px;
  background-position: -2259px 0px;
}

.iti__flag.iti__hu {
  height: 10px;
  background-position: -2281px 0px;
}

.iti__flag.iti__ic {
  height: 14px;
  background-position: -2303px 0px;
}

.iti__flag.iti__id {
  height: 14px;
  background-position: -2325px 0px;
}

.iti__flag.iti__ie {
  height: 10px;
  background-position: -2347px 0px;
}

.iti__flag.iti__il {
  height: 15px;
  background-position: -2369px 0px;
}

.iti__flag.iti__im {
  height: 10px;
  background-position: -2391px 0px;
}

.iti__flag.iti__in {
  height: 14px;
  background-position: -2413px 0px;
}

.iti__flag.iti__io {
  height: 10px;
  background-position: -2435px 0px;
}

.iti__flag.iti__iq {
  height: 14px;
  background-position: -2457px 0px;
}

.iti__flag.iti__ir {
  height: 12px;
  background-position: -2479px 0px;
}

.iti__flag.iti__is {
  height: 15px;
  background-position: -2501px 0px;
}

.iti__flag.iti__it {
  height: 14px;
  background-position: -2523px 0px;
}

.iti__flag.iti__je {
  height: 12px;
  background-position: -2545px 0px;
}

.iti__flag.iti__jm {
  height: 10px;
  background-position: -2567px 0px;
}

.iti__flag.iti__jo {
  height: 10px;
  background-position: -2589px 0px;
}

.iti__flag.iti__jp {
  height: 14px;
  background-position: -2611px 0px;
}

.iti__flag.iti__ke {
  height: 14px;
  background-position: -2633px 0px;
}

.iti__flag.iti__kg {
  height: 12px;
  background-position: -2655px 0px;
}

.iti__flag.iti__kh {
  height: 13px;
  background-position: -2677px 0px;
}

.iti__flag.iti__ki {
  height: 10px;
  background-position: -2699px 0px;
}

.iti__flag.iti__km {
  height: 12px;
  background-position: -2721px 0px;
}

.iti__flag.iti__kn {
  height: 14px;
  background-position: -2743px 0px;
}

.iti__flag.iti__kp {
  height: 10px;
  background-position: -2765px 0px;
}

.iti__flag.iti__kr {
  height: 14px;
  background-position: -2787px 0px;
}

.iti__flag.iti__kw {
  height: 10px;
  background-position: -2809px 0px;
}

.iti__flag.iti__ky {
  height: 10px;
  background-position: -2831px 0px;
}

.iti__flag.iti__kz {
  height: 10px;
  background-position: -2853px 0px;
}

.iti__flag.iti__la {
  height: 14px;
  background-position: -2875px 0px;
}

.iti__flag.iti__lb {
  height: 14px;
  background-position: -2897px 0px;
}

.iti__flag.iti__lc {
  height: 10px;
  background-position: -2919px 0px;
}

.iti__flag.iti__li {
  height: 12px;
  background-position: -2941px 0px;
}

.iti__flag.iti__lk {
  height: 10px;
  background-position: -2963px 0px;
}

.iti__flag.iti__lr {
  height: 11px;
  background-position: -2985px 0px;
}

.iti__flag.iti__ls {
  height: 14px;
  background-position: -3007px 0px;
}

.iti__flag.iti__lt {
  height: 12px;
  background-position: -3029px 0px;
}

.iti__flag.iti__lu {
  height: 12px;
  background-position: -3051px 0px;
}

.iti__flag.iti__lv {
  height: 10px;
  background-position: -3073px 0px;
}

.iti__flag.iti__ly {
  height: 10px;
  background-position: -3095px 0px;
}

.iti__flag.iti__ma {
  height: 14px;
  background-position: -3117px 0px;
}

.iti__flag.iti__mc {
  height: 15px;
  background-position: -3139px 0px;
}

.iti__flag.iti__md {
  height: 10px;
  background-position: -3160px 0px;
}

.iti__flag.iti__me {
  height: 10px;
  background-position: -3182px 0px;
}

.iti__flag.iti__mf {
  height: 14px;
  background-position: -3204px 0px;
}

.iti__flag.iti__mg {
  height: 14px;
  background-position: -3226px 0px;
}

.iti__flag.iti__mh {
  height: 11px;
  background-position: -3248px 0px;
}

.iti__flag.iti__mk {
  height: 10px;
  background-position: -3270px 0px;
}

.iti__flag.iti__ml {
  height: 14px;
  background-position: -3292px 0px;
}

.iti__flag.iti__mm {
  height: 14px;
  background-position: -3314px 0px;
}

.iti__flag.iti__mn {
  height: 10px;
  background-position: -3336px 0px;
}

.iti__flag.iti__mo {
  height: 14px;
  background-position: -3358px 0px;
}

.iti__flag.iti__mp {
  height: 10px;
  background-position: -3380px 0px;
}

.iti__flag.iti__mq {
  height: 14px;
  background-position: -3402px 0px;
}

.iti__flag.iti__mr {
  height: 14px;
  background-position: -3424px 0px;
}

.iti__flag.iti__ms {
  height: 10px;
  background-position: -3446px 0px;
}

.iti__flag.iti__mt {
  height: 14px;
  background-position: -3468px 0px;
}

.iti__flag.iti__mu {
  height: 14px;
  background-position: -3490px 0px;
}

.iti__flag.iti__mv {
  height: 14px;
  background-position: -3512px 0px;
}

.iti__flag.iti__mw {
  height: 14px;
  background-position: -3534px 0px;
}

.iti__flag.iti__mx {
  height: 12px;
  background-position: -3556px 0px;
}

.iti__flag.iti__my {
  height: 10px;
  background-position: -3578px 0px;
}

.iti__flag.iti__mz {
  height: 14px;
  background-position: -3600px 0px;
}

.iti__flag.iti__na {
  height: 14px;
  background-position: -3622px 0px;
}

.iti__flag.iti__nc {
  height: 10px;
  background-position: -3644px 0px;
}

.iti__flag.iti__ne {
  height: 15px;
  background-position: -3666px 0px;
}

.iti__flag.iti__nf {
  height: 10px;
  background-position: -3686px 0px;
}

.iti__flag.iti__ng {
  height: 10px;
  background-position: -3708px 0px;
}

.iti__flag.iti__ni {
  height: 12px;
  background-position: -3730px 0px;
}

.iti__flag.iti__nl {
  height: 14px;
  background-position: -3752px 0px;
}

.iti__flag.iti__no {
  height: 15px;
  background-position: -3774px 0px;
}

.iti__flag.iti__np {
  height: 15px;
  background-position: -3796px 0px;
}

.iti__flag.iti__nr {
  height: 10px;
  background-position: -3811px 0px;
}

.iti__flag.iti__nu {
  height: 10px;
  background-position: -3833px 0px;
}

.iti__flag.iti__nz {
  height: 10px;
  background-position: -3855px 0px;
}

.iti__flag.iti__om {
  height: 10px;
  background-position: -3877px 0px;
}

.iti__flag.iti__pa {
  height: 14px;
  background-position: -3899px 0px;
}

.iti__flag.iti__pe {
  height: 14px;
  background-position: -3921px 0px;
}

.iti__flag.iti__pf {
  height: 14px;
  background-position: -3943px 0px;
}

.iti__flag.iti__pg {
  height: 15px;
  background-position: -3965px 0px;
}

.iti__flag.iti__ph {
  height: 10px;
  background-position: -3987px 0px;
}

.iti__flag.iti__pk {
  height: 14px;
  background-position: -4009px 0px;
}

.iti__flag.iti__pl {
  height: 13px;
  background-position: -4031px 0px;
}

.iti__flag.iti__pm {
  height: 14px;
  background-position: -4053px 0px;
}

.iti__flag.iti__pn {
  height: 10px;
  background-position: -4075px 0px;
}

.iti__flag.iti__pr {
  height: 14px;
  background-position: -4097px 0px;
}

.iti__flag.iti__ps {
  height: 10px;
  background-position: -4119px 0px;
}

.iti__flag.iti__pt {
  height: 14px;
  background-position: -4141px 0px;
}

.iti__flag.iti__pw {
  height: 13px;
  background-position: -4163px 0px;
}

.iti__flag.iti__py {
  height: 11px;
  background-position: -4185px 0px;
}

.iti__flag.iti__qa {
  height: 8px;
  background-position: -4207px 0px;
}

.iti__flag.iti__re {
  height: 14px;
  background-position: -4229px 0px;
}

.iti__flag.iti__ro {
  height: 14px;
  background-position: -4251px 0px;
}

.iti__flag.iti__rs {
  height: 14px;
  background-position: -4273px 0px;
}

.iti__flag.iti__ru {
  height: 14px;
  background-position: -4295px 0px;
}

.iti__flag.iti__rw {
  height: 14px;
  background-position: -4317px 0px;
}

.iti__flag.iti__sa {
  height: 14px;
  background-position: -4339px 0px;
}

.iti__flag.iti__sb {
  height: 10px;
  background-position: -4361px 0px;
}

.iti__flag.iti__sc {
  height: 10px;
  background-position: -4383px 0px;
}

.iti__flag.iti__sd {
  height: 10px;
  background-position: -4405px 0px;
}

.iti__flag.iti__se {
  height: 13px;
  background-position: -4427px 0px;
}

.iti__flag.iti__sg {
  height: 14px;
  background-position: -4449px 0px;
}

.iti__flag.iti__sh {
  height: 10px;
  background-position: -4471px 0px;
}

.iti__flag.iti__si {
  height: 10px;
  background-position: -4493px 0px;
}

.iti__flag.iti__sj {
  height: 15px;
  background-position: -4515px 0px;
}

.iti__flag.iti__sk {
  height: 14px;
  background-position: -4537px 0px;
}

.iti__flag.iti__sl {
  height: 14px;
  background-position: -4559px 0px;
}

.iti__flag.iti__sm {
  height: 15px;
  background-position: -4581px 0px;
}

.iti__flag.iti__sn {
  height: 14px;
  background-position: -4603px 0px;
}

.iti__flag.iti__so {
  height: 14px;
  background-position: -4625px 0px;
}

.iti__flag.iti__sr {
  height: 14px;
  background-position: -4647px 0px;
}

.iti__flag.iti__ss {
  height: 10px;
  background-position: -4669px 0px;
}

.iti__flag.iti__st {
  height: 10px;
  background-position: -4691px 0px;
}

.iti__flag.iti__sv {
  height: 12px;
  background-position: -4713px 0px;
}

.iti__flag.iti__sx {
  height: 14px;
  background-position: -4735px 0px;
}

.iti__flag.iti__sy {
  height: 14px;
  background-position: -4757px 0px;
}

.iti__flag.iti__sz {
  height: 14px;
  background-position: -4779px 0px;
}

.iti__flag.iti__ta {
  height: 10px;
  background-position: -4801px 0px;
}

.iti__flag.iti__tc {
  height: 10px;
  background-position: -4823px 0px;
}

.iti__flag.iti__td {
  height: 14px;
  background-position: -4845px 0px;
}

.iti__flag.iti__tf {
  height: 14px;
  background-position: -4867px 0px;
}

.iti__flag.iti__tg {
  height: 13px;
  background-position: -4889px 0px;
}

.iti__flag.iti__th {
  height: 14px;
  background-position: -4911px 0px;
}

.iti__flag.iti__tj {
  height: 10px;
  background-position: -4933px 0px;
}

.iti__flag.iti__tk {
  height: 10px;
  background-position: -4955px 0px;
}

.iti__flag.iti__tl {
  height: 10px;
  background-position: -4977px 0px;
}

.iti__flag.iti__tm {
  height: 14px;
  background-position: -4999px 0px;
}

.iti__flag.iti__tn {
  height: 14px;
  background-position: -5021px 0px;
}

.iti__flag.iti__to {
  height: 10px;
  background-position: -5043px 0px;
}

.iti__flag.iti__tr {
  height: 14px;
  background-position: -5065px 0px;
}

.iti__flag.iti__tt {
  height: 12px;
  background-position: -5087px 0px;
}

.iti__flag.iti__tv {
  height: 10px;
  background-position: -5109px 0px;
}

.iti__flag.iti__tw {
  height: 14px;
  background-position: -5131px 0px;
}

.iti__flag.iti__tz {
  height: 14px;
  background-position: -5153px 0px;
}

.iti__flag.iti__ua {
  height: 14px;
  background-position: -5175px 0px;
}

.iti__flag.iti__ug {
  height: 14px;
  background-position: -5197px 0px;
}

.iti__flag.iti__um {
  height: 11px;
  background-position: -5219px 0px;
}

.iti__flag.iti__un {
  height: 14px;
  background-position: -5241px 0px;
}

.iti__flag.iti__us {
  height: 11px;
  background-position: -5263px 0px;
}

.iti__flag.iti__uy {
  height: 14px;
  background-position: -5285px 0px;
}

.iti__flag.iti__uz {
  height: 10px;
  background-position: -5307px 0px;
}

.iti__flag.iti__va {
  height: 15px;
  background-position: -5329px 0px;
}

.iti__flag.iti__vc {
  height: 14px;
  background-position: -5346px 0px;
}

.iti__flag.iti__ve {
  height: 14px;
  background-position: -5368px 0px;
}

.iti__flag.iti__vg {
  height: 10px;
  background-position: -5390px 0px;
}

.iti__flag.iti__vi {
  height: 14px;
  background-position: -5412px 0px;
}

.iti__flag.iti__vn {
  height: 14px;
  background-position: -5434px 0px;
}

.iti__flag.iti__vu {
  height: 12px;
  background-position: -5456px 0px;
}

.iti__flag.iti__wf {
  height: 14px;
  background-position: -5478px 0px;
}

.iti__flag.iti__ws {
  height: 10px;
  background-position: -5500px 0px;
}

.iti__flag.iti__xk {
  height: 15px;
  background-position: -5522px 0px;
}

.iti__flag.iti__ye {
  height: 14px;
  background-position: -5544px 0px;
}

.iti__flag.iti__yt {
  height: 14px;
  background-position: -5566px 0px;
}

.iti__flag.iti__za {
  height: 14px;
  background-position: -5588px 0px;
}

.iti__flag.iti__zm {
  height: 14px;
  background-position: -5610px 0px;
}

.iti__flag.iti__zw {
  height: 10px;
  background-position: -5632px 0px;
}

.iti__flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("../../front/images/flags.png");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag .iti__flag {
    background-image: url("../../front/images/flags@2x.png");
  }
}

.iti__flag.iti__np {
  background-color: transparent;
}

/* @font-face {
   font-family: 'Nom de la Police';
   src: local('Nom de la Police'),
   url('./assets/fonts/nom_de_la_police.woff2') format('woff2'),
   url('./assets/fonts/nom_de_la_police.woff') format('woff');
   font-weight: 300;
   font-style: normal;
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
 }*/
@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;
  src: url("../../front/fonts/slick.eot");
  src: url("../../front/fonts/slick.eot?#iefix") format("embedded-opentype"), url("../../front/fonts/slick.woff") format("woff"), url("../../front/fonts/slick.ttf") format("truetype"), url("../../front/fonts/slick.svg#slick") format("svg");
}
/*-- RESET ALL --*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, select, option {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

* {
  box-sizing: border-box;
}

input, textarea {
  box-sizing: border-box;
  border: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  display: block;
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  font-family: "Alegreya Sans", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
  overflow-x: hidden;
}

b, strong {
  font-weight: 600;
}

ol, ul {
  list-style: none;
}

em, i {
  font-style: italic;
}

a {
  text-decoration: none;
  color: inherit;
}

sup {
  position: relative;
  bottom: 1px;
  font-size: 0.65em;
  line-height: 0.65em;
  vertical-align: super;
  text-decoration: none;
}

.hidden,
.print-only {
  display: none;
}

.text-nowrap {
  white-space: nowrap;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

#page-content {
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
}
@media screen and (min-width: 991px) {
  #page-content {
    padding-top: 0;
  }
}

.message-banner {
  position: relative;
}
@media screen and (max-width: 767px) {
  .message-banner.floating {
    position: absolute;
    top: 110px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    background: #fff;
    z-index: 1000;
    padding: 16px;
    padding-right: 38px;
    box-shadow: 1px 2px 10px 0px grey;
  }
}
@media screen and (max-width: 767px) {
  .message-banner.floating .container-edito {
    text-align: left;
    padding: 0;
  }
}
.message-banner .container-edito {
  color: #2D2D2D;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.42px;
  line-height: 21px;
  text-align: center;
  padding: 10px;
}
.message-banner .container-edito strong {
  color: #be0081;
}
.message-banner .container-edito a {
  display: inline-flex;
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #94bec5;
  text-decoration: underline;
}
.message-banner .container-edito a:hover {
  text-decoration: none;
}
.message-banner .close-banner {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 20px;
}
@media screen and (max-width: 767px) {
  .message-banner .close-banner {
    float: none;
    padding: 0;
    position: absolute;
    top: 10px;
    right: 10px;
    transform: none;
  }
}
.message-banner:not(.floating) {
  width: 100%;
  background: #fff;
  padding: 28px;
}
.message-banner:not(.floating) .container-edito {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.42px;
  text-align: left;
  padding: 0;
}

.popup_content {
  display: block;
  background-color: white;
  padding: 20px;
}
.popup_content section:not(.form-section) p,
.popup_content section:not(.form-section) h4 {
  padding: 10px 0;
}
.popup_content p a:not(.btn-underline) {
  color: #94bec5;
  text-decoration: none;
}
.popup_content p a:not(.btn-underline):hover {
  text-decoration: underline;
}
.popup_content h4 {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 4px;
  color: #94bec5;
  text-transform: uppercase;
}
.popup_content ul {
  margin: 0 0 0 36px;
  padding: 10px 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: #2e333d;
  list-style-type: circle;
  line-height: 1.8;
}

.popup_close {
  position: absolute;
  top: 0;
  right: 0;
}

#back-to-top {
  z-index: 1000;
  position: fixed;
  display: none;
  right: 20px;
  bottom: 100px;
  height: 60px;
  width: 60px;
}
@media screen and (max-width: 767px) {
  #back-to-top {
    height: 40px;
    width: 40px;
    right: 10px;
    bottom: 120px;
  }
}
#back-to-top .icon-arrow {
  fill: white;
  height: 25px;
  width: 25px;
  transform: rotate(-90deg);
}
@media screen and (max-width: 767px) {
  #back-to-top .icon-arrow {
    height: 15px;
    width: 15px;
  }
}

.js-list {
  width: 100%;
}

@media screen and (max-width: 559px) {
  .iti.iti--container {
    z-index: 1000000;
  }
}
/* ==========================================================================
   Components / MIXINS
   ========================================================================== */
/*********************
  BREAKPOINTS
  *********************/
/* ==========================================================================
   Components / Formulaires
   ========================================================================== */
:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #ffffff inset;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield;
}

select::-ms-expand {
  display: none;
}

input:focus, textarea:focus {
  outline-style: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.code-response,
.parsley-error-text,
.alert-danger {
  margin-top: 8px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
}
.code-response ul li,
.parsley-error-text ul li,
.alert-danger ul li {
  letter-spacing: -0.01rem;
}

.parsley-error-text,
.alert-danger {
  color: #d35b5b;
}

/* ==========================================================================
   Components / Buttons
   ========================================================================== */
button {
  background: transparent;
  border: 0;
  cursor: pointer;
}
button:focus {
  outline-style: none;
}

.btn {
  text-decoration: none;
}
.btn:focus {
  outline-style: none;
}

.btn-full {
  white-space: nowrap;
  font-family: "Alegreya Sans", sans-serif;
  display: block;
  height: 50px;
  border-radius: 25px;
  background-color: #be0081;
  font-size: 15px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  text-align: center;
  line-height: 50px;
  border: solid 2px transparent;
  letter-spacing: 1px;
  padding: 0 13vw;
}
@media screen and (min-width: 425px) {
  .btn-full {
    padding: 0 50px;
  }
}
.btn-full:hover {
  color: #be0081;
  background-color: transparent;
  border: solid 2px #be0081;
}

.btn-trans, .btn-trans-purple {
  white-space: nowrap;
  font-family: "Alegreya Sans", sans-serif;
  height: 50px;
  border-radius: 25px;
  border: solid 2px white;
  font-size: 15px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  padding: 0 50px;
}
.btn-trans:hover, .btn-trans-purple:hover {
  background-color: white;
  color: #2d2f38;
}
@media screen and (max-width: 767px) {
  .btn-trans, .btn-trans-purple {
    padding: 0 30px 0 30px;
  }
}

.btn-trans-purple {
  border: solid 2px #be0081;
  color: #be0081;
}
.btn-trans-purple:hover {
  background-color: #be0081;
  color: white;
}

.btn-underline {
  margin-top: 30px;
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 5px;
  padding-bottom: 6px;
  border-bottom: 3px solid #be0081;
  text-transform: uppercase;
  color: black;
  text-decoration: none;
  line-height: normal;
}
.btn-underline:hover {
  color: #be0081;
  text-decoration: none;
}

.reservation-ticket .form-check-container-checkbox .form-check-label {
  color: #ffffff !important;
}

.form-check-container-checkbox {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
}
.form-check-container-checkbox .form-check-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid #c5c5c5;
  z-index: 10;
}
.form-check-container-checkbox .form-check-checkmark:after {
  content: "";
  position: absolute;
  display: block;
  left: 7px;
  top: 4px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.form-check-container-checkbox input:checked + .form-check-checkmark {
  background-color: #94bec5;
  border: none;
}
.form-check-container-checkbox .form-check-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.form-check-container-checkbox .form-check-label {
  padding-left: 40px;
  z-index: 20;
  cursor: pointer;
}
.form-check-container-checkbox .form-check-label b {
  font-weight: bold;
}
.form-check-container-checkbox .form-check-label.label-form-conditions {
  font-size: 10px;
}
.form-check-container-checkbox .form-check-label.label-form-conditions ul {
  list-style: disc;
}
.form-check-container-checkbox .form-check-label.label-form-conditions ul li {
  list-style-position: inside;
}

.form-check-container-radio {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;
}
.form-check-container-radio .form-check-checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  height: 20px;
  width: 20px;
  transform: translateY(-50%);
  background-color: white;
  border: 1px solid #c5c5c5;
  border-radius: 50%;
  z-index: 10;
}
.form-check-container-radio .form-check-checkmark:after {
  content: "";
  position: absolute;
  display: block;
  width: 6px;
  height: 6px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: white;
}
.form-check-container-radio input:checked + .form-check-checkmark {
  background-color: #94bec5;
  border: none;
}
.form-check-container-radio .form-check-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.form-check-container-radio .form-check-label {
  padding-left: 30px;
  z-index: 20;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

select.select-custom {
  height: 70px;
  width: 100%;
  line-height: 70px;
  font-family: "Alegreya Sans", sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  color: #6f6f6f;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  border: 0;
  outline: 0;
  padding: 0 54px 0 20px;
  background-color: white;
  background-image: url("../../front/images/arrow-down.svg");
  background-position: calc(100% - 20px) 50%;
  background-size: 14px 9px;
  background-repeat: no-repeat;
}
select.select-custom *:focus {
  border: 0;
  outline: 0;
}
select.select-custom option {
  border: 0;
  outline: 0;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-family: "Alegreya Sans", sans-serif;
}
select.select-custom option:disabled {
  color: #be0081;
}

[data-tooltip] {
  cursor: pointer;
  position: relative;
  /********** Right Tooltip **********/
  /********** Bottom Tooltip **********/
  /********** Left Tooltip **********/
  /********** Header Tooltip **********/
}
[data-tooltip]:before, [data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.15s cubic-bezier(0.5, 1, 0.25, 1);
  z-index: 1;
}
[data-tooltip]:before {
  font-weight: normal;
  padding: 5px;
  width: 110px;
  border-radius: 3px;
  background: #2d2f38;
  color: #fff;
  content: attr(data-tooltip);
  white-space: pre-wrap;
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}
[data-tooltip]:after {
  border: 8px solid transparent;
  width: 0;
  content: "";
  font-size: 0;
  line-height: 0;
}
[data-tooltip]:hover:before, [data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
[data-tooltip].t-top:before {
  bottom: 100%;
  left: 50%;
  margin-bottom: 5px;
  transform: translateX(-50%);
}
[data-tooltip].t-top:after {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-top: 8px solid #2d2f38;
  border-bottom: none;
}
[data-tooltip].t-top:hover:before, [data-tooltip].t-top:hover:after {
  transform: translateX(-50%) translateY(-5px);
}
[data-tooltip].t-right:before {
  top: 50%;
  left: 100%;
  margin-left: 5px;
  transform: translateY(-50%);
}
[data-tooltip].t-right:after {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  border-right: 8px solid #2d2f38;
  border-left: none;
}
[data-tooltip].t-right:hover:before, [data-tooltip].t-right:hover:after {
  transform: translateX(5px) translateY(-50%);
}
[data-tooltip].t-bottom:before {
  top: 100%;
  left: 50%;
  margin-top: 5px;
  transform: translateX(-50%);
}
[data-tooltip].t-bottom:after {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 8px solid #2d2f38;
  border-top: none;
}
[data-tooltip].t-bottom:hover:before, [data-tooltip].t-bottom:hover:after {
  transform: translateX(-50%) translateY(5px);
}
[data-tooltip].t-left:before {
  top: 50%;
  right: 100%;
  margin-right: 5px;
  transform: translateY(-50%);
}
[data-tooltip].t-left:after {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  border-left: 8px solid #2d2f38;
  border-right: none;
}
[data-tooltip].t-left:hover:before, [data-tooltip].t-left:hover:after {
  transform: translateX(-5px) translateY(-50%);
}
[data-tooltip].t-header:before {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  width: 270px;
}
[data-tooltip].t-header:before::first-line {
  font-weight: bold;
}
[data-tooltip].t-header:after {
  border-bottom: 8px solid rgba(0, 0, 0, 0.8);
}
[data-tooltip].t-header:hover:after {
  transform: translateX(-50%) translateY(2px);
}

/* ==========================================================================
   Components / Title
   ========================================================================== */
.h2-title {
  font-size: 57px;
  color: #be0081;
  font-family: "Playfair Display", serif;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  padding-bottom: 15px;
}
@media screen and (max-width: 767px) {
  .h2-title {
    font-size: 40px;
  }
}
@media screen and (max-width: 465px) {
  .h2-title {
    font-size: 30px;
  }
}

.h3-title {
  font-size: 35px;
  font-weight: 300;
  color: black;
  font-style: normal;
}
@media screen and (max-width: 767px) {
  .h3-title {
    font-size: 24px;
  }
}

.p-subtitle {
  display: table;
  max-width: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: 0.5px;
  color: black;
  margin-bottom: 15px;
}

/* ==========================================================================
   Components / Icons
   ========================================================================== */
.icon-container {
  background-color: #94bec5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-container .icon {
  display: block;
  fill: white;
}

.icon-cross {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
  background-image: url("../../front/images/cross.svg");
  background-size: 15px 15px;
  background-repeat: no-repeat;
}

/* LOADER PRINCIPAL */
.loading:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
}

.loading:after {
  content: "";
  width: 60px;
  height: 40px;
  background-color: #9ec6cc;
  border-radius: 0 6px;
  margin: calc(50vh - 20px) auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
/* ==========================================================================
   Components / Card
   ========================================================================== */
/* ==========================================================================
   Components / WYSIWYG
   ========================================================================== */
.wysiwyg a {
  text-decoration: none;
}
input.parsley-success,
select.parsley-success,
textarea.parsley-success {
  color: #468847;
  background-color: #DFF0D8;
  border: 1px solid #D6E9C6;
}

input.parsley-error,
select.parsley-error,
textarea.parsley-error {
  color: #B94A48;
  background-color: #F2DEDE;
  border: 1px solid #EED3D7;
}

.parsley-errors-list {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  color: #d35b5b;
  margin-top: 8px;
  padding: 0;
  list-style-type: none;
  opacity: 0;
  transition: all 0.3s ease-in;
}

.parsley-errors-list.filled {
  opacity: 1;
}

.-top-right- .datepicker--pointer, .-bottom-right- .datepicker--pointer {
  right: 38px;
}

.datepicker--day-name {
  color: #be0081;
}

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5;
}

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #be0081;
}

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #be0081;
}

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2);
}

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none;
}

/* -------------------------------------------------
    Datepicker cells
   ------------------------------------------------- */
.datepicker--cells {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.datepicker--cell {
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 32px;
  z-index: 1;
}

.datepicker--cell.-focus- {
  background: #f0f0f0;
}

.datepicker--cell.-current- {
  color: #be0081;
}

.datepicker--cell.-current-.-focus- {
  color: #4a4a4a;
}

.datepicker--cell.-current-.-in-range- {
  color: #be0081;
}

.datepicker--cell.-in-range- {
  background: rgba(92, 196, 239, 0.1);
  color: #4a4a4a;
  border-radius: 0;
}

.datepicker--cell.-in-range-.-focus- {
  background-color: rgba(92, 196, 239, 0.2);
}

.datepicker--cell.-disabled- {
  cursor: default;
  color: #aeaeae;
}

.datepicker--cell.-disabled-.-focus- {
  color: #aeaeae;
}

.datepicker--cell.-disabled-.-in-range- {
  color: #a1a1a1;
}

.datepicker--cell.-disabled-.-current-.-focus- {
  color: #aeaeae;
}

.datepicker--cell.-range-from- {
  border: 1px solid rgba(92, 196, 239, 0.5);
  background-color: rgba(92, 196, 239, 0.1);
  border-radius: 4px 0 0 4px;
}

.datepicker--cell.-range-to- {
  border: 1px solid rgba(92, 196, 239, 0.5);
  background-color: rgba(92, 196, 239, 0.1);
  border-radius: 0 4px 4px 0;
}

.datepicker--cell.-range-from-.-range-to- {
  border-radius: 4px;
}

.datepicker--cell.-selected-.disponible,
.datepicker--cell.-selected-.promo,
.datepicker--cell.-selected- {
  color: #fff;
  border: none;
  background: #be0081;
}

.datepicker--cell.promo {
  color: #fff;
  border: none;
  background: #f2c524;
}

.datepicker--cell.disponible {
  color: #fff;
  border: none;
  background: #94bec5;
}

.datepicker--cell.-selected-.-current-.disponible,
.datepicker--cell.-selected-.-current-.promo .datepicker--cell.-selected-.-current- {
  color: #fff;
  border: none;
  background: #be0081;
}

.datepicker--cell.-selected-.-focus- {
  background: #be0081;
}

.datepicker--cell:empty {
  cursor: default;
}

.datepicker--days-names {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 8px 0 3px;
}

.datepicker--day-name {
  color: #be0081;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
}

.datepicker--cell-day {
  width: 14.28571%;
}

.datepicker--cells-months {
  height: 170px;
}

.datepicker--cell-month {
  width: 33.33%;
  height: 25%;
}

.datepicker--years {
  height: 170px;
}

.datepicker--cells-years {
  height: 170px;
}

.datepicker--cell-year {
  width: 25%;
  height: 33.33%;
}

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5;
}

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #be0081;
}

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #be0081;
}

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2);
}

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none;
}

/* -------------------------------------------------
    Datepicker
   ------------------------------------------------- */
.datepickers-container {
  position: absolute;
  left: 0;
  top: 0;
}

@media print {
  .datepickers-container {
    display: none;
  }
}
.datepicker {
  background: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-sizing: content-box;
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: #4a4a4a;
  width: 250px;
  position: absolute;
  left: -100000px;
  opacity: 0;
  transition: opacity 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
  z-index: 100;
}

.datepicker.-from-top- {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

.datepicker.-from-right- {
  -webkit-transform: translateX(8px);
  transform: translateX(8px);
}

.datepicker.-from-bottom- {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}

.datepicker.-from-left- {
  -webkit-transform: translateX(-8px);
  transform: translateX(-8px);
}

.datepicker.active {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  transition: opacity 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease;
}

.datepicker-inline .datepicker {
  border-color: #d7d7d7;
  box-shadow: none;
  position: static;
  left: auto;
  right: auto;
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}

.datepicker-inline .datepicker--pointer {
  display: none;
}

.datepicker--content {
  box-sizing: content-box;
  padding: 4px;
}

.-only-timepicker- .datepicker--content {
  display: none;
}

.datepicker--pointer {
  position: absolute;
  background: #fff;
  border-top: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  width: 10px;
  height: 10px;
  z-index: -1;
}

.-top-left- .datepicker--pointer, .-top-center- .datepicker--pointer, .-top-right- .datepicker--pointer {
  top: calc(100% - 4px);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.-right-top- .datepicker--pointer, .-right-center- .datepicker--pointer, .-right-bottom- .datepicker--pointer {
  right: calc(100% - 4px);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.-bottom-left- .datepicker--pointer, .-bottom-center- .datepicker--pointer, .-bottom-right- .datepicker--pointer {
  bottom: calc(100% - 4px);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}

.-left-top- .datepicker--pointer, .-left-center- .datepicker--pointer, .-left-bottom- .datepicker--pointer {
  left: calc(100% - 4px);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.-top-left- .datepicker--pointer, .-bottom-left- .datepicker--pointer {
  left: 10px;
}

.-top-right- .datepicker--pointer, .-bottom-right- .datepicker--pointer {
  right: 10px;
}

.-top-center- .datepicker--pointer, .-bottom-center- .datepicker--pointer {
  left: calc(50% - 5px);
}

.-left-top- .datepicker--pointer, .-right-top- .datepicker--pointer {
  top: 10px;
}

.-left-bottom- .datepicker--pointer, .-right-bottom- .datepicker--pointer {
  bottom: 10px;
}

.-left-center- .datepicker--pointer, .-right-center- .datepicker--pointer {
  top: calc(50% - 5px);
}

.datepicker--body {
  display: none;
}

.datepicker--body.active {
  display: block;
}

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5;
}

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #be0081;
}

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #be0081;
}

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2);
}

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none;
}

/* -------------------------------------------------
    Navigation
   ------------------------------------------------- */
.datepicker--nav {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  min-height: 32px;
  padding: 4px;
}

.-only-timepicker- .datepicker--nav {
  display: none;
}

.datepicker--nav-title,
.datepicker--nav-action {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.datepicker--nav-action {
  width: 32px;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.datepicker--nav-action:hover {
  background: #f0f0f0;
}

.datepicker--nav-action.-disabled- {
  visibility: hidden;
}

.datepicker--nav-action svg {
  width: 32px;
  height: 32px;
}

.datepicker--nav-action path {
  fill: none;
  stroke: #9c9c9c;
  stroke-width: 2px;
}

.datepicker--nav-title {
  border-radius: 4px;
  padding: 0 8px;
}

.datepicker--nav-title i {
  font-style: normal;
  color: #9c9c9c;
  margin-left: 5px;
}

.datepicker--nav-title:hover {
  background: #f0f0f0;
}

.datepicker--nav-title.-disabled- {
  cursor: default;
  background: none;
}

.datepicker--buttons {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 4px;
  border-top: 1px solid #efefef;
}

.datepicker--button {
  color: #be0081;
  cursor: pointer;
  border-radius: 4px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
}

.datepicker--button:hover {
  color: #4a4a4a;
  background: #f0f0f0;
}

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5;
}

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #be0081;
}

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #be0081;
}

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2);
}

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none;
}

/* -------------------------------------------------
    Timepicker
   ------------------------------------------------- */
.datepicker--time {
  border-top: 1px solid #efefef;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 4px;
  position: relative;
}

.datepicker--time.-am-pm- .datepicker--time-sliders {
  -webkit-flex: 0 1 138px;
  -ms-flex: 0 1 138px;
  flex: 0 1 138px;
  max-width: 138px;
}

.-only-timepicker- .datepicker--time {
  border-top: none;
}

.datepicker--time-sliders {
  -webkit-flex: 0 1 153px;
  -ms-flex: 0 1 153px;
  flex: 0 1 153px;
  margin-right: 10px;
  max-width: 153px;
}

.datepicker--time-label {
  display: none;
  font-size: 12px;
}

.datepicker--time-current {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 14px;
  text-align: center;
  margin: 0 0 0 10px;
}

.datepicker--time-current-colon {
  margin: 0 2px 3px;
  line-height: 1;
}

.datepicker--time-current-hours,
.datepicker--time-current-minutes {
  line-height: 1;
  font-size: 19px;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  position: relative;
  z-index: 1;
}

.datepicker--time-current-hours:after,
.datepicker--time-current-minutes:after {
  content: "";
  background: #f0f0f0;
  border-radius: 4px;
  position: absolute;
  left: -2px;
  top: -3px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  opacity: 0;
}

.datepicker--time-current-hours.-focus-:after,
.datepicker--time-current-minutes.-focus-:after {
  opacity: 1;
}

.datepicker--time-current-ampm {
  text-transform: uppercase;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  color: #9c9c9c;
  margin-left: 6px;
  font-size: 11px;
  margin-bottom: 1px;
}

.datepicker--time-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 11px;
  height: 17px;
  background: linear-gradient(to right, #dedede, #dedede) left 50%/100% 1px no-repeat;
}

.datepicker--time-row:first-child {
  margin-bottom: 4px;
}

.datepicker--time-row input[type=range] {
  background: none;
  cursor: pointer;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
}

.datepicker--time-row input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.datepicker--time-row input[type=range]::-ms-tooltip {
  display: none;
}

.datepicker--time-row input[type=range]:hover::-webkit-slider-thumb {
  border-color: #b8b8b8;
}

.datepicker--time-row input[type=range]:hover::-moz-range-thumb {
  border-color: #b8b8b8;
}

.datepicker--time-row input[type=range]:hover::-ms-thumb {
  border-color: #b8b8b8;
}

.datepicker--time-row input[type=range]:focus {
  outline: none;
}

.datepicker--time-row input[type=range]:focus::-webkit-slider-thumb {
  background: #be0081;
  border-color: #be0081;
}

.datepicker--time-row input[type=range]:focus::-moz-range-thumb {
  background: #be0081;
  border-color: #be0081;
}

.datepicker--time-row input[type=range]:focus::-ms-thumb {
  background: #be0081;
  border-color: #be0081;
}

.datepicker--time-row input[type=range]::-webkit-slider-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  transition: background 0.2s;
}

.datepicker--time-row input[type=range]::-moz-range-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  transition: background 0.2s;
}

.datepicker--time-row input[type=range]::-ms-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  transition: background 0.2s;
}

.datepicker--time-row input[type=range]::-webkit-slider-thumb {
  margin-top: -6px;
}

.datepicker--time-row input[type=range]::-webkit-slider-runnable-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: transparent;
}

.datepicker--time-row input[type=range]::-moz-range-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: transparent;
}

.datepicker--time-row input[type=range]::-ms-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: transparent;
}

.datepicker--time-row input[type=range]::-ms-fill-lower {
  background: transparent;
}

.datepicker--time-row input[type=range]::-ms-fill-upper {
  background: transparent;
}

.datepicker--time-row span {
  padding: 0 12px;
}

.datepicker--time-icon {
  color: #9c9c9c;
  border: 1px solid;
  border-radius: 50%;
  font-size: 16px;
  position: relative;
  margin: 0 5px -1px 0;
  width: 1em;
  height: 1em;
}

.datepicker--time-icon:after, .datepicker--time-icon:before {
  content: "";
  background: currentColor;
  position: absolute;
}

.datepicker--time-icon:after {
  height: 0.4em;
  width: 1px;
  left: calc(50% - 1px);
  top: calc(50% + 1px);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.datepicker--time-icon:before {
  width: 0.4em;
  height: 1px;
  top: calc(50% + 1px);
  left: calc(50% - 1px);
}

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5;
}

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #be0081;
}

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #be0081;
}

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2);
}

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none;
}

.-top-right- .datepicker--pointer, .-bottom-right- .datepicker--pointer {
  right: 38px;
}

.social {
  top: 30px;
  text-align: center;
  position: absolute;
  background: #FFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
@media (min-width: 768px) {
  .social {
    top: 15px;
    box-shadow: none;
  }
}
.social__item {
  display: inline-block;
  margin: 10px 5px;
}
.social .fa {
  border-radius: 35px;
  color: #818181;
  cursor: pointer;
  display: block;
  font-size: 20px;
  height: 40px;
  line-height: 44px;
  position: relative;
  text-align: center;
  transition: all 0.2s;
  width: 40px;
}
.social .fa svg {
  width: 20px;
  height: 20px;
  fill: #FFF;
}
.social .fa-facebook {
  background-color: #3b5998;
}
.social .fa-twitter {
  background-color: #0dcfed;
}
.social .fa-linkedin {
  background-color: #007bb6;
}
.social .fa-pinterest {
  background-color: #cb2027;
}
.social .fa:after {
  color: #818181;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  left: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 100%;
  width: 100%;
}
.social .fa-facebook:hover {
  box-shadow: 0 0 8px rgba(59, 89, 152, 0.5);
  color: #3b5998;
}
.social .fa-vk:hover {
  box-shadow: 0 0 8px rgba(69, 102, 142, 0.5);
  color: #45668e;
}
.social .fa-twitter:hover {
  box-shadow: 0 0 8px rgba(0, 172, 237, 0.5);
  color: #00aced;
}
.social .fa-pinterest:hover {
  box-shadow: 0 0 8px rgba(203, 32, 39, 0.5);
  color: #cb2027;
}
.social .fa-linkedin:hover {
  box-shadow: 0 0 8px rgba(0, 123, 182, 0.5);
  color: #007bb6;
}
.social .fa-google-plus:hover {
  box-shadow: 0 0 8px rgba(221, 75, 57, 0.5);
  color: #dd4b39;
}

/* ==========================================================================
   Layout / Menu Navigation
   ========================================================================== */
/* ==========================================================================
   Layout / Header
   ========================================================================== */
.header {
  z-index: 1000;
  position: fixed;
  width: 100%;
  max-width: 100vw;
}
@media screen and (min-width: 992px) {
  .header {
    position: relative;
  }
}
.header .topbar-center {
  z-index: 60;
  position: absolute;
  left: 0;
  top: 0;
}
.header .topbar-center.active .logo-header {
  top: -100vh;
}
.header .topbar-center.active .topbar-center-text {
  top: 50%;
}
@media screen and (max-width: 991px) {
  .header .topbar-center {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 64px;
  }
}
@media screen and (max-width: 767px) {
  .header .topbar-center {
    top: 20px;
    left: 50%;
  }
}
.header .topbar-center .logo-header {
  position: absolute;
  left: 84px;
  top: 17px;
  width: 162px;
  height: 89px;
  z-index: 45;
  transition: top 400ms ease-out;
}
@media screen and (max-width: 1300px) {
  .header .topbar-center .logo-header {
    top: 8px;
    left: 84px;
    width: 90px;
    height: 49px;
  }
}
@media screen and (max-width: 991px) {
  .header .topbar-center .logo-header {
    position: relative;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 84px;
    height: 45px;
  }
}
.header .topbar-center-text {
  position: absolute;
  top: -100vh;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 3px;
  transition: top 400ms ease-out;
}
.header .country {
  margin: 18px 0 0 20px;
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 60;
}
@media screen and (max-width: 991px) {
  .header .country {
    display: none;
  }
}
.header .country li:not(.active) {
  display: none;
}
.header .country.active li:not(.active) {
  display: block;
}
.header .country.active .icon-arrow {
  transform: rotate(-90deg);
}
.header .country ul {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-right: 5px;
}
.header .country ul li.active {
  order: -1;
  margin-bottom: 10px;
}
.header .country .icon-drapeau-fr {
  display: block;
  width: 20px;
  height: 14px;
}
.header .country .icon-drapeau-en {
  display: block;
  width: 20px;
  height: 10px;
}
.header .country .tooglecountry {
  padding: 5px;
}
.header .country .icon-arrow {
  display: block;
  fill: #1d1d1b;
  transform: rotate(90deg);
  width: 8px;
  height: 8px;
}
.header:before {
  display: block;
  content: "";
  width: 100%;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.6);
}
@media screen and (min-width: 768px) {
  .header:before {
    display: none;
  }
}
.header .topbar-right {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 60;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .header .topbar-right {
    top: 20px;
  }
}
.header .topbar-right .header-mobile {
  padding: 6px;
}
@media screen and (min-width: 992px) {
  .header .topbar-right .header-mobile {
    display: none;
  }
}
.header .topbar-right .header-mobile .icon-container {
  height: 36px;
  width: 36px;
  margin-left: 5px;
}
.header .topbar-right .header-mobile .icon-container .icon {
  width: 60%;
  height: 60%;
}
.header .topbar-right .header-mobile p {
  color: #94BEC5;
  font-family: "Alegreya Sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.96px;
  line-height: 15px;
  text-align: center;
}
.header .topbar-right .client {
  padding-right: 25px;
  display: flex;
  font-size: 14px;
  color: black;
  font-weight: normal;
  align-items: center;
  letter-spacing: 1px;
}
@media screen and (max-width: 991px) {
  .header .topbar-right .client {
    display: none;
  }
}
.header .topbar-right .client-flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 10px;
}
.header .topbar-right .client-flex a:last-child {
  color: #94bec5;
  font-size: 12px;
  font-weight: bold;
}
.header .topbar-right .client .icon-container {
  width: 26px;
  height: 26px;
  margin-left: 10px;
}
.header .topbar-right .client .icon-container .icon-account {
  height: 14px;
  width: 14px;
}
.header .topbar-right .client .icon-container .icon-log-off {
  height: 14px;
  width: 14px;
}
.header .topbar-right .client .icon-container .icon-phone {
  width: 14px;
  height: 15px;
}
.header .topbar-right .client .icon-container .icon-mail {
  width: 14px;
  height: 11px;
}
.header .topbar-right .client .icon-container .icon-faq {
  width: 14px;
  height: 14px;
}
.header .topbar-right .client > span {
  line-height: 49px;
}
.header .topbar-right .client:hover a {
  text-decoration: underline;
}
.header .topbar-right .client-service:after {
  content: "";
  display: block;
  width: 0;
  height: 20px;
  border-right: 1px solid #f7f7f7;
  padding-right: 25px;
}
.header .topbar-right a:hover .booking {
  color: #be0081;
  background-color: white;
}
.header .topbar-right .booking {
  width: 226px;
  background-color: #be0081;
  border: 2px solid #be0081;
  height: 49px;
  line-height: 49px;
  vertical-align: middle;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 991px) {
  .header .topbar-right .booking {
    width: 76px;
    font-size: 12px;
    height: 64px;
    line-height: normal;
    text-transform: none;
  }
}
.header .topbar-right .booking .icon-container {
  width: 36px;
  height: 36px;
  background-color: white;
}
@media screen and (min-width: 992px) {
  .header .topbar-right .booking .icon-container {
    display: none;
  }
}
.header .topbar-right .booking .icon-container .icon-calendar {
  fill: #be0081;
  width: 20px;
  height: 20px;
}
.header .topbar {
  height: 49px;
  width: 100%;
  z-index: 40;
  position: relative;
  border-bottom: 1px solid #f7f7f7;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 991px) {
  .header .topbar {
    height: 64px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    border: none;
  }
}
.header .navigation {
  position: relative;
  z-index: 50;
  display: flex;
  height: 57px;
  font-size: 13px;
  font-weight: bold;
  color: #6f6f6f;
  text-align: center;
  justify-content: flex-end;
  transition: top 400ms ease-out, height 400ms ease-out;
}
.header .navigation.active {
  max-height: 100vh;
  overflow: auto;
  top: 0;
  height: calc(100vh - 64px - 20px);
}
@media screen and (min-width: 992px) {
  .header .navigation.active {
    max-height: none;
    height: auto;
    overflow: inherit;
  }
}
@media screen and (max-width: 991px) {
  .header .navigation {
    top: -1000vh;
    height: 0;
    font-size: 18px;
    font-weight: 500;
    flex-direction: column;
    justify-content: flex-start;
  }
}
.header .navigation > ul {
  background-color: white;
  align-items: center;
  justify-content: flex-end;
  padding-left: 20px;
  box-shadow: 10px 5px 20px -5px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 58;
}
@media screen and (min-width: 991px) {
  .header .navigation > ul {
    display: flex;
  }
}
@media screen and (max-width: 991px) {
  .header .navigation > ul {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
  }
}
.header .navigation > ul > li {
  max-width: 100px;
  text-transform: uppercase;
  margin-right: 10px;
  position: relative;
  z-index: 53;
}
@media screen and (min-width: 1160px) {
  .header .navigation > ul > li {
    max-width: 110px;
    margin-right: 20px;
  }
}
.header .navigation > ul > li.push {
  border-top: 3px double #e0e0e0;
}
.header .navigation > ul > li.push a {
  color: #94bec5;
}
@media screen and (min-width: 991px) {
  .header .navigation > ul > li.push {
    padding: 0 15px;
    margin: 0;
    max-width: 160px;
    border-top: none;
  }
  .header .navigation > ul > li.push:before {
    content: "";
    border-left: 1px solid #e0e0e0;
    position: absolute;
    height: 20px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.header .navigation > ul > li > a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 5px;
  letter-spacing: 0;
}
@media screen and (min-width: 1160px) {
  .header .navigation > ul > li > a {
    letter-spacing: 1px;
  }
}
.header .navigation > ul > li a:hover {
  color: #be0081;
}
@media screen and (max-width: 991px) {
  .header .navigation > ul > li {
    max-width: none;
    width: 100%;
    margin: 0;
    text-transform: none;
    padding: 15px 20px 15px 20px;
    text-align: left;
  }
}
@media screen and (min-width: 992px) {
  .header .navigation > ul > li.mobile, .header .navigation > ul > li.country-mobile {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .header .navigation > ul > li:after {
    content: "";
    position: absolute;
    left: 20px;
    bottom: 0;
    width: calc(100% - 40px);
    height: 1px;
    border-bottom: 1px solid #f7f7f7;
  }
}
.header .navigation > ul .country-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
}
@media screen and (max-width: 991px) {
  .header .navigation > ul .country-mobile {
    padding-bottom: 40px;
  }
}
.header .navigation > ul .country-mobile.active li.active {
  margin-bottom: 10px;
}
.header .navigation > ul .country-mobile.active li:not(.active) {
  display: flex;
}
.header .navigation > ul .country-mobile.active .icon-arrow {
  transform: rotate(-90deg);
}
.header .navigation > ul .country-mobile ul {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-right: 10px;
}
.header .navigation > ul .country-mobile ul li a {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header .navigation > ul .country-mobile ul li.active {
  order: -1;
}
.header .navigation > ul .country-mobile ul li:not(.active) {
  display: none;
}
.header .navigation > ul .country-mobile .icon-drapeau-fr {
  display: block;
  width: 27px;
  height: 18px;
}
.header .navigation > ul .country-mobile .icon-drapeau-en {
  display: block;
  width: 27px;
  height: 14px;
}
.header .navigation > ul .country-mobile .country-text {
  font-size: 16px;
  font-weight: normal;
  color: black;
  margin-left: 10px;
}
.header .navigation > ul .country-mobile .tooglecountry {
  margin-left: 10px;
  padding: 5px;
  width: auto;
  height: auto;
}
.header .navigation > ul .country-mobile .icon-arrow {
  transform: rotate(90deg);
  width: 8px;
  height: 8px;
}
.header .navigation > ul .client {
  display: flex;
  font-size: 16px;
  color: black;
  font-weight: normal;
  align-items: center;
  background-color: #f7f7f7;
}
.header .navigation > ul .client > a {
  width: auto;
  height: auto;
}
.header .navigation > ul .client .icon-container {
  width: 35px;
  height: 35px;
  margin-left: 20px;
}
.header .navigation > ul .client .icon-container .icon-account {
  height: 18px;
  width: 19px;
}
.header .navigation > ul .client .icon-container .icon-phone {
  width: 19px;
  height: 20px;
}
.header .navigation > ul .client .icon-container .icon-mail {
  width: 19px;
  height: 15px;
}
.header .navigation > ul .client .icon-container .icon-faq {
  width: 19px;
  height: 19px;
}
.header .navigation > ul .client a:hover {
  text-decoration: underline;
}
.header .navigation > ul .client > *:first-child {
  margin-right: 33px;
}
@media screen and (min-width: 992px) {
  .header .navigation:before {
    height: 0;
    width: 0;
    content: "";
    display: block;
    position: relative;
    border-top: 57px solid white;
    border-left: 57px solid transparent;
    filter: drop-shadow(0 20px 20px rgba(0, 0, 0, 0.1));
    z-index: 55;
  }
}
.header .navigation .pros-and-groups {
  color: #94bec5;
}
.header .navigation ul li.active {
  color: #be0081;
}
.header .navigation .client-service.mobile span {
  padding: 5px;
  min-width: 105px;
  margin-right: 0;
}
.header .navigation .client.mobile a:first-child {
  margin-right: 0;
  min-width: 105px;
}
.header .breadcrumbs {
  position: absolute;
  top: 110px;
  left: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  color: white;
  letter-spacing: 0.4px;
}
.header .breadcrumbs a:hover {
  text-decoration: underline;
}
.header .breadcrumbs.breadcrumbs-alernative li {
  color: #2e333d;
}
.header .breadcrumbs.breadcrumbs-alernative li:after {
  background-image: url("../../front/images/arrow-grey.svg");
}
.header .breadcrumbs.breadcrumbs-alernative li:last-child {
  color: #94bec5;
}
@media screen and (max-width: 991px) {
  .header .breadcrumbs {
    display: none;
  }
}
.header .breadcrumbs li {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header .breadcrumbs li:after {
  content: "";
  background-image: url("../../front/images/arrow-white.svg");
  background-position: center;
  background-color: transparent;
  background-size: 5px 8px;
  display: block;
  margin: 0 8px;
  width: 5px;
  height: 8px;
}
.header .breadcrumbs li:last-child {
  color: #bfc9de;
}
.header .breadcrumbs li:last-child:after {
  display: none;
}
@media screen and (min-width: 2000px) {
  .header .breadcrumbs li {
    color: #bfc9de;
  }
}

.burger-menu {
  margin-left: 20px;
  position: relative;
  width: 40px;
  height: 18px;
}
@media screen and (min-width: 992px) {
  .burger-menu {
    display: none;
  }
}

.burger-click-region {
  position: absolute;
  width: 24px;
  height: 18px;
  cursor: pointer;
}

header.notransition .topbar-center.active .logo-header {
  transition: none;
  top: 17px;
}
@media screen and (max-width: 1300px) {
  header.notransition .topbar-center.active .logo-header {
    top: 8px;
  }
}
@media screen and (max-width: 991px) {
  header.notransition .topbar-center.active .logo-header {
    top: 50%;
  }
}
header.notransition .topbar-center.active .topbar-center-text {
  transition: none;
  top: -100vh;
}
header.notransition .navigation {
  transition: none;
}
header.notransition .burger-menu-piece, header.notransition .active .burger-menu-piece, header.notransition .closing .burger-menu-piece {
  transition: none;
  animation: none;
}

.burger-menu-piece {
  display: block;
  position: absolute;
  width: 24px;
  border-top: 2px solid black;
  transform-origin: 50% 50%;
  transition: transform 400ms ease-out;
}
.burger-menu-piece:nth-child(1) {
  top: 0;
}
.burger-menu-piece:nth-child(2) {
  top: 8px;
  opacity: 1;
  transition: transform 400ms ease-out, opacity 0ms linear 200ms;
}
.burger-menu-piece:nth-child(3) {
  top: 16px;
}
.active .burger-menu-piece:nth-child(1) {
  animation: burger-open-top 400ms ease-out forwards;
}
.active .burger-menu-piece:nth-child(2) {
  opacity: 0;
  transition: transform 400ms ease-out, opacity 0ms linear 200ms;
}
.active .burger-menu-piece:nth-child(3) {
  animation: burger-open-bot 400ms ease-out forwards;
}
.closing .burger-menu-piece:nth-child(1) {
  animation: burger-close-top 400ms ease-out forwards;
}
.closing .burger-menu-piece:nth-child(3) {
  animation: burger-close-bot 400ms ease-out forwards;
}

@keyframes burger-open-top {
  50% {
    transform: translate3d(0, 8px, 0);
  }
  100% {
    transform: translate3d(0, 8px, 0) rotate(45deg);
  }
}
@keyframes burger-open-bot {
  50% {
    transform: translate3d(0, -8px, 0);
  }
  100% {
    transform: translate3d(0, -8px, 0) rotate(-45deg);
  }
}
@keyframes burger-close-top {
  0% {
    transform: translate3d(0, 8px, 0) rotate(45deg);
  }
  50% {
    transform: translate3d(0, 8px, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes burger-close-bot {
  0% {
    transform: translate3d(0, -8px, 0) rotate(-45deg);
  }
  50% {
    transform: translate3d(0, -8px, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
/* ==========================================================================
   Layout / Footer
   ========================================================================== */
.footer {
  margin-top: 3px;
}

.footer .footer-1 {
  width: 100%;
  background-color: #2d2f38;
  padding: 32px 0 50px 0;
}
.footer .footer-1 .footer-title {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 4px;
  text-align: center;
  text-transform: uppercase;
  color: #94bec5;
}
.footer .footer-1-left .social-icons {
  display: flex;
  margin-top: 32px;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .footer .footer-1-left .social-icons {
    margin-top: 20px;
  }
}
.footer .footer-1-left .icon {
  margin-right: 15px;
  margin-left: 15px;
  fill: white;
}
.footer .footer-1-left .icon-facebook {
  width: 12px;
  height: 25px;
}
.footer .footer-1-left .icon-twitter {
  width: 25px;
  height: 20px;
}
.footer .footer-1-left .icon-instagram {
  width: 25px;
  height: 25px;
}
.footer .footer-1-left .icon-linkedin {
  width: 25px;
  height: 25px;
}
.footer .footer-1-right {
  /*.button-newsletter-container{
    display: flex;
    align-items: center;
    justify-content: center;
  }*/
  /*a.button-newsletter{
    margin: 24px auto 10px auto;
    padding: 0 50px 0 50px;
    height: 50px;
    border-radius: 30px;
    border: solid 2px white;
    font-size: 15px;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    text-align: center;
    line-height: 50px;
    letter-spacing: 1px;

    @media screen and (min-width: 768px) {
      //display: none;
    }

    @media screen and (max-width: 359px) {
      letter-spacing: normal;
    }
  }*/
}
@media screen and (max-width: 767px) {
  .footer .footer-1-right {
    margin-top: 65px;
  }
}
.footer .footer-1-right .email-response {
  color: white;
  padding: 10px 0;
  display: none;
}
.footer .footer-1-right a.button-newsletter {
  display: none;
}
.footer .footer-1-right form.show .form-check-container-checkbox {
  display: flex;
}
.footer .footer-1-right form.show .parsley-errors-list {
  display: block;
}
.footer .footer-1-right .form-check-container-checkbox {
  display: none;
  margin-top: 10px;
  text-align: left;
  color: white;
  font-size: 12px;
  max-width: 300px;
}
.footer .footer-1-right .form-check-container-checkbox .form-check-label {
  padding-left: 30px;
}
.footer .footer-1-right .parsley-errors-list {
  text-align: left;
  display: none;
}
.footer .footer-1-right .form-input {
  border: 0;
  border-bottom: 1px solid #c5c5c5;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .footer .footer-1-right .form-input {
    margin: 0 20px;
  }
}
.footer .footer-1-right .form-input input[type=email] {
  width: 100%;
  margin-top: 32px;
  font-size: 16px;
  font-weight: 300;
  color: white;
  background-color: transparent;
  border: 0;
  font-family: "Alegreya Sans", sans-serif;
}
.footer .footer-1-right .form-input input[type=email]::placeholder {
  font-size: 16px;
  font-weight: 300;
  color: white;
  letter-spacing: 0.3px;
}
.footer .footer-1-right .form-input button[type=submit] {
  width: 18px;
  height: 18px;
  background-image: url("../../front/images/arrow-right.svg");
  background-position: center;
  background-color: transparent;
  background-size: 18px 18px;
  border: 0;
  cursor: pointer;
}
.footer .footer-1-container {
  margin-top: 42px;
  display: flex;
  justify-content: space-around;
  text-align: center;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .footer .footer-1-container {
    flex-direction: column;
  }
}
.footer .footer-1-container .footer-title-right {
  padding: 0 20px 0 20px;
}
.footer .footer-1-container .footer-title-right, .footer .footer-1-container .footer-title-left {
  font-size: 22px;
  font-weight: 300;
  color: white;
}
.footer .footer-1-container .footer-title-right b, .footer .footer-1-container .footer-title-left b {
  font-weight: 500;
}

.footer-2 {
  margin-top: 5px;
  background-color: #dfe5e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 47px 13.6111111111% 45px 13.6111111111%;
}
@media screen and (max-width: 1199px) {
  .footer-2 {
    flex-direction: column;
  }
}
.footer-2 .nav-footer-container {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .footer-2 .nav-footer-container {
    flex-direction: column;
  }
}
.footer-2 .nav-footer-container > img {
  width: 120px;
  height: 120px;
}
@media screen and (max-width: 767px) {
  .footer-2 .nav-footer-container > img {
    width: 82px;
    height: 82px;
  }
}
.footer-2 .nav-footer-container .nav-footer-logo {
  min-width: 150px;
  text-align: center;
}
.footer-2 .nav-footer-container .nav-footer-logo > span {
  display: block;
  font-size: 14px;
  padding: 15px 0;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
}
.footer-2 .nav-footer-container .nav-footer {
  margin: 0 92px 0 92px;
  display: flex;
  font-size: 13px;
  font-weight: normal;
  color: black;
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media screen and (max-width: 767px) {
  .footer-2 .nav-footer-container .nav-footer {
    margin: 24px 20px 0 20px;
    font-weight: bold;
  }
}
@media screen and (max-width: 559px) {
  .footer-2 .nav-footer-container .nav-footer {
    flex-direction: column;
    text-align: center;
  }
}
.footer-2 .nav-footer-container .nav-footer ul li {
  padding: 4px 0;
}
.footer-2 .nav-footer-container .nav-footer ul li a {
  display: inline-flex;
  padding: 4px;
}
.footer-2 .nav-footer-container .nav-footer ul:last-child {
  margin-left: 52px;
}
@media screen and (max-width: 559px) {
  .footer-2 .nav-footer-container .nav-footer ul:last-child {
    margin-left: 0;
  }
}
.footer-2 .labels {
  text-align: center;
}
@media screen and (max-width: 1199px) {
  .footer-2 .labels {
    margin-top: 26px;
  }
}
.footer-2 .labels .payment-methods-text {
  text-transform: uppercase;
  margin-top: 39px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 5px;
  color: #2d2f38;
}
.footer-2 .labels .badges {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .footer-2 .labels .badges {
    flex-wrap: wrap;
  }
}
.footer-2 .labels .badges img {
  width: auto;
}
@media screen and (max-width: 767px) {
  .footer-2 .labels .badges img {
    height: 64px;
    width: auto;
    margin: 10px;
  }
}
.footer-2 .labels .badges-pass-nantes {
  margin: 0 20px 0 20px;
}
.footer-2 .labels .badges-lavelodyssee {
  margin: 0 0 0 20px;
}

.payment-methods {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
.payment-methods img {
  width: 66px;
  height: 43px;
}
@media screen and (max-width: 767px) {
  .payment-methods img {
    width: 51px;
    height: 33px;
  }
}
.payment-methods img.method-visa {
  margin: 0 20px 0 20px;
}

.footer-3 {
  padding: 20px 0 6px 0;
  margin: 0;
}
.footer-3 ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.footer-3 ul li {
  padding: 0 14px;
  margin-bottom: 14px;
}
.footer-3 ul li a {
  font-size: 14px;
  font-weight: normal;
  color: #2d2f38;
  text-decoration: none;
  padding: 6px;
}

.rendez-vous {
  overflow: hidden;
  padding: 80px 90px;
  background-color: #2d2f38;
}
@media screen and (max-width: 990px) {
  .rendez-vous {
    padding: 80px 45px;
  }
}
@media screen and (max-width: 465px) {
  .rendez-vous {
    padding: 80px 14px;
  }
}
.rendez-vous-title {
  /*margin-left: 439px / 1440px * 100%;*/
}
@media screen and (max-width: 767px) {
  .rendez-vous-title {
    margin: 0 20px;
    text-align: center;
  }
}
.rendez-vous-subtitle {
  color: white;
  /*margin-left: 543px / 1440px * 100%;*/
}
@media screen and (max-width: 767px) {
  .rendez-vous-subtitle {
    margin: 15px 20px 0 20px;
    text-align: center;
  }
}
.rendez-vous .button-promo-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.rendez-vous a.button-promo {
  margin: 50px 0 80px 0;
}
@media screen and (max-width: 767px) {
  .rendez-vous a.button-promo {
    margin: 50px 0 60px 0;
  }
}
@media screen and (max-width: 359px) {
  .rendez-vous a.button-promo {
    padding: 0 20px;
  }
}
@media screen and (max-width: 901px) {
  .rendez-vous:not(.evenements-pros) .grid-container {
    display: none;
  }
}

.slider-rdv-container {
  width: 100%;
  max-height: 800px;
  position: relative;
  left: 0;
  top: 0;
  margin-top: 50px;
  /*@media screen and (min-width: 902px) {
    display: none;
  }*/
}
@media screen and (max-width: 465px) {
  .slider-rdv-container {
    margin: 26px 0 0 !important;
    padding: 0 !important;
  }
}
.slider-rdv-container .slider-arrow {
  display: none;
  position: absolute;
  top: -100px;
  right: 0;
  background: transparent;
  padding: 15px 15px 11px 15px;
  flex-direction: row;
  align-items: center;
  z-index: 35;
}
.slider-rdv-container .slider-arrow.active {
  display: flex;
}
@media screen and (max-width: 767px) {
  .slider-rdv-container .slider-arrow.active {
    width: calc(100% + 60px + 7px);
    right: -33px;
    top: 55%;
    transform: translateY(-50%);
    justify-content: space-between;
  }
}
.slider-rdv-container .slider-arrow p {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 2.5px;
  margin: 0 15px;
}
.slider-rdv-container .slider-arrow .slick-arrow {
  height: 40px;
  width: 40px;
  padding: 8px;
  border: solid 1px #fff;
  border-radius: 50%;
  margin: 0 5px;
  /*&.slick-prev:not(:first-of-type), &.slick-next:not(:first-of-type) {
    display: none;
    visibility: hidden;
  }*/
}
@media screen and (max-width: 767px) {
  .slider-rdv-container .slider-arrow .slick-arrow {
    background: #000;
    box-shadow: 0px 0px 20px 3px #000;
  }
}
.slider-rdv-container .slider-arrow .slick-prev .icon-arrow {
  transform: rotate(90deg);
}
.slider-rdv-container .slider-arrow .slick-next .icon-arrow {
  transform: rotate(-90deg);
}
.slider-rdv-container .slider-arrow .icon-arrow {
  width: 22px;
  height: 22px;
  fill: #2d2f38;
  background-image: url("../../front/images/arrow-down.svg");
  background-position: center;
  background-color: transparent;
  background-size: 22px 22px;
}
@media screen and (max-width: 767px) {
  .slider-rdv-container .slick-list {
    overflow: visible;
  }
}
.slider-rdv-container .slick-slide {
  padding: 0 10px;
}
@media screen and (max-width: 767px) {
  .slider-rdv-container .slick-slide {
    transition: ease-in-out 0.3s;
  }
  .slider-rdv-container .slick-slide:not(.slick-current) {
    opacity: 0.3;
  }
}
.slider-rdv-container .grid-item {
  float: none !important;
  width: 100%;
  margin-bottom: 0;
}

.grid-container {
  margin: 50px 6.25% 0 6.25%;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .grid-container {
    margin: 26px 6.25% 0 6.25%;
  }
}
@media screen and (max-width: 465px) {
  .grid-container {
    margin: 26px 20px 0 20px;
  }
}
.grid-container .grid-header {
  max-width: 1259px;
  margin: 0 auto;
}
.grid-container .grid {
  margin: 0 auto;
  min-height: 600px;
}
.grid-container .grid .grid-item,
.grid-container .grid .grid-sizer {
  width: 413px;
}
@media screen and (max-width: 1439px) {
  .grid-container .grid .grid-item,
  .grid-container .grid .grid-sizer {
    width: 390px;
  }
}
@media screen and (max-width: 465px) {
  .grid-container .grid .grid-item,
  .grid-container .grid .grid-sizer {
    width: 320px;
  }
}
@media screen and (max-width: 359px) {
  .grid-container .grid .grid-item,
  .grid-container .grid .grid-sizer {
    width: 100%;
  }
}

.grid-item {
  float: left;
  background-color: white;
  margin-bottom: 10px;
}
@media screen and (max-width: 465px) {
  .grid-item {
    margin-bottom: 20px;
  }
}
.grid-item .item-thumbnail-container {
  position: relative;
  width: 100%;
  height: 302px;
  overflow: hidden;
}
@media screen and (max-width: 465px) {
  .grid-item .item-thumbnail-container {
    height: 257px;
  }
}
.grid-item img.item-thumbnail {
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  min-width: 100%;
  min-height: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.grid-item .item-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 30px;
  text-align: left;
  position: relative;
}
.grid-item .item-info-title {
  font-size: 31px;
  line-height: 32px;
  font-weight: 300;
  font-style: normal;
  color: #2d2f38;
  text-transform: uppercase;
  max-width: calc(100% - 125px);
}
.grid-item .item-info-title.xlarge {
  padding-right: 0;
  max-width: 100%;
}
.grid-item .item-info .item-date {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: #2d2f38;
  margin-top: 8px;
  text-transform: uppercase;
}
.grid-item .item-info .item-text {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  margin-top: 14px;
  color: #2d2f38;
  line-height: 1.5;
  letter-spacing: 0.5px;
  max-width: 100%;
}
.grid-item .item-info .btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}
.grid-item .item-info .btn-container a.btn-full, .grid-item .item-info .btn-container a.btn-trans {
  margin-top: 20px;
}
.grid-item .item-info a.btn-full {
  margin-top: 36px;
  padding: 0 45px;
}
@media screen and (max-width: 465px) {
  .grid-item .item-info a.btn-full {
    padding: 0 25px;
  }
}
@media screen and (max-width: 359px) {
  .grid-item .item-info a.btn-full {
    padding: 0 13px;
  }
}
.grid-item .item-info a.btn-trans {
  margin-top: 36px;
  padding: 0 35px;
}
@media screen and (max-width: 465px) {
  .grid-item .item-info a.btn-trans {
    padding: 0 20px;
  }
}
@media screen and (max-width: 359px) {
  .grid-item .item-info a.btn-trans {
    padding: 0 10px;
  }
}
.grid-item .item-info .icon-container {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 0;
  right: 10px;
  transform: translateY(-50%);
}
.grid-item .item-info .icon-calendar {
  width: 30px;
  height: 30px;
  fill: white;
}
.grid-item .item-promo {
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 4px;
  color: white;
  background-color: #2ab0eb;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-50%);
  padding: 8px;
}
.grid-item .item-quantity {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 0 -20px 0;
}
.grid-item .item-quantity > * {
  margin: 20px 0;
}
.grid-item .item-quantity > div:first-child {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
}
.grid-item .item-quantity .icon-container {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  transform: none;
  width: 35px;
  height: 35px;
  font-family: "Open Sans", sans-serif;
  font-size: 26px;
  font-weight: normal;
  font-style: normal;
  color: white;
  user-select: none;
  cursor: pointer;
  flex: 0 0 auto;
}
.grid-item .item-quantity .quantity-number {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  color: #c5c5c5;
  margin: 0 20px;
  max-width: 30px;
  text-align: center;
}
.grid-item .item-quantity .item-price {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  margin: 0;
}
.grid-item .item-price {
  position: absolute;
  top: 30px;
  right: 15px;
  display: flex;
  flex-direction: row;
  text-align: right;
  justify-content: flex-start;
  align-items: flex-end;
}
.grid-item .item-price.item-discount {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  color: #be0081;
  max-width: 120px;
  margin-top: 10px;
}
@media screen and (max-width: 767px) {
  .grid-item .item-price, .grid-item .item-price.item-discount {
    position: relative;
    margin-top: 10px;
    text-align: left;
    max-width: 100%;
    top: 0;
    right: 0;
  }
}
.grid-item .item-price-text {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 1.5px;
  color: #2d2f38;
  margin-right: 30px;
  text-transform: uppercase;
}
.grid-item .item-price-amount {
  font-family: "Open Sans", sans-serif;
  font-size: 27px;
  font-weight: bold;
  font-style: normal;
  color: #be0081;
}
.grid-item .item-price-amount .per_person {
  font-size: 12px;
}

.evenements-pros {
  padding: 80px 0 90px 0;
  background-color: white;
}
@media screen and (max-width: 767px) {
  .evenements-pros {
    padding: 60px 0;
  }
}
.evenements-pros-title {
  margin-left: 22.8472222222%;
}
@media screen and (max-width: 767px) {
  .evenements-pros-title {
    margin: 0 20px;
  }
}
@media screen and (max-width: 359px) {
  .evenements-pros-title {
    font-size: 36px;
  }
}
.evenements-pros-subtitle {
  margin-left: 29.7916666667%;
}
@media screen and (max-width: 767px) {
  .evenements-pros-subtitle {
    margin: 15px 20px 0 20px;
  }
}
.evenements-pros .grid-item {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 767px) {
  .evenements-pros .button-promo-container {
    flex-direction: column;
  }
}
.evenements-pros a.button-promo {
  border-color: #be0081;
  color: #be0081;
  margin: 30px 20px 0 20px;
}
.evenements-pros a.button-promo:hover {
  background-color: #be0081;
  color: white;
}

.bon-cadeau {
  background-image: url("../../front/images/bon-cadeau.jpg");
  background-size: 100% auto;
  background-position: center;
  padding-top: 44.9305555556%;
  position: relative;
}
@media screen and (max-width: 991px) {
  .bon-cadeau {
    background-color: #f7f7f7;
    background-image: url("../../front/images/bon-cadeau-seul.png");
    background-blend-mode: normal;
    background-repeat: no-repeat;
    background-position: bottom;
    padding: 0;
    height: 640px;
  }
}
.bon-cadeau .bon-cadeau-inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bon-cadeau .bon-cadeau-inside > div {
  padding: 50px 0 0 22.9861111111%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media screen and (max-width: 767px) {
  .bon-cadeau .bon-cadeau-inside > div {
    padding: 41px 20px 0 20px;
  }
}
.bon-cadeau .bon-cadeau-inside .bon-cadeau-title {
  text-align: left;
  margin: 0;
}
.bon-cadeau .bon-cadeau-inside .bon-cadeau-subtitle {
  max-width: 370px;
  margin-top: 20px;
}
.bon-cadeau .bon-cadeau-inside a.btn-full {
  margin-top: 20px;
  padding: 0 75px;
}
.bon-cadeau .bon-cadeau-inside a.btn-full:hover {
  background-color: #fff;
  border-color: #fff;
}

.slider-section {
  position: relative;
  top: -57px;
  left: 0;
  z-index: 30;
  height: 763px;
  overflow: hidden;
}
@media screen and (max-width: 991px) {
  .slider-section {
    height: 556px;
    top: 0;
    left: 0;
  }
}
@media screen and (max-width: 767px) {
  .slider-section {
    height: 490px;
  }
}
.slider-section .video-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 45;
  overflow: hidden;
}
.slider-section .video-background .video-foreground, .slider-section .video-background .video-foreground iframe {
  position: absolute;
  pointer-events: none;
  z-index: 45;
  top: 0;
  left: 0;
}
.slider-section .video-background .video-foreground {
  height: 150%;
  width: 150%;
  top: -25%;
  left: -25%;
}
.slider-section .video-background .video-foreground iframe {
  border: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 991px) {
  .slider-section .video-background {
    display: none;
  }
}
.slider-section .video-background ~ .hero:after {
  visibility: visible;
}
.slider-section .hero-img {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  min-width: 100%;
  z-index: 40;
}
@media screen and (max-width: 991px) {
  .slider-section .hero-img {
    display: none;
  }
}
.slider-section .hero-img-mobile {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  min-width: 100%;
  z-index: 40;
}
@media screen and (min-width: 991px) {
  .slider-section .hero-img-mobile {
    display: none;
  }
}
.slider-section .hero {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 763px;
  z-index: 50;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
}
@media screen and (max-width: 991px) {
  .slider-section .hero {
    height: 556px;
  }
}
@media screen and (max-width: 767px) {
  .slider-section .hero {
    height: 490px;
  }
}
.slider-section .hero:after {
  visibility: hidden;
  content: "";
  display: block;
  width: 2px;
  height: 42px;
  background-color: white;
  margin: 0 auto;
}
.slider-section .hero-tripadvisor {
  position: absolute;
  left: 50%;
  bottom: 70px;
  transform: translateX(200px);
}
@media screen and (max-width: 767px) {
  .slider-section .hero-tripadvisor {
    display: none;
  }
}
.slider-section .hero-text {
  display: flex;
  flex-direction: column-reverse;
  max-width: 590px;
}
.slider-section .hero-text-title {
  color: white;
  text-align: center;
  font-size: 92px;
  font-family: "Playfair Display", serif;
  font-weight: normal;
  font-style: normal;
}
@media screen and (max-width: 991px) {
  .slider-section .hero-text-title {
    font-size: 45px;
  }
}
.slider-section .hero-text-subtile {
  margin-top: 20px;
  font-size: 26px;
  font-weight: 500;
  font-style: italic;
  color: white;
  text-align: center;
}
@media screen and (max-width: 991px) {
  .slider-section .hero-text-subtile {
    font-size: 20px;
  }
}
.slider-section .hero .button-book-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-section .hero a.button-book {
  pointer-events: auto;
  margin: 30px 0 0 0;
  padding: 0 70px;
}

.experience-section {
  width: 100%;
  padding: 18px 0 80px 0;
}
@media screen and (max-width: 767px) {
  .experience-section {
    padding: 50px 0 0 0;
  }
}
.experience-section-title {
  margin-left: 15.4861111111%;
}
@media screen and (max-width: 767px) {
  .experience-section-title {
    margin: 0 20px;
  }
}
.experience-section-subtitle {
  margin-left: 22.9861111111%;
}
@media screen and (max-width: 767px) {
  .experience-section-subtitle {
    margin: 15px 20px;
  }
}
.experience-section .experience-pictures {
  margin-top: 50px;
  width: 100%;
  display: flex;
}
@media screen and (max-width: 767px) {
  .experience-section .experience-pictures {
    margin-top: 20px;
  }
}
@media screen and (max-width: 767px) {
  .experience-section .experience-pictures {
    flex-direction: column;
  }
}
.experience-section .experience-pictures .experience-left, .experience-section .experience-pictures .experience-right {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .experience-section .experience-pictures .experience-left, .experience-section .experience-pictures .experience-right {
    width: 100%;
  }
}
.experience-section .experience-pictures .experience-item-container-1, .experience-section .experience-pictures .experience-item-container-2 {
  margin-right: 5px;
}
.experience-section .experience-pictures .experience-item-container-2 {
  margin-left: 27.2727272727%;
}
.experience-section .experience-pictures .experience-item-container-3 {
  margin-right: 27.2727272727%;
}
.experience-section .experience-pictures .experience-item-container-3, .experience-section .experience-pictures .experience-item-container-4 {
  margin-left: 5px;
}
.experience-section .experience-pictures .experience-item-container {
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .experience-section .experience-pictures .experience-item-container {
    margin: 0 0 10px 0;
  }
}
.experience-section .experience-pictures .experience-item-container .experience-item-img {
  position: absolute;
  width: 100%;
  height: auto;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: all 0.4s;
}
@media screen and (max-width: 430px) {
  .experience-section .experience-pictures .experience-item-container .experience-item-img {
    min-height: 100%;
    min-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.experience-section .experience-pictures .experience-item-container:hover .experience-item-img {
  filter: blur(8px);
  transform: translateY(-50%) scale(1.1);
}
@media screen and (max-width: 430px) {
  .experience-section .experience-pictures .experience-item-container:hover .experience-item-img {
    transform: translate(-50%, -50%) scale(1.1);
  }
}
.experience-section .experience-pictures .experience-item-container:hover .experience-item-book {
  display: block;
}
.experience-section .experience-pictures .experience-item-container:hover .experience-item-title .title {
  display: none;
}
.experience-section .experience-pictures .experience-item-container:hover .experience-item-title .name {
  display: block;
}
.experience-section .experience-pictures .experience-item-container .experience-item-inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.experience-section .experience-pictures .experience-item-container .experience-item-title {
  font-family: "Alegreya Sans", sans-serif;
  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  letter-spacing: 2px;
  color: white;
  text-align: center;
  margin: 0;
  width: 100%;
}
.experience-section .experience-pictures .experience-item-container .experience-item-title .title {
  display: block;
  font-size: 30px;
  font-weight: 500;
  font-style: italic;
  text-transform: none;
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: normal;
  line-height: normal;
}
.experience-section .experience-pictures .experience-item-container .experience-item-title .name {
  display: none;
}
@media screen and (max-width: 767px) {
  .experience-section .experience-pictures .experience-item-container .experience-item-title {
    padding: 0;
  }
}
.experience-section .experience-pictures .experience-item-container .experience-item-book {
  display: none;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 5px;
  color: white;
  text-align: center;
  padding-bottom: 6px;
  margin-top: 30px;
  border-bottom: 3px solid #be0081;
  text-transform: uppercase;
  line-height: normal;
}
.experience-section .experience-pictures .experience-item-1 {
  padding-top: 53.2122905028%;
}
@media screen and (max-width: 767px) {
  .experience-section .experience-pictures .experience-item-1 {
    padding-top: 0;
    height: 280px;
  }
}
.experience-section .experience-pictures .experience-item-2 {
  padding-top: 61.5384615385%;
}
@media screen and (max-width: 767px) {
  .experience-section .experience-pictures .experience-item-2 {
    padding-top: 0;
    height: 280px;
  }
}
.experience-section .experience-pictures .experience-item-3 {
  padding-top: 54.6153846154%;
}
@media screen and (max-width: 767px) {
  .experience-section .experience-pictures .experience-item-3 {
    padding-top: 0;
    height: 280px;
  }
}
.experience-section .experience-pictures .experience-item-4 {
  padding-top: 46.5734265734%;
}
@media screen and (max-width: 767px) {
  .experience-section .experience-pictures .experience-item-4 {
    padding-top: 0;
    height: 280px;
  }
}

.customer-review-container {
  margin: 80px 6.25%;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .customer-review-container {
    margin: 80px 20px 60px 20px;
  }
}

.customer-review {
  margin-bottom: -20px;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.customer-review-title {
  margin-left: 27.4305555556%;
  order: -1;
}
@media screen and (max-width: 767px) {
  .customer-review-title {
    margin: 0;
  }
}
.customer-review-subtitle {
  margin-left: 32.7083333333%;
}
@media screen and (max-width: 767px) {
  .customer-review-subtitle {
    margin: 0;
  }
}
.customer-review-embed {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 50px -20px -50px -20px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .customer-review-embed {
    flex-direction: column;
  }
}
.customer-review-embed > div {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  align-items: center;
  justify-content: flex-start;
  margin: 0 20px 50px 20px;
}
.customer-review-embed #review-container {
  min-width: 468px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .customer-review-embed #review-container {
    width: 50%;
    /*flex-direction: row;*/
  }
}
@media screen and (max-width: 540px) {
  .customer-review-embed #review-container {
    min-width: auto;
    width: 100%;
    flex: 1 1 100%;
  }
}
.customer-review-embed #review-container > div {
  display: block;
  width: 100%;
}
.customer-review-embed #review-container > div .romw .romw-source-logo img {
  display: table;
  width: 25px;
  height: 25px;
  position: relative;
  right: 80px;
}
.customer-review-embed #review-container > div .romw .romw-text {
  display: block;
  width: 85%;
}

.TA_selfserveprop.mobile {
  display: flex;
}
@media screen and (min-width: 541px) {
  .TA_selfserveprop.mobile {
    display: none;
  }
}

.TA_selfserveprop.desktop {
  display: flex;
  padding-bottom: 45px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .TA_selfserveprop.desktop {
    width: 50%;
    flex: 1 1 50%;
  }
}
@media screen and (max-width: 540px) {
  .TA_selfserveprop.desktop {
    display: none;
  }
}

.TA_certificateOfExcellence {
  position: absolute;
  right: 0;
  bottom: 0;
}

#TA_selfserveprop920 #CDSWIDSSP .widSSPData,
#TA_selfserveprop367 #CDSWIDSSP .widSSPData {
  border: none !important;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  padding: 20px;
}
#TA_selfserveprop920 #CDSWIDSSP .widSSPData .widSSPBranding dt.widSSPTagline,
#TA_selfserveprop920 #CDSWIDSSP .widSSPData .widSSPReviews .widSSPH11,
#TA_selfserveprop920 #CDSWIDSSP .widSSPData .widSSPH11,
#TA_selfserveprop920 #CDSWIDSSP .widSSPData .widSSPInformation .widSSPPopIdx b,
#TA_selfserveprop367 #CDSWIDSSP .widSSPData .widSSPBranding dt.widSSPTagline,
#TA_selfserveprop367 #CDSWIDSSP .widSSPData .widSSPReviews .widSSPH11,
#TA_selfserveprop367 #CDSWIDSSP .widSSPData .widSSPH11,
#TA_selfserveprop367 #CDSWIDSSP .widSSPData .widSSPInformation .widSSPPopIdx b {
  font-size: 13px;
}
#TA_selfserveprop920 #CDSWIDSSP .widSSPData .widSSPOneReview .widSSPBullet li span,
#TA_selfserveprop367 #CDSWIDSSP .widSSPData .widSSPOneReview .widSSPBullet li span {
  font-size: 14px;
}
#TA_selfserveprop920 #CDSWIDSSP .widSSPData .widSSPAll a,
#TA_selfserveprop920 #CDSWIDSSP .widSSPData .widSSPOneReview .widSSPBullet li a,
#TA_selfserveprop920 #CDSWIDSSP .widSSPData .widSSPTrvlRtng .widSSPOverall div,
#TA_selfserveprop367 #CDSWIDSSP .widSSPData .widSSPAll a,
#TA_selfserveprop367 #CDSWIDSSP .widSSPData .widSSPOneReview .widSSPBullet li a,
#TA_selfserveprop367 #CDSWIDSSP .widSSPData .widSSPTrvlRtng .widSSPOverall div {
  font-size: 12px;
}
#TA_selfserveprop920 #CDSWIDSSP .widSSPData .widSSPTrvlRtng .widSSPOverall div,
#TA_selfserveprop367 #CDSWIDSSP .widSSPData .widSSPTrvlRtng .widSSPOverall div {
  margin-top: 3px;
}

#TA_selfserveprop920 #CDSWIDSSP {
  overflow: visible;
}

.product-price {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.product-price-text {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  color: #2d2f38;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.product-price-amount {
  font-family: "Open Sans", sans-serif;
  font-size: 35px;
  font-weight: bold;
  font-style: normal;
  color: #be0081;
}
.product-price-amount span {
  font-size: 23px;
}

.slider-section-produit {
  position: relative;
  top: -57px;
  left: 0;
  margin-bottom: -10px;
  z-index: 30;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  /*Style quand il n'y a pas d'image dans le slider + style commun*/
}
@media screen and (max-width: 767px) {
  .slider-section-produit {
    top: 0;
    left: 0;
    margin-bottom: 0;
    height: auto;
    overflow: auto;
    flex-direction: column;
  }
}
.slider-section-produit .slider-image-container {
  width: 59.2361111111%;
  height: 727px;
  overflow: hidden;
  position: relative;
  left: 0;
  top: 0;
  background-color: white;
  /*Style quand il y a une image dans le slider*/
}
.slider-section-produit .slider-image-container .slider-arrow {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  padding: 15px 15px 11px 15px;
  flex-direction: row;
  align-items: center;
  z-index: 35;
}
.slider-section-produit .slider-image-container .slider-arrow.active {
  display: flex;
}
@media screen and (max-width: 767px) {
  .slider-section-produit .slider-image-container .slider-arrow.active {
    display: none;
  }
}
.slider-section-produit .slider-image-container .slider-arrow p {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 2.5px;
  margin: 0 15px;
}
.slider-section-produit .slider-image-container .slider-arrow .slick-arrow {
  height: 25px;
  width: 25px;
  padding: 0;
}
.slider-section-produit .slider-image-container .slider-arrow .slick-prev .icon-arrow {
  transform: rotate(180deg);
}
.slider-section-produit .slider-image-container .slider-arrow .icon-arrow {
  width: 25px;
  height: 25px;
  fill: #2d2f38;
  background-image: url("../../front/images/arrow-right-grey.svg");
  background-position: center;
  background-color: transparent;
  background-size: 25px 25px;
}
.slider-section-produit .slider-image-container:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
  z-index: 32;
  pointer-events: none;
}
@media screen and (max-width: 767px) {
  .slider-section-produit .slider-image-container {
    width: 100%;
    height: 280px;
  }
}
.slider-section-produit .slider-image-container .slick-slide > div {
  position: relative;
  overflow: hidden;
}
.slider-section-produit .slider-image-container .slick-slider, .slider-section-produit .slider-image-container .slick-slider div {
  height: 100%;
  width: 100%;
}
.slider-section-produit .slider-image-container img {
  min-height: 100%;
  min-width: 100%;
  width: auto !important;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 31;
  transform: translate(-50%, -50%);
}
.slider-section-produit .slider-image-container ~ .slider-info-container {
  margin: 0;
  width: 40.7638888889%;
}
.slider-section-produit .slider-image-container ~ .slider-info-container .product-title, .slider-section-produit .slider-image-container ~ .slider-info-container .product-subtitle {
  text-align: left;
}
.slider-section-produit .slider-image-container ~ .slider-info-container .product-controls {
  justify-content: flex-start;
}
@media screen and (max-width: 767px) {
  .slider-section-produit .slider-image-container ~ .slider-info-container {
    width: calc(100% - 40px);
    margin: 0 20px 20px 20px;
    top: -16px;
  }
}
.slider-section-produit .slider-info-container {
  z-index: 33;
  width: 87.5%;
  padding: 57px 3.4722222222% 25px 3.4722222222%;
  height: 100%;
  margin: 0 auto;
}
.slider-section-produit .slider-info-container .product-title, .slider-section-produit .slider-info-container .product-subtitle {
  text-align: center;
}
@media screen and (max-width: 767px) {
  .slider-section-produit .slider-info-container {
    width: calc(100% - 40px);
    height: auto;
    position: relative;
    top: 0;
    left: 0;
    box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.2);
    margin: 0 20px;
    background-color: white;
    padding: 40px 20px 25px 20px;
  }
}
.slider-section-produit .slider-info-container .h2-title {
  margin: 60px 0;
  padding: 0;
}
.slider-section-produit .slider-info-container .product-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  color: #94bec5;
  text-transform: uppercase;
  margin-top: 20px;
  letter-spacing: 0.5px;
}
@media screen and (max-width: 767px) {
  .slider-section-produit .slider-info-container .product-controls {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
  }
}
.slider-section-produit .slider-info-container .product-controls > a {
  display: flex;
  align-items: center;
}
.slider-section-produit .slider-info-container .product-controls > a:hover {
  text-decoration: underline;
}
.slider-section-produit .slider-info-container .product-controls a.product-controls-print {
  margin-right: 30px;
}
@media screen and (max-width: 767px) {
  .slider-section-produit .slider-info-container .product-controls a.product-controls-print {
    display: none;
  }
}
.slider-section-produit .slider-info-container .product-controls .icon-print, .slider-section-produit .slider-info-container .product-controls .icon-share {
  width: 13px;
  height: 13px;
  fill: #94bec5;
  margin-left: 10px;
}
.slider-section-produit .slider-info-container .product-link {
  display: inline-flex;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  margin-top: 16px;
  color: #94bec5;
  text-decoration: underline;
}
.slider-section-produit .slider-info-container .product-link:hover {
  text-decoration: none;
}
.slider-section-produit .slider-info-container .product-link-price {
  margin-top: 37px;
}
.slider-section-produit .slider-info-container .product-price {
  margin-top: 32px;
}

/* Tab bar mobile */
.tab-bar {
  display: none;
}
@media screen and (max-width: 767px) {
  .tab-bar {
    display: block;
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px 15px 18px;
    background: #fff;
  }
}
.tab-bar .product-price {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.tab-bar .navigation-product-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  margin-top: 8px;
  text-align: center;
}
.tab-bar .navigation-product-right li {
  width: calc(50% - 5px);
  line-height: 50px;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
}
.tab-bar .navigation-product-right li.book {
  background-color: #be0081;
  border: solid 2px #be0081;
}
.tab-bar .navigation-product-right li.book:hover {
  background-color: white;
  color: #be0081;
}
.tab-bar .navigation-product-right li.offer {
  margin-left: 3px;
  background-color: #94bec5;
  border: solid 2px #94bec5;
}
.tab-bar .navigation-product-right li.offer:hover {
  background-color: white;
  color: #94bec5;
}
@media screen and (max-width: 767px) {
  .tab-bar .navigation-product-right li.offer {
    margin-left: 10px;
  }
}
.tab-bar .navigation-product-right li a {
  display: block;
  padding: 0;
}

.navigation-product {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 14.5px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 1px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: white;
  z-index: 999;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .navigation-product {
    box-shadow: none;
    background-color: transparent;
    justify-content: center;
    margin-top: 20px;
  }
}
@media screen and (min-width: 991px) {
  .navigation-product.sticky, .creation-compte .navigation-product.sticky {
    position: fixed;
    top: 0;
    left: 0;
  }
}
.navigation-product ul {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navigation-product ul li a {
  display: block;
  padding: 23px 0;
}
.navigation-product ul.navigation-product-left {
  font-weight: 500;
  color: black;
  margin-left: 55px;
  justify-content: flex-start;
}
@media screen and (max-width: 1199px) {
  .navigation-product ul.navigation-product-left {
    margin-left: 40px;
  }
}
@media screen and (max-width: 1049px) {
  .navigation-product ul.navigation-product-left {
    margin-left: 30px;
  }
}
@media screen and (max-width: 767px) {
  .navigation-product ul.navigation-product-left {
    display: none;
  }
}
.navigation-product ul.navigation-product-left li {
  margin-right: 80px;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
}
.navigation-product ul.navigation-product-left li.active, .navigation-product ul.navigation-product-left li:hover {
  border-bottom: 2px solid #be0081;
}
@media screen and (max-width: 1399px) {
  .navigation-product ul.navigation-product-left li {
    margin-right: 40px;
  }
}
@media screen and (max-width: 1049px) {
  .navigation-product ul.navigation-product-left li {
    margin-right: 20px;
  }
}
.navigation-product ul.navigation-product-right {
  font-weight: bold;
  color: white;
  justify-content: flex-end;
  text-align: center;
}
.navigation-product ul.navigation-product-right li {
  width: 125px;
}
@media screen and (max-width: 1199px) {
  .navigation-product ul.navigation-product-right li {
    width: 85px;
  }
}
@media screen and (max-width: 767px) {
  .navigation-product ul.navigation-product-right li {
    height: 50px;
    border-radius: 50px;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 50px;
    width: 155px;
  }
  .navigation-product ul.navigation-product-right li a {
    padding: 0;
  }
}
@media screen and (max-width: 359px) {
  .navigation-product ul.navigation-product-right li {
    width: 135px;
  }
}
.navigation-product ul.navigation-product-right li.book {
  background-color: #be0081;
  border: solid 2px #be0081;
}
.navigation-product ul.navigation-product-right li.book:hover {
  background-color: white;
  color: #be0081;
}
.navigation-product ul.navigation-product-right li.offer {
  margin-left: 3px;
  background-color: #94bec5;
  border: solid 2px #94bec5;
}
.navigation-product ul.navigation-product-right li.offer:hover {
  background-color: white;
  color: #94bec5;
}
@media screen and (max-width: 767px) {
  .navigation-product ul.navigation-product-right li.offer {
    margin-left: 10px;
  }
}

.navigation-product.sticky + .nos-prestations {
  padding-top: 150px;
}

.nos-prestations {
  padding: 80px 13.6111111111% 0 6.25%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
@media screen and (max-width: 1199px) {
  .nos-prestations {
    padding: 80px 6.25% 0 6.25%;
  }
}
@media screen and (max-width: 767px) {
  .nos-prestations {
    padding: 40px 20px 20px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
.nos-prestations select {
  margin: 40px 0 20px 0;
}
@media screen and (min-width: 768px) {
  .nos-prestations select {
    display: none;
  }
}
.nos-prestations-left {
  flex: 1 1 0%;
}
@media screen and (max-width: 767px) {
  .nos-prestations-left {
    width: 100%;
  }
}
@media screen and (min-width: 767px) {
  .nos-prestations-left {
    flex: 1 1 50%;
  }
}
.nos-prestations-left .h2-title, .nos-prestations-left .h3-title {
  padding-right: 20px;
}
@media screen and (max-width: 767px) {
  .nos-prestations-left .h3-title {
    margin-top: 15px;
  }
}
.nos-prestations .list-prestations {
  margin-top: 110px;
}
@media screen and (max-width: 767px) {
  .nos-prestations .list-prestations {
    margin: 40px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: 100%;
    overflow-x: scroll;
  }
  .nos-prestations .list-prestations li {
    font-size: 19px !important;
    padding: 10px 18px !important;
    letter-spacing: 2px !important;
    white-space: nowrap;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border: solid 1px transparent;
    border-bottom: none;
    /*&:not(.active) .list-prestations-elem {
      max-width: 36px;
      overflow: hidden;
      margin-right: 20px;
      position: relative;

      &:after {
        content: '[...]';
        position: absolute;
        top: 50%;
        right: 0;

      }
    }*/
  }
  .nos-prestations .list-prestations li:not(:last-of-type) {
    margin-right: 10px;
  }
  .nos-prestations .list-prestations li.active {
    border-color: #c5c5c5;
    box-shadow: 0 18px 20px 1px #000;
  }
  .nos-prestations .list-prestations li.active:before, .nos-prestations .list-prestations li.active:after {
    display: none;
  }
}
.nos-prestations .list-prestations-elem {
  flex: 1 0 auto;
}
.nos-prestations .list-prestations li {
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 4px;
  color: #c5c5c5;
  text-transform: uppercase;
  padding: 15px 0;
  transition: all 0.4s;
}
.nos-prestations .list-prestations li.active {
  font-weight: bold;
  color: #be0081;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;
}
.nos-prestations .list-prestations li.active a {
  order: 1;
}
.nos-prestations .list-prestations li.active:before {
  content: "";
  margin-left: 20px;
  height: 10px;
  width: 10px;
  background-color: white;
  border: 1px solid #2d2f38;
  border-radius: 50%;
  order: 2;
  flex: 1 0 auto;
}
.nos-prestations .list-prestations li.active:after {
  content: "";
  height: 1px;
  width: 100%;
  background-color: #2d2f38;
  display: block;
  float: right;
  order: 3;
}
.nos-prestations .info-prestations {
  font-family: "Open Sans", sans-serif;
  max-width: 400px;
  margin-top: 40px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  color: #2e333d;
  line-height: 1.8;
}
.nos-prestations .info-prestations ul {
  list-style-type: circle;
  margin-left: 36px;
}
.nos-prestations .info-prestations li {
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .nos-prestations .info-prestations {
    display: none;
  }
}
.nos-prestations .info-prestations b {
  font-weight: bold;
}
.nos-prestations-infos {
  padding: 30px 50px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  flex: 1 0 0;
}
@media screen and (min-width: 767px) {
  .nos-prestations-infos {
    flex: 1 1 50%;
  }
}
.nos-prestations-infos > li {
  display: none;
}
.nos-prestations-infos > li.active {
  display: block;
}
.nos-prestations-infos p {
  padding: 10px 0;
}
.nos-prestations-infos p a:not(.btn-underline) {
  color: #94bec5;
  text-decoration: none;
}
.nos-prestations-infos p a:not(.btn-underline):hover {
  text-decoration: underline;
}
@media screen and (max-width: 767px) {
  .nos-prestations-infos {
    padding: 20px;
    width: 100%;
  }
}
.nos-prestations-infos-intro {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  color: #2e333d;
  line-height: 1.7;
  margin-bottom: 20px;
}
.nos-prestations-infos-intro b {
  font-weight: bold;
}
.nos-prestations-infos-beforeul {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 4px;
  color: #94bec5;
  text-transform: uppercase;
}
.nos-prestations-infos ul, .nos-prestations-infos-ul {
  margin: 0 0 0 36px;
  padding: 10px 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: #2e333d;
  list-style-type: circle;
  line-height: 1.8;
}
.nos-prestations-infos-links {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
  text-transform: uppercase;
  color: #94bec5;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
}
.nos-prestations-infos-links a {
  margin: 0 50px 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.nos-prestations-infos-links a:hover {
  text-decoration: underline;
}
.nos-prestations-infos-links a:last-child {
  margin-right: 0;
}
.nos-prestations-infos-links .icon-container {
  width: 36px;
  height: 36px;
}
.nos-prestations-infos-links .icon-container .icon-hat-menu, .nos-prestations-infos-links .icon-container .icon-glass {
  width: 20px;
  height: 20px;
}
.nos-prestations-infos-links-text {
  margin-left: 20px;
}
.nos-prestations-infos-prices {
  margin-top: 20px;
}
.nos-prestations-infos-prices > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 767px) {
  .nos-prestations-infos-prices > div {
    flex-direction: column;
    justify-content: center;
    padding: 10px 20px;
  }
}
.nos-prestations-infos-prices > div:last-child {
  border-bottom: none;
}
.nos-prestations-infos-prices > div:not(:first-child) .product-price-amount {
  font-size: 29px;
  color: #2d2f38;
}
.nos-prestations-infos-prices > div:not(:first-child) .product-price-amount span {
  font-size: 16px;
}
.nos-prestations-infos-prices > div .product-price-info:last-child {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: black;
  padding-left: 20px;
  flex: 1 0 0;
}
@media screen and (max-width: 767px) {
  .nos-prestations-infos-prices > div .product-price-info:last-child {
    padding-left: 0;
  }
}
.nos-prestations-infos-prices > div .product-price-info:first-child {
  font-family: "Alegreya Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  color: #2d2f38;
  letter-spacing: 2px;
}
@media screen and (min-width: 768px) {
  .nos-prestations-infos-prices > div .product-price-info:first-child {
    margin-right: 80px;
  }
}
.nos-prestations-infos-prices .product-price {
  white-space: nowrap;
}

.nos-prestations-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin-top: 35px;
  padding: 20px 0;
  background-color: #2d2f38;
}
@media screen and (max-width: 767px) {
  .nos-prestations-buttons {
    display: none;
  }
}
.nos-prestations-buttons .btn-full {
  margin: 0 15px;
  padding: 0 65px;
}
.nos-prestations-buttons .btn-blue {
  padding: 0 75px;
  background-color: #94bec5;
}
.nos-prestations-buttons .btn-blue:hover {
  color: #94bec5;
  background-color: transparent;
  border: 2px solid #94bec5;
}
.nos-prestations-buttons .btn-full:hover {
  background-color: white;
  border: solid 2px white;
}

.section-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: stretch;
}
.section-heading div:first-child {
  flex-grow: 1;
}
.section-heading-after {
  display: none;
  background-image: url("../../front/images/arrow-down.svg");
  background-repeat: no-repeat;
  width: 24px;
  height: 19px;
  background-size: 14px 9px;
  background-position: center center;
  margin-right: 20px;
  flex-shrink: 0;
}
@media screen and (max-width: 767px) {
  .section-heading-after {
    display: block;
  }
}

.section-hidden {
  display: block;
}
@media screen and (max-width: 767px) {
  .section-hidden {
    display: none;
  }
}

section.active .section-hidden {
  display: block;
}
section.active .section-heading-after {
  transform: rotate(180deg);
}

.experience {
  padding-top: 80px;
}
@media screen and (max-width: 768px) {
  .experience {
    padding-top: 40px;
  }
}
.experience .h2-title {
  margin-left: 23.9583333333%;
}
@media screen and (max-width: 768px) {
  .experience .h2-title {
    margin: 0 20px;
  }
}
.experience .h3-title {
  margin-left: 48.1944444444%;
  margin-bottom: 50px;
}
@media screen and (max-width: 768px) {
  .experience .h3-title {
    margin: 15px 20px 0 20px;
  }
}

.experience-item-product.experience-item {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 600px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  color: black;
}
@media screen and (max-width: 991px) {
  .experience-item-product.experience-item {
    flex-direction: column;
    height: auto;
    width: 100%;
  }
}
.experience-item-product.experience-item b {
  font-weight: bold;
}
.experience-item-product.experience-item ul {
  padding: 10px 0;
  margin-left: 36px;
  font-weight: normal;
  font-style: normal;
  color: #2e333d;
  list-style-type: circle;
  line-height: 1.8;
}
.experience-item-product.experience-item .experience-item-infos {
  width: 42.7083333333%;
  padding: 40px 55px 40px 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 767px) {
  .experience-item-product.experience-item .experience-item-infos {
    min-height: 600px;
  }
}
@media screen and (max-width: 991px) {
  .experience-item-product.experience-item .experience-item-infos {
    width: 100%;
    padding: 20px;
  }
}
.experience-item-product.experience-item .experience-item-infos p {
  margin-bottom: 20px;
  line-height: 1.7;
}
.experience-item-product.experience-item .experience-item-infos a {
  color: #94bec5;
  text-decoration: none;
}
.experience-item-product.experience-item .experience-item-infos a:hover {
  text-decoration: underline;
}
.experience-item-product.experience-item .experience-item-infos a.btn-underline {
  color: black;
}
.experience-item-product.experience-item .experience-item-infos a.btn-underline:hover {
  text-decoration: none;
  color: #be0081;
}
.experience-item-product.experience-item .experience-item-infos-title {
  font-family: "Alegreya Sans", sans-serif;
  margin: 10px 0 20px 0;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 4px;
  color: #94bec5;
  text-transform: uppercase;
}
.experience-item-product.experience-item .experience-item-infos-subtitle, .experience-item-product.experience-item .experience-item-infos h4 {
  font-family: "Lato", sans-serif;
  font-size: 22px;
  font-weight: 300;
  font-style: normal;
  color: #2e333d;
  margin-bottom: 17px;
}
.experience-item-product.experience-item .experience-item-float {
  width: 57.2916666667%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: stretch;
  position: relative;
}
@media screen and (max-width: 991px) {
  .experience-item-product.experience-item .experience-item-float {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 991px) {
  .experience-item-product.experience-item .experience-item-float:before {
    content: "";
    padding-top: 56.25%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.experience-item-product.experience-item .experience-item-float a img {
  max-height: 100%;
  min-width: auto;
  max-width: 100%;
  min-height: auto;
}
.experience-item-product.experience-item .experience-item-float a .icon-container {
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80px;
  width: 80px;
  background-color: white;
}
.experience-item-product.experience-item .experience-item-float a .icon-container .icon-zoom {
  width: 38px;
  height: 38px;
  fill: #94bec5;
}
.experience-item-product.experience-item .experience-item-float a:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  z-index: 99;
}
.experience-item-product.experience-item .experience-item-float-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
}
.experience-item-product.experience-item .experience-item-float > iframe {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  height: 100%;
  width: 100%;
  border: 0;
}
.experience-item-product.experience-item .experience-item-float #map {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.experience-item.experience-item-product-reverse .experience-item-float {
  order: 1;
  width: 42.7083333333%;
}
@media screen and (max-width: 991px) {
  .experience-item.experience-item-product-reverse .experience-item-float {
    width: 100%;
    order: 2;
  }
}
.experience-item.experience-item-product-reverse .experience-item-infos {
  order: 2;
  padding: 40px 45px 40px 55px;
  width: 57.2916666667%;
}
@media screen and (max-width: 991px) {
  .experience-item.experience-item-product-reverse .experience-item-infos {
    padding: 20px;
    width: 100%;
    order: 1;
  }
}

.informations-pratiques {
  padding: 80px 0 120px 0;
}
@media screen and (max-width: 768px) {
  .informations-pratiques {
    padding: 60px 0;
  }
}
.informations-pratiques .h2-title {
  margin: 0 0 30px 28.1944444444%;
}
@media screen and (max-width: 768px) {
  .informations-pratiques .h2-title {
    margin: 0 20px;
  }
}
@media screen and (max-width: 359px) {
  .informations-pratiques .h2-title {
    font-size: 40px;
  }
}

.slider-section-rich-gab {
  margin-bottom: -57px;
}
@media screen and (max-width: 767px) {
  .slider-section-rich-gab {
    margin-bottom: 0;
  }
}

.reservation-devis {
  background-color: #2d2f38;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 9px 0;
}
.reservation-devis.sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
}
.reservation-devis.bottom {
  position: relative;
  order: 999;
  margin-top: 40px;
}
.reservation-devis-title {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 4px;
  color: #f7f7f7;
  text-transform: uppercase;
  text-align: center;
  margin: 9px 20px;
}
@media screen and (max-width: 991px) {
  .reservation-devis-title {
    font-size: 20px;
    margin: 0 10px;
  }
}
@media screen and (max-width: 767px) {
  .reservation-devis-title {
    font-size: 18px;
  }
}
.reservation-devis-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 9px 0;
}
.reservation-devis-buttons #reservation-devis-buttons-tel-mobile {
  display: block;
}
@media screen and (min-width: 768px) {
  .reservation-devis-buttons #reservation-devis-buttons-tel-mobile {
    display: none;
  }
}
.reservation-devis-buttons #reservation-devis-buttons-tel {
  display: none;
}
@media screen and (min-width: 768px) {
  .reservation-devis-buttons #reservation-devis-buttons-tel {
    display: block;
  }
}
.reservation-devis-buttons #reservation-devis-buttons-tel span:first-child {
  display: inline;
}
.reservation-devis-buttons #reservation-devis-buttons-tel span:last-child {
  display: none;
  font-size: 18px;
  line-height: 18px;
}
.reservation-devis-buttons #reservation-devis-buttons-tel.active span:first-child {
  display: none;
}
.reservation-devis-buttons #reservation-devis-buttons-tel.active span:last-child {
  display: inline;
}
.reservation-devis-buttons a.btn-full {
  padding: 0 67px;
  margin: 0 20px;
  height: 40px;
  line-height: 40px;
}
@media screen and (max-width: 991px) {
  .reservation-devis-buttons a.btn-full {
    padding: 0 40px;
    margin: 0 10px;
  }
}
@media screen and (max-width: 767px) {
  .reservation-devis-buttons a.btn-full {
    padding: 0 25px;
    margin: 0 10px;
  }
}
@media screen and (max-width: 359px) {
  .reservation-devis-buttons a.btn-full {
    padding: 0 15px;
  }
}
.reservation-devis-buttons a.btn-full:hover {
  background-color: white;
  border: solid 2px white;
}

.product-content {
  padding: 0 6.25%;
}
.product-content > .experience-item:first-child {
  padding-top: 60px;
}
.product-content .h2-title {
  padding: 5px 0 30px 0;
  text-align: center;
}
.product-content .img-container {
  width: 100%;
  max-height: 600px;
}
@media screen and (max-width: 767px) {
  .product-content .img-container {
    max-height: 300px;
  }
}
.product-content .img-container img {
  display: block;
  margin: auto;
  max-height: 600px;
  max-width: 100%;
}
.product-content .slider-sync-for .slide-img-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 600px;
}
@media screen and (max-width: 767px) {
  .product-content .slider-sync-for .slide-img-container {
    height: 300px;
  }
}
.product-content .slider-sync-for .slide-img-container img {
  position: absolute;
  width: auto !important;
  max-height: 100%;
  max-width: unset;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.product-content .slider-sync .slick-slide > div {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}
.product-content .slider-sync .slick-slide > div img {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.product-content .img-caption {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: italic;
  color: black;
  text-align: center;
  padding-top: 15px;
}
.product-content-text {
  padding: 40px 18.75% 0 18.75%;
}
@media screen and (max-width: 767px) {
  .product-content-text {
    padding: 40px 6.25% 0 6.25%;
  }
}
.product-content-image {
  padding-top: 40px;
}
.product-content-image .product-content-subtitle {
  padding: 10px 0 45px 0;
  text-align: center;
}
.product-content-slider {
  padding-top: 40px;
}
.product-content-slider .product-content-subtitle {
  padding: 10px 0 45px 0;
  text-align: center;
}
.product-content-title {
  font-family: "Alegreya Sans", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 4px;
  color: #94bec5;
  text-transform: uppercase;
  margin: 10px 0 20px 0;
}
.product-content-subtitle {
  font-family: "Lato", sans-serif;
  font-size: 22px;
  font-weight: 300;
  font-style: normal;
  color: #2e333d;
  margin-bottom: 17px;
}
.product-content h4 {
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  color: #2d2f38;
  font-family: "Alegreya Sans", sans-serif;
}
.product-content-content {
  padding-bottom: 40px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: 0.5px;
  color: black;
}
.product-content-content a {
  color: #94bec5;
}
.product-content-content a:hover {
  text-decoration: underline;
}
.product-content-content a.btn-underline {
  color: black;
}
.product-content-content a.btn-underline:hover {
  text-decoration: none;
  color: #be0081;
}
.product-content-content p {
  padding: 10px 0;
}
.product-content-content b {
  font-weight: bold;
}
.product-content-content i {
  font-style: italic;
}
.product-content-content ul {
  padding: 10px 0;
  margin-left: 19px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: #2e333d;
  list-style-type: circle;
  line-height: 1.8;
}

.slider-sync {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.slider-sync .slick-list {
  width: 100%;
  margin: 25px 0 40px 0;
}
@media screen and (max-width: 767px) {
  .slider-sync .slick-list {
    margin: 20px 0;
    visibility: hidden;
  }
}
.slider-sync .slick-list .slick-slide {
  margin: 0 5px;
  cursor: pointer;
}
.slider-sync .slick-prev,
.slider-sync .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slider-sync .slick-prev:hover,
.slider-sync .slick-prev:focus,
.slider-sync .slick-next:hover,
.slider-sync .slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slider-sync .slick-prev:hover:before,
.slider-sync .slick-prev:focus:before,
.slider-sync .slick-next:hover:before,
.slider-sync .slick-next:focus:before {
  opacity: 1;
}
.slider-sync .slick-prev.slick-disabled:before,
.slider-sync .slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slider-sync .slick-prev:before,
.slider-sync .slick-next:before {
  font-family: "slick";
  font-size: 40px;
  line-height: 1;
  opacity: 0.75;
  color: #94bec5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slider-sync .slick-prev {
  top: 50%;
  left: -3.5714285714%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 767px) {
  .slider-sync .slick-prev {
    left: 0;
    transform: translate(0, -50%);
  }
}
.slider-sync .slick-prev:before {
  content: "←";
}
.slider-sync .slick-next {
  top: 50%;
  right: -3.5714285714%;
  transform: translate(50%, -50%);
}
@media screen and (max-width: 767px) {
  .slider-sync .slick-next {
    right: 0;
    transform: translate(0, -50%);
  }
}
.slider-sync .slick-next:before {
  content: "→";
}

.youtube-embed {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
}
.youtube-embed .product-content-subtitle {
  padding: 10px 0 45px 0;
}
.youtube-embed .video-outer {
  position: relative;
  width: 100%;
}
.youtube-embed .video-outer:before {
  content: "";
  padding-top: 56.25%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.youtube-embed .video-outer iframe {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  height: 100%;
  width: 100%;
}
.youtube-embed-caption {
  font-family: "Open Sans", sans-serif;
  align-self: flex-start;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  color: #2d2f38;
  padding: 20px 0;
  display: block;
}

.liste-produits {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 0 0;
}
.liste-produits .h2-title {
  text-align: center;
}
.liste-produits-subtitle {
  margin: 10px 27.7777777778% 60px 27.7777777778%;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .liste-produits-subtitle {
    margin: 10px 20px 60px 20px;
  }
}
.liste-produits .featured-product {
  margin-top: 0;
  margin-bottom: 80px;
}
.liste-produits .featured-product-info a.btn-full {
  padding: 0 65px;
}
@media screen and (max-width: 465px) {
  .liste-produits .featured-product-info a.btn-full {
    padding: 0 25px;
  }
}
@media screen and (max-width: 359px) {
  .liste-produits .featured-product-info a.btn-full {
    padding: 0 13px;
  }
}

.featured-product {
  margin: 80px 6.25% 0 6.25%;
  width: 87.5%;
  max-width: 1440px;
  height: 470px;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 1440px) {
  .featured-product {
    margin-left: auto;
    margin-right: auto;
  }
}
.featured-product:nth-child(2n) .featured-product-info {
  right: 50.3968253968%;
  left: auto;
}
@media screen and (max-width: 767px) {
  .featured-product:nth-child(2n) .featured-product-info {
    right: 0;
    left: 0;
  }
}
.featured-product:nth-child(2n) .featured-product-picture {
  right: 0;
  left: auto;
}
@media screen and (max-width: 767px) {
  .featured-product:nth-child(2n) .featured-product-picture {
    left: 0;
  }
}
@media screen and (max-width: 767px) {
  .featured-product {
    flex-direction: column;
    margin: 0;
    width: 100%;
    height: auto;
  }
}
.featured-product-picture {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 470px;
  width: 66.3492063492%;
}
@media screen and (max-width: 767px) {
  .featured-product-picture {
    width: 100%;
    position: relative;
  }
}
.featured-product-img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}
.featured-product-info {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 50.3968253968%;
  top: 50%;
  transform: translateY(-50%);
  max-width: 50%;
  background-color: white;
}
@media screen and (max-width: 767px) {
  .featured-product-info {
    position: relative;
    top: -172px;
    left: auto;
    width: calc(100% - 40px);
    padding: 30px;
    margin: 0 20px -172px 20px;
    text-align: left;
    align-items: center;
    transform: none;
    max-width: 100%;
  }
}
.featured-product-info .item-price {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  margin-left: 2.4%;
}
@media screen and (max-width: 767px) {
  .featured-product-info .item-price {
    margin: 20px 0 0 0;
  }
}
.featured-product-info .item-price-text {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 1.5px;
  color: #2d2f38;
  text-transform: uppercase;
}
.featured-product-info .item-price-amount {
  font-family: "Open Sans", sans-serif;
  font-size: 27px;
  font-weight: bold;
  font-style: normal;
  color: #be0081;
  white-space: nowrap;
}
.featured-product-info .item-price-amount span {
  font-size: 16px;
}
.featured-product-info-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .featured-product-info-heading {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.featured-product-info-title {
  display: block;
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  color: #94bec5;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.featured-product-info-text {
  margin-top: 15px;
  color: #2d2f38;
  max-width: 100%;
}
@media screen and (max-width: 767px) {
  .featured-product-info-text {
    font-size: 18px;
  }
}
.featured-product-info .btn-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
}
.featured-product-info .btn-container a {
  margin-top: 20px;
}
.featured-product-info a.btn-trans {
  padding: 0 55px;
}
@media screen and (max-width: 465px) {
  .featured-product-info a.btn-trans {
    padding: 0 20px;
  }
}
@media screen and (max-width: 359px) {
  .featured-product-info a.btn-trans {
    padding: 0 10px;
  }
}
.featured-product-info a.btn-full {
  padding: 0 40px;
}
@media screen and (max-width: 767px) {
  .featured-product-info a.btn-full {
    margin-top: 20px;
  }
}
@media screen and (max-width: 465px) {
  .featured-product-info a.btn-full {
    padding: 0 25px;
  }
}
@media screen and (max-width: 359px) {
  .featured-product-info a.btn-full {
    padding: 0 13px;
  }
}

.liste-cards {
  padding: 60px 0 0 0;
}
.liste-cards-subtitle {
  margin: 30px 0;
}
.liste-cards .h2-title:not(.rendez-vous-title) {
  text-align: center;
}
.liste-cards .grid-container {
  margin-top: 10px;
  margin-bottom: 60px;
}
@media screen and (min-width: 1024px) {
  .liste-cards .grid-header form p.liste-cards-subtitle:first-child {
    margin: 0 27.77777778% 60px;
  }
}
.liste-cards .grid-item {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}
.liste-cards-selection {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 40px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  color: #6f6f6f;
  letter-spacing: 1px;
}
@media screen and (max-width: 767px) {
  .liste-cards-selection {
    display: none;
  }
}
.liste-cards-selection > div {
  padding: 25px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  margin: 20px 10px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.liste-cards-selection .form-check-container-radio input:checked + .form-check-checkmark {
  background-color: #be0081;
}
.liste-cards-selection .form-check-container-radio input:checked ~ .form-check-label {
  color: #be0081;
}
.liste-cards select.select-custom {
  margin-top: 60px;
  width: 100%;
  display: none;
}
@media screen and (max-width: 767px) {
  .liste-cards select.select-custom {
    display: block;
  }
}

.creation-compte {
  padding: 60px 6.25% 25px 6.25%;
}
.creation-compte .h2-title {
  padding-bottom: 30px;
  text-align: center;
}
.creation-compte .btn-submit {
  margin: 0 auto 25px auto;
  padding: 0 35px;
}
@media screen and (max-width: 767px) {
  .creation-compte .btn-submit span {
    display: none;
  }
}
.creation-compte-success {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: #86d788;
  padding: 10px 0;
}
.creation-compte .navigation-product {
  position: absolute;
  top: -70px;
  left: 0;
}

.form-section {
  margin-bottom: 60px;
}
.form-section-title {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 4px;
  color: #94bec5;
  text-align: left;
  text-transform: uppercase;
}
.form-section-block, .form-section-flex {
  margin-top: 30px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
}
.form-section-flex {
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
}
.form-section-flex .btn-full {
  margin-top: 30px;
  align-self: center;
  margin-bottom: 20px;
  padding: 0 20px;
}
@media screen and (min-width: 769px) {
  .form-section-flex .btn-full {
    margin-top: 10px;
    margin-right: 55px;
    align-self: flex-end;
  }
}
.form-section-block {
  padding: 40px 55px 40px 55px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
}
@media screen and (max-width: 767px) {
  .form-section-block {
    padding: 40px 6.25% 10px 6.25%;
  }
}
.form-section-block .btn-full {
  margin-top: 30px;
  align-self: center;
  margin-bottom: 20px;
  padding: 0 20px;
}
@media screen and (min-width: 769px) {
  .form-section-block .btn-full {
    margin-top: 10px;
    align-self: flex-end;
  }
}
.form-section-block-inner {
  max-width: 520px;
}
@media screen and (min-width: 1124px) {
  .form-section-block-inner {
    margin-bottom: -30px;
  }
}
.form-section-flex-inner {
  padding: 40px 55px 0 55px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: -20px -20px 0 -20px;
}
@media screen and (max-width: 767px) {
  .form-section-flex-inner {
    padding: 40px 6.25% 10px 6.25%;
  }
}
.form-section-flex-inner > div {
  max-width: 520px;
  flex: 1 1 auto;
  margin: 20px 20px 0 20px;
}
.form-section-flex-inner > div .form-group {
  flex: 1 1 auto;
}
@media screen and (min-width: 1039px) {
  .form-section-flex-inner .mt-exc {
    margin-top: 36px;
    margin-bottom: 14px;
  }
}
.form-section.order-history .order-history-ordernum {
  font-weight: normal;
}
.form-section.order-history .order-history-price {
  color: #be0081;
  min-width: 90px;
  justify-content: flex-end;
}
.form-section.order-history .order-history-price sup {
  margin-left: 2px;
}
.form-section.order-history ul {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: -10px;
}
.form-section.order-history li {
  width: 100%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px 0;
  flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
  .form-section.order-history li {
    padding: 0 15px;
  }
}
.form-section.order-history li > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .form-section.order-history li > div {
    flex-wrap: wrap;
    height: auto;
  }
}
.form-section.order-history li > div > div, .form-section.order-history li > div > p {
  margin: 0 30px;
  height: 70px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .form-section.order-history li > div > div, .form-section.order-history li > div > p {
    margin: 0 15px;
  }
}
.form-section.order-history li > div:first-child {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  justify-content: flex-start;
  color: #2d2f38;
}
.form-section.order-history li > div:last-child {
  font-family: "Alegreya Sans", sans-serif;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0.8px;
  color: #94bec5;
  text-transform: uppercase;
}
.form-section.order-history li > div:last-child > div {
  min-width: 200px;
}
.form-section.order-history li > div:last-child a {
  display: flex;
  align-items: center;
}
.form-section.order-history li > div:last-child a:hover {
  text-decoration: underline;
}
.form-section.order-history li .icon-container {
  width: 36px;
  height: 36px;
  flex: 0 0 auto;
  margin-right: 30px;
}
.form-section.order-history li .icon-container .icon-dl {
  width: 20px;
  height: 20px;
}
.form-section-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 45px;
  line-height: 25px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: black;
  background-color: #f7f7f7;
  padding: 10px 30px;
}
.form-section-banner .form-check-container-checkbox .form-check-checkmark {
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}
.form-section .form-group-inline {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .form-section .form-group-inline {
    margin: 0;
  }
}
.form-section .form-group-inline .form-group {
  margin: 0 20px 30px 0;
  justify-content: flex-end;
  flex: 1 1 0%;
}
@media screen and (max-width: 767px) {
  .form-section .form-group-inline .form-group {
    flex: 1 1 100%;
    margin: 0 0 30px 0;
  }
}
.form-section .form-group-inline .form-group:last-child {
  margin-right: 0;
}
.form-section .form-group-inline .form-group select.form-control,
.form-section .form-group-inline .form-group input.form-control {
  width: 100%;
  padding: 0 25px;
}
.form-section .form-group-inline .form-group.form-group-delivery-phone, .form-section .form-group-inline .form-group.form-group-fact-phone {
  flex: 3 1 0;
}
.form-section .form-group-inline .form-group.form-group-fact-phone {
  z-index: 1000;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
}
.form-group #account-birthdate {
  max-width: 300px;
}
.form-group-helper {
  font-size: 12px;
  font-style: italic;
  color: #2d2f38;
}
@media screen and (max-width: 767px) {
  .form-group-helper-fake {
    display: none;
  }
}
.form-group label {
  color: black;
}
.form-group .label-helper {
  font-size: 10px;
  font-weight: bold;
  color: #94bec5;
}
.form-group input.form-control, .form-group textarea.form-control, .form-group select.form-control {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  color: #2d2f38;
  width: 100%;
  height: 50px;
  border: solid #c5c5c5 2px;
  margin-top: 8px;
}
.form-group input.form-control::placeholder, .form-group textarea.form-control::placeholder, .form-group select.form-control::placeholder {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  color: #c5c5c5;
}
.form-group input.form-control {
  line-height: 46px;
  padding: 0 25px;
}
.form-group textarea.form-control {
  height: 160px;
  resize: none;
  padding: 15px 25px;
}
.form-group.form-check {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.form-group .form-check-container-radio {
  margin-left: 40px;
}
@media screen and (max-width: 767px) {
  .form-group .form-check-container-radio {
    margin-left: 20px;
  }
}
@media screen and (max-width: 359px) {
  .form-group .form-check-container-radio {
    margin-left: 5px;
  }
}
@media screen and (max-width: 767px) {
  .form-group .form-check-container-radio .form-check-label {
    padding-left: 25px;
  }
}
.form-group .civility-error {
  margin-left: 10px;
}
.form-group .input-container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.form-group .input-container input.parsley-success ~ .input-validation {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  flex-shrink: 0;
  top: calc(50% - 10px);
  right: -35px;
  background-color: #86d788;
}
.form-group .input-container input.parsley-success ~ .input-validation:after {
  content: "";
  position: absolute;
  display: block;
  left: 7px;
  top: 4px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.form-group .input-container input.parsley-error ~ .input-validation {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  flex-shrink: 0;
  top: calc(50% - 10px);
  right: -35px;
  background-color: #d35b5b;
  transform: rotate(45deg);
}
.form-group .input-container input.parsley-error ~ .input-validation:after {
  content: "";
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 0;
  width: 12px;
  height: 2px;
  background-color: white;
}
.form-group .input-container input.parsley-error ~ .input-validation:before {
  content: "";
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 2px;
  height: 12px;
  background-color: white;
}

.creation-compte .checkboxs-section .form-check {
  margin-bottom: 40px;
}

.checkboxs-section .parsley-errors-list {
  padding-left: 40px;
}
.checkboxs-section .parsley-errors-list .parsley-required {
  font-weight: 800;
  font-size: 13px;
  color: #e14242;
}
.checkboxs-section .form-check {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
}
.checkboxs-section .form-check a {
  font-weight: bold;
  color: #94bec5;
}
.checkboxs-section .form-check a:hover {
  text-decoration: underline;
}
.checkboxs-section .g-recaptcha {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 425px) {
  .checkboxs-section .g-recaptcha {
    display: block;
  }
}
.checkboxs-section-title {
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  color: #94bec5;
  margin-bottom: 15px;
}

.authentification {
  padding: 60px 6.25% 120px 6.25%;
}
.authentification .h2-title {
  margin-bottom: 50px;
  text-align: left;
}
@media screen and (max-width: 379px) {
  .authentification .h2-title {
    font-size: 43px;
  }
}
@media screen and (max-width: 359px) {
  .authentification .h2-title {
    font-size: 38px;
  }
}
.authentification-flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -10px -5px;
}
@media screen and (max-width: 767px) {
  .authentification .new-client {
    order: 2;
  }
}
.authentification .current-client {
  padding: 35px 110px 10px 110px;
}
@media screen and (max-width: 767px) {
  .authentification .current-client {
    order: 1;
    padding: 35px 6.25% 10px 6.25%;
  }
}
.authentification .current-client form {
  width: 100%;
}
.authentification .current-client .btn-full {
  margin: 25px 0 40px 0;
}
.authentification .current-client .form-group {
  margin-bottom: 15px;
  width: 100%;
}
.authentification .current-client a.password-forgotten {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  color: black;
  text-align: right;
  align-self: flex-end;
}
.authentification .current-client a.password-forgotten:hover {
  text-decoration: underline;
}
.authentification .current-client .authentification-flex-title {
  margin-bottom: 20px;
}
.authentification-flex {
  padding: 35px 60px 10px 60px;
  margin: 10px 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .authentification-flex {
    padding: 35px 6.25% 10px 6.25%;
    flex: 1 1 100%;
  }
}
.authentification-flex .btn-full {
  width: 310px;
  padding: 0;
}
@media screen and (max-width: 409px) {
  .authentification-flex .btn-full {
    width: 100%;
  }
}
.authentification-flex-title {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 4px;
  text-align: center;
  color: #94bec5;
  text-transform: uppercase;
}
.authentification-flex .p-subtitle {
  margin-top: 30px;
  max-width: 100%;
  letter-spacing: normal;
  text-align: center;
}
.authentification-flex .btn-subscribe-container {
  padding: 60px 0 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.authentification-flex .btn-subscribe-container .btn-fb {
  position: relative;
  background-color: #4267b2;
}
.authentification-flex .btn-subscribe-container .btn-fb:hover {
  color: #4267b2;
  background-color: transparent;
  border: solid 2px #4267b2;
}
.authentification-flex .btn-subscribe-container .btn-fb > span:last-child {
  margin-left: 30px;
}
@media screen and (max-width: 380px) {
  .authentification-flex .btn-subscribe-container .btn-fb > span:last-child {
    margin-left: 0;
  }
}
.authentification-flex .btn-subscribe-container .btn-fb .icon-fb-container {
  position: absolute;
  top: -2px;
  left: -2px;
  height: 50px;
  width: 50px;
  background-color: #5e7ebd;
}
@media screen and (max-width: 380px) {
  .authentification-flex .btn-subscribe-container .btn-fb .icon-fb-container {
    display: none;
  }
}
.authentification-flex .btn-subscribe-container .btn-fb .icon-fb-container .icon-facebook {
  width: 9px;
  height: 21px;
}
.authentification-flex .btn-subscribe-container .p-subtitle {
  margin: 0;
  padding: 8px 0;
}
@media screen and (min-width: 1199px) {
  .authentification .current-client {
    padding: 35px 50px 10px 50px;
    flex: 1 0 50%;
  }
}
@media screen and (min-width: 1279px) {
  .authentification .current-client {
    flex: 1 0 30%;
  }
}
@media screen and (min-width: 1499px) {
  .authentification .current-client {
    flex: 1 1 0;
  }
}
@media screen and (min-width: 407px) {
  .authentification .current-client .container-buttons-auth {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (min-width: 960px) {
  .authentification .current-client .container-buttons-auth {
    flex-wrap: nowrap;
  }
}
.authentification .current-client .container-buttons-auth .btn-full:first-child {
  width: 100%;
}
@media screen and (min-width: 407px) {
  .authentification .current-client .container-buttons-auth .btn-full:first-child {
    width: 310px;
  }
}
@media screen and (min-width: 960px) {
  .authentification .current-client .container-buttons-auth .btn-full:first-child {
    width: auto;
    min-width: 35%;
    margin-right: 20px;
  }
}
.authentification .current-client .btn-fb {
  position: relative;
  background-color: #4267b2;
}
@media screen and (min-width: 407px) {
  .authentification .current-client .btn-fb {
    width: 310px;
    margin-top: 0;
  }
}
@media screen and (min-width: 960px) {
  .authentification .current-client .btn-fb {
    width: 65%;
    margin-top: 25px;
  }
}
.authentification .current-client .btn-fb:hover {
  color: #4267b2;
  background-color: transparent;
  border: solid 2px #4267b2;
}
.authentification .current-client .btn-fb > span:last-child {
  margin-left: 25px;
}
@media screen and (max-width: 415px) {
  .authentification .current-client .btn-fb > span:last-child {
    margin-left: 0;
  }
}
.authentification .current-client .btn-fb .icon-fb-container {
  position: absolute;
  top: -2px;
  left: -2px;
  height: 50px;
  width: 50px;
  background-color: #5e7ebd;
}
@media screen and (max-width: 415px) {
  .authentification .current-client .btn-fb .icon-fb-container {
    display: none;
  }
}
.authentification .current-client .btn-fb .icon-fb-container .icon-facebook {
  width: 9px;
  height: 21px;
}

.reservation-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding: 75px 6.25%;
}
@media screen and (max-width: 884px) {
  .reservation-header {
    display: none;
  }
}
.reservation-header > ul {
  width: 100%;
  max-width: 1040px;
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.reservation-header > ul > li {
  height: 100%;
  flex: 1 1 25%;
}
.reservation-header > ul > li:first-child > ul:before {
  display: none;
}
.reservation-header > ul > li:last-child > ul:after {
  display: none;
}
.reservation-header > ul > li > ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 100%;
}
.reservation-header > ul > li > ul:before {
  content: "";
  display: block;
  position: absolute;
  height: 6px;
  width: calc(50% - 11px);
  background-color: #f7f7f7;
  top: 11px;
  left: 0;
  transform: translateY(-50%);
}
.reservation-header > ul > li > ul:after {
  content: "";
  display: block;
  position: absolute;
  height: 6px;
  width: calc(50% - 11px);
  background-color: #f7f7f7;
  top: 11px;
  right: 0;
  transform: translateY(-50%);
}
.reservation-header > ul > li > ul li:first-child {
  position: relative;
}
.reservation-header > ul > li > ul li:first-child .timeline-check {
  position: relative;
  width: 22px;
  height: 22px;
  background-color: #c5c5c5;
  border-radius: 50%;
  z-index: 50;
}
.reservation-header > ul > li > ul > li:nth-child(2) {
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 1px;
  text-align: center;
  color: #2d2f38;
}
.reservation-header > ul > li > ul > li:last-child {
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Playfair Display", serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  color: #c5c5c5;
}
.reservation-header > ul > li.done ul:before, .reservation-header > ul > li.done ul:after {
  background-color: #be0081;
}
.reservation-header > ul > li.done ul li:first-child .timeline-check {
  position: relative;
  width: 22px;
  height: 22px;
  background-color: #be0081;
  border-radius: 50%;
}
.reservation-header > ul > li.done ul li:first-child:after {
  content: "";
  position: absolute;
  display: block;
  left: 8px;
  top: 4px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  z-index: 99;
}
.reservation-header > ul > li.done ul li:last-child {
  color: #2d2f38;
  font-weight: normal;
}
.reservation-header > ul > li.done ul:hover li:nth-child(2) {
  color: #be0081;
}
.reservation-header > ul > li.done ul:hover li:last-child {
  color: #be0081;
  font-weight: bold;
}
.reservation-header > ul > li.active ul:before {
  background-color: #be0081;
}
.reservation-header > ul > li.active ul:after {
  background-color: #f7f7f7;
}
.reservation-header > ul > li.active ul li:first-child .timeline-check {
  position: relative;
  width: 22px;
  height: 22px;
  background-color: #be0081;
  border-radius: 50%;
}
.reservation-header > ul > li.active ul li:first-child:before {
  content: "";
  position: absolute;
  display: block;
  width: 7px;
  height: 7px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: white;
  z-index: 99;
}
.reservation-header > ul > li.active ul li:nth-child(2) {
  color: #be0081;
}
.reservation-header > ul > li.active ul li:last-child {
  color: #be0081;
  font-weight: bold;
}

.reservation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0 110px 5%;
}
@media screen and (max-width: 884px) {
  .reservation {
    padding: 60px 6.25%;
  }
}
@media screen and (max-width: 884px) {
  .reservation {
    flex-direction: column;
    justify-content: flex-start;
  }
}
.reservation-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 60px;
  width: 63.75%;
  flex: 1 1 auto;
}
@media screen and (max-width: 884px) {
  .reservation-left {
    width: 100%;
    padding-right: 0;
  }
}
.reservation-left .p-subtitle {
  order: -1;
}
@media screen and (min-width: 885px) {
  .reservation-left .p-subtitle {
    display: none;
  }
}
.reservation-left-title {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 4px;
  color: #94bec5;
}
.reservation-left .h2-title {
  padding-bottom: 30px;
}
.reservation .recap-addresses {
  display: flex;
  flex-direction: row;
  margin: -30px -10px 35px 0;
  flex-wrap: wrap;
  width: 100%;
}
.reservation .recap-addresses > div {
  margin: 30px 10px 0 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  max-width: 410px;
  width: 100%;
}
.reservation .recap-addresses > div .addresses-block {
  margin-top: 30px;
  padding: 35px 20px 20px 35px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-between;
}
.reservation .recap-addresses > div .addresses-block p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  color: black;
  margin-bottom: 5px;
}
.reservation .recap-addresses > div .addresses-block p.addresses-block-name {
  font-weight: bold;
  margin-bottom: 20px;
}
.reservation .recap-addresses > div .addresses-block .btn-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-items: flex-end;
}
.reservation .recap-addresses > div .addresses-block .btn-container .btn-trans {
  text-transform: uppercase;
  padding: 0 35px;
  margin-left: auto;
}
.reservation-right {
  display: flex;
  position: relative;
  background-color: white;
  max-width: 454px;
  min-width: 354px;
  width: 31%;
  flex: 1 0 auto;
}
@media screen and (min-width: 885px) {
  .reservation-right.bottom {
    align-self: flex-end;
  }
}
@media screen and (max-width: 884px) {
  .reservation-right {
    max-width: 100%;
    min-width: auto;
    width: 100%;
  }
}
.reservation-right-inner {
  position: relative;
  top: auto;
  right: auto;
  max-width: 454px;
  min-width: 354px;
  width: 31.5277777778vw;
  padding-right: 20px;
  background-color: white;
}
@media screen and (max-width: 884px) {
  .reservation-right-inner {
    max-width: 100%;
    min-width: auto;
    width: 100%;
    padding-right: 0;
  }
}
@media screen and (min-width: 885px) {
  .reservation-right-inner.sticky {
    position: fixed;
    top: 0;
    right: 0;
  }
}
.reservation-ticket {
  width: 100%;
  background-color: #2d2f38;
  padding: 20px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.reservation-ticket-recap {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
}
.reservation-ticket-recap-title {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  color: white;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px;
}
.reservation-ticket-recap-list ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  width: 100%;
}
.reservation-ticket-recap-list ul li:last-child {
  text-align: right;
  white-space: nowrap;
}
.reservation-ticket-recap-list ul:last-child {
  font-size: 24px;
  font-weight: bold;
  border-top: 1px solid #6f6f6f;
  padding: 25px 0;
  margin-top: 15px;
}
.reservation-ticket .btn-submit {
  margin-top: 15px;
  padding: 0 65px;
}
.reservation-ticket .btn-submit:hover {
  background-color: #ffffff;
  border-color: #ffffff;
}
.reservation-ticket .btn-edit {
  font-family: "Open Sans", sans-serif;
  margin-top: 40px;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  color: white;
  text-transform: uppercase;
}
.reservation-ticket .btn-edit:hover {
  text-decoration: underline;
}
.reservation-payment-methods {
  width: 100%;
  padding: 40px 40px 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  background-color: #f7f7f7;
}
.reservation-payment-methods-title {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 2.3px;
  color: #2d2f38;
  text-transform: uppercase;
}
.reservation-payment-methods-subtitle {
  margin-top: 10px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  color: black;
}
.reservation-payment-methods .payment-methods {
  margin-bottom: 30px;
}
.reservation-payment-methods .client {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}
.reservation-payment-methods .client .icon-container {
  width: 35px;
  height: 35px;
  margin: 0 10px;
}
.reservation-payment-methods .client .icon-container .icon-phone {
  width: 19px;
  height: 20px;
}
.reservation-payment-methods .client .icon-container .icon-mail {
  width: 19px;
  height: 15px;
}
.reservation-payment-methods .client .icon-container .icon-faq {
  width: 19px;
  height: 19px;
}
.reservation.paiement .reservation-center {
  margin: auto;
  padding-right: 5%;
}
.reservation.paiement .reservation-center .reservation-left-title {
  margin-bottom: 30px;
}
.reservation.paiement .form-group {
  flex-wrap: wrap;
  margin: 10px auto;
}
@media screen and (max-width: 884px) {
  .reservation.paiement .form-group {
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
  }
}
.reservation.paiement .form-group .form-check-container-radio {
  margin: 20px 40px;
}
@media screen and (max-width: 884px) {
  .reservation.paiement .form-group .form-check-container-radio {
    margin: 20px 0;
  }
}
.reservation.paiement .form-group .form-check-container-radio .form-check-label {
  padding-left: 40px;
}
.reservation.paiement .form-group .form-check-container-radio .form-check-label img:last-child {
  margin-left: 10px;
}
.reservation.confirmation {
  padding: 0 6.25% 110px 6.25%;
}
@media screen and (max-width: 884px) {
  .reservation.confirmation {
    padding: 60px 6.25%;
  }
}
.reservation.confirmation .reservation-left {
  align-items: center;
  margin: 0;
}
@media screen and (max-width: 884px) {
  .reservation.confirmation .reservation-left {
    align-items: flex-start;
  }
}
.reservation.confirmation .reservation-left .h2-title {
  max-width: 500px;
  text-align: center;
}
@media screen and (max-width: 884px) {
  .reservation.confirmation .reservation-left .h2-title {
    text-align: left;
  }
}
.reservation.confirmation .reservation-left-subtitle {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: 0.5px;
  margin: 25px 0 30px 0;
  color: #2d2f38;
}
@media screen and (max-width: 884px) {
  .reservation.confirmation .reservation-left-subtitle {
    text-align: left;
  }
}
.reservation.confirmation .reservation-left .btn-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: -10px 0;
}
.reservation.confirmation .reservation-left .btn-container .btn-trans, .reservation.confirmation .reservation-left .btn-container .btn-full {
  margin: 10px 10px;
  padding: 0;
  width: 218px;
}
.reservation.confirmation .reservation-left .btn-container .btn-full {
  padding: 0 10px;
  width: auto;
  min-width: 218px;
}
.reservation.confirmation .reservation-left .btn-container:last-child {
  margin: 20px 0 -10px 0;
}
.reservation .reservation-item-container {
  margin-top: 40px;
  width: 100%;
}
.reservation .reservation-item-container .btn-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 900px;
}
.reservation .reservation-item-container .btn-container .btn-trans-purple {
  padding: 0 80px;
}
.reservation .reservation-item-container .btn-container .btn-blue {
  padding: 0 75px;
  background-color: #94bec5;
}
.reservation .reservation-item-container .btn-container .btn-blue:hover {
  color: #94bec5;
  background-color: transparent;
  border: 2px solid #94bec5;
}
.reservation .reservation-item-container .btn-container .date-picker-container {
  flex: 1 0 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  /*.selected-date {
    color: $medium-grey;
    font-size: 18px;
    font-weight: 600;

    input#date-picker {
      color: $purple;
      font-size: 18px;
      font-weight: 600;
    }
  }*/
}
@media screen and (min-width: 884px) {
  .reservation .reservation-item-container .btn-container .date-picker-container #date-picker-disponibilite {
    max-width: 70%;
  }
}
.reservation .reservation-item-container .btn-container .date-picker-container .datepicker {
  margin-right: 0;
  width: 100%;
}
.reservation .reservation-item-container .btn-container .date-picker-container .datepicker--content .datepicker--cell {
  margin: 10px;
  min-height: 32px;
  color: #ffffff;
}
@media screen and (min-width: 884px) {
  .reservation .reservation-item-container .btn-container .date-picker-container .datepicker--content .datepicker--cell {
    margin: 10px 20px;
  }
}
.reservation .reservation-item-container .btn-container .date-picker-container .datepicker--content .datepicker--cell.-disabled- {
  background-color: #f7f7f7;
  color: #94bec5;
}
.reservation .reservation-item-container .btn-container .date-picker-container .datepicker--content .datepicker--cell-day {
  width: calc(14.2857142857% - 20px);
}
@media screen and (min-width: 884px) {
  .reservation .reservation-item-container .btn-container .date-picker-container .datepicker--content .datepicker--cell-day {
    width: calc(14.2857142857% - 40px);
  }
}
.reservation .reservation-item-container .btn-container .date-picker-container #legend-disponibilite {
  margin-top: 6px;
  margin-bottom: 24px;
}
@media screen and (min-width: 884px) {
  .reservation .reservation-item-container .btn-container .date-picker-container #legend-disponibilite ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
.reservation .reservation-item-container .btn-container .date-picker-container #legend-disponibilite ul li {
  padding-left: 18px;
  position: relative;
  margin-right: 10px;
}
.reservation .reservation-item-container .btn-container .date-picker-container #legend-disponibilite ul li:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.reservation .reservation-item-container .btn-container .date-picker-container #legend-disponibilite ul li.unavailable:after {
  background-color: #f7f7f7;
}
.reservation .reservation-item-container .btn-container .date-picker-container #legend-disponibilite ul li.available:after {
  background-color: #94bec5;
}
.reservation .reservation-item-container .btn-container .date-picker-container #legend-disponibilite ul li.promotion:after {
  background-color: #f2c524;
}
@media screen and (max-width: 884px) {
  .reservation .reservation-item-container .update-reservation {
    margin: 0 auto 60px;
  }
}
.reservation #display-option {
  padding-top: 15px;
}
.reservation .reservation-item {
  padding-bottom: 60px;
}
.reservation .reservation-item-text {
  margin-top: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  color: black;
}
.reservation .reservation-item .grid-container {
  margin: 30px 0 0 0;
}
.reservation .reservation-item .grid-container .grid {
  margin: 0;
}
.reservation .reservation-item .grid-container .form-group-resa .quantity-button span {
  margin-top: -6px;
}
.reservation .reservation-item .grid-item {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}
.reservation .reservation-item .form-group {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
@media screen and (max-width: 884px) {
  .reservation .reservation-item #option-scintillant {
    max-width: 100%;
  }
  .reservation .reservation-item #option-scintillant .btn-trans {
    max-width: 100%;
    font-size: 13px;
  }
}
.reservation .reservation-item #option-scintillant .btn-trans {
  margin-top: 0;
}
.reservation .reservation-item.promo-item {
  padding-bottom: 30px;
}
@media screen and (max-width: 884px) {
  .reservation .reservation-item.promo-item {
    padding-bottom: 0;
  }
}
.reservation .reservation-item.promo-item .form-group {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.reservation .reservation-item.promo-item .form-group input.form-control {
  max-width: 300px;
}
.reservation .reservation-item.promo-item .form-group label {
  flex: 1 1 auto;
}
.reservation .reservation-item .parsley-error-text,
.reservation .reservation-item .alert-danger {
  color: #d35b5b;
}
.reservation .reservation-item .parsley-error-text + .input-validation,
.reservation .reservation-item .alert-danger + .input-validation {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  flex-shrink: 0;
  top: calc(50% - 10px - 8px);
  right: -35px;
  background-color: #d35b5b;
  transform: rotate(45deg);
}
.reservation .reservation-item .parsley-error-text + .input-validation:before,
.reservation .reservation-item .alert-danger + .input-validation:before {
  content: "";
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 2px;
  height: 12px;
  background-color: white;
}
.reservation .reservation-item .parsley-error-text + .input-validation:after,
.reservation .reservation-item .alert-danger + .input-validation:after {
  content: "";
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 0;
  width: 12px;
  height: 2px;
  background-color: white;
}
.reservation .reservation-item-element {
  margin-top: 30px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  text-transform: none;
  letter-spacing: 1px;
  color: #6f6f6f;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  /*background-color: white;*/
  width: 100%;
  /* &.reservation-sub-type{
     display: none;
   }*/
}
.reservation .reservation-item-element.select-custom {
  max-width: 900px;
}
.reservation .reservation-item-element:not(select) {
  padding: 0 20px;
}
.reservation .reservation-item-element#date-picker {
  height: 70px;
  padding: 0 86px 0 20px;
  max-width: 320px;
  background-image: url("../../front/images/calendar.svg");
  background-position: calc(100% - 20px) 50%;
  background-size: 46px 46px;
  background-repeat: no-repeat;
}
.reservation .reservation-item-element#date-picker:after {
  content: "";
  display: block;
}
.reservation .reservation-item-element.quantity {
  display: flex;
  align-items: center;
  flex-direction: row;
  /*justify-content: flex-end;*/
  max-width: 900px;
}
.reservation .reservation-item-element.quantity .quantity-inner {
  display: flex;
  /*align-items: center;*/
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -65px;
  width: 100%;
  flex: 1;
}
@media screen and (max-width: 1110px) {
  .reservation .reservation-item-element.quantity .quantity-inner {
    flex-direction: column;
    align-items: flex-end;
    margin: 0;
  }
}
.reservation .reservation-item-element.quantity .form-group-resa {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 65px;
  height: auto;
  min-height: 70px;
  flex: 1 1 50%;
}
.reservation .reservation-item-element.quantity .form-group-resa.hidden {
  display: none;
}
@media screen and (max-width: 1110px) {
  .reservation .reservation-item-element.quantity .form-group-resa {
    padding: 0;
    flex: 1 1 auto;
  }
}
.reservation .reservation-item-element.quantity .quantity-number {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  color: #c5c5c5;
  margin: 0 20px;
  max-width: 30px;
  text-align: center;
}
.reservation .reservation-item-element.quantity .quantity-number:disabled {
  background: #fff;
}
.reservation .reservation-item-element.quantity .quantity-label {
  position: relative;
  margin-right: 30px;
}
.reservation .reservation-item-element.quantity .quantity-label .tooltip {
  position: absolute;
  top: 0;
  right: -5px;
  transform: translate(100%, -50%);
  width: 15px;
  height: 15px;
  background-image: url("../../front/images/info.svg");
  background-position: center;
  background-color: transparent;
  background-size: 15px 15px;
}
.reservation .reservation-item-element.quantity .quantity-button {
  user-select: none;
  cursor: pointer;
  flex: 0 0 auto;
  position: relative;
  width: 35px;
  height: 35px;
}
.reservation .reservation-item-element.quantity .quantity-button .icon {
  width: 35px;
  height: 35px;
  fill: #94bec5;
  position: absolute;
  z-index: -1;
}
.reservation .reservation-item.results-item {
  margin-top: 30px;
  max-width: 900px;
}
.reservation .reservation-item.results-item .reservation-item-text {
  font-weight: bold;
  color: #2d2f38;
  margin-top: 30px;
}
.reservation .reservation-item.results-item .reservation-item-text span {
  color: #be0081;
}
.reservation .reservation-item.results-item .reservation-item-empty {
  margin-top: 30px;
  padding: 25px 20px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 1px;
  background-color: #be0081;
  margin-bottom: 40px;
}
.reservation .reservation-item.results-item .results-item-element {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 1px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}
.reservation .reservation-item.results-item .results-item-element a {
  text-decoration: underline;
}
.reservation .reservation-item.results-item .results-item-element.show .results-item-element-heading-hidden {
  display: flex;
}
.reservation .reservation-item.results-item .results-item-element.show .infos-toggle a.more-info {
  display: none;
}
.reservation .reservation-item.results-item .results-item-element.show .infos-toggle a.less-info {
  display: flex;
}
.reservation .reservation-item.results-item .results-item-element .infos-toggle a {
  text-align: right;
  flex-direction: row;
  align-items: center;
}
.reservation .reservation-item.results-item .results-item-element .infos-toggle a.more-info {
  display: flex;
}
.reservation .reservation-item.results-item .results-item-element .infos-toggle a.more-info:after {
  transform: rotate(90deg);
}
.reservation .reservation-item.results-item .results-item-element .infos-toggle a.less-info {
  display: none;
}
.reservation .reservation-item.results-item .results-item-element .infos-toggle a.less-info:after {
  transform: rotate(-90deg);
}
.reservation .reservation-item.results-item .results-item-element .infos-toggle a:after {
  content: "";
  background-image: url("../../front/images/arrow-white.svg");
  background-position: center;
  background-color: transparent;
  background-size: 8px 12px;
  display: block;
  margin: 0 15px;
  width: 8px;
  height: 12px;
}
.reservation .reservation-item.results-item .results-item-element-heading {
  background: #94bec5 url("../../front/images/calendar-white.svg") 20px center/18px auto no-repeat;
  color: white;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 50px;
  cursor: pointer;
}
.reservation .reservation-item.results-item .results-item-element-heading-hidden {
  background-color: #94bec5;
  color: white;
  padding: 0 20px 20px 20px;
  line-height: 1.8;
  display: none;
}
.reservation .reservation-item.results-item .results-item-element-radio {
  padding: 20px 95px 45px 20px;
  background-color: white;
  color: #2d2f38;
}
.reservation .reservation-item.results-item .results-item-element .form-group.form-check {
  margin: 30px -60px -20px -60px;
  font-weight: bold;
  font-family: "Alegreya Sans", sans-serif;
  justify-content: flex-start;
  flex-wrap: wrap;
}
@media screen and (max-width: 359px) {
  .reservation .reservation-item.results-item .results-item-element .form-group.form-check {
    margin: 30px 0 -20px 0;
  }
}
.reservation .reservation-item.results-item .results-item-element .form-group.form-check .form-check-container-radio {
  margin: 0 60px 20px 60px;
}
@media screen and (max-width: 359px) {
  .reservation .reservation-item.results-item .results-item-element .form-group.form-check .form-check-container-radio {
    margin: 0 0 20px 0;
  }
}
.reservation .reservation-item.results-item .results-item-element .form-group.form-check .form-check-container-radio input:checked + .form-check-checkmark {
  background-color: #be0081;
}
.reservation .reservation-item.results-item .results-item-element .form-group.form-check .form-check-container-radio .icon-container {
  position: absolute;
  right: -30px;
  width: 20px;
  height: 20px;
}
.reservation .reservation-item.results-item .results-item-element .form-group.form-check .form-check-container-radio .icon-container:before {
  background-color: #ffffff;
  color: #000;
  min-width: 180px;
  text-align: left;
  padding: 20px;
  font-family: "Open Sans", sans-serif;
  line-height: 20px;
  font-size: 13px;
  box-shadow: 1px 2px 10px 0px grey;
}
@media screen and (min-width: 1100px) {
  .reservation .reservation-item.results-item .results-item-element .form-group.form-check .form-check-container-radio .icon-container:before {
    min-width: 240px;
  }
}
.reservation .reservation-item.results-item .results-item-element .form-group.form-check .form-check-container-radio .icon-container:after {
  border-top-color: #ffffff;
}
.reservation .reservation-item.results-item .results-item-element .form-group.form-check .form-check-container-radio .icon-container .icon {
  width: 13px;
  height: 13px;
}
.reservation .reservation-item.results-item .results-item-element .form-group.form-check label {
  padding-left: 40px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.reservation .reservation-item.results-item .results-item-element .form-group.form-check label .item-price {
  color: #be0081;
}
.reservation .reservation-item.results-item .results-item-element .form-group.form-check label .item-promo {
  background-color: #2ab0eb;
  color: white;
  padding: 4px 10px;
  font-size: 12px;
  letter-spacing: 1px;
  position: absolute;
  top: 0;
  right: -5px;
  transform: translateX(100%);
}
.reservation .reservation-item.results-item .btn-container {
  margin: 30px 0 -20px;
  flex-wrap: wrap;
}
.reservation .reservation-item.results-item .btn-container .form-group {
  margin-right: 30px;
  flex: 1 1 40%;
}
@media screen and (min-width: 1100px) {
  .reservation .reservation-item.results-item .btn-container {
    flex-wrap: nowrap;
  }
}
.reservation .reservation-item.results-item .btn-container > * {
  margin: 0 30px 20px;
}
@media screen and (max-width: 884px) {
  .reservation .reservation-item.results-item .btn-container > * {
    margin: 0 0 20px 0;
  }
}
.reservation .reservation-item.results-item .btn-container .form-group:last-child,
.reservation .reservation-item.results-item .btn-container .select-cruise {
  margin-right: 0;
}
.reservation .reservation-item.results-item .btn-container p {
  margin: auto;
  margin-bottom: 20px;
  flex: 1 1 100%;
}
@media screen and (min-width: 1100px) {
  .reservation .reservation-item.results-item .btn-container p {
    flex: 1 1 auto;
  }
}
.reservation .reservation-item .btn-container {
  margin: 30px -50px -20px -50px;
  justify-content: flex-end;
  flex-wrap: wrap;
}
@media screen and (max-width: 884px) {
  .reservation .reservation-item .btn-container {
    margin: 30px 0 -20px 0;
  }
}
.reservation .reservation-item .btn-container > * {
  margin: 0 50px 20px 50px;
}
@media screen and (max-width: 884px) {
  .reservation .reservation-item .btn-container > * {
    margin: 0 0 20px 0;
  }
}
.reservation .reservation-item .btn-container > p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.7;
  color: black;
  flex: 1 1 35%;
}
@media screen and (max-width: 884px) {
  .reservation .reservation-item .btn-container > p {
    flex: 1 1 auto;
  }
}
.reservation .reservation-item .btn-container > p a {
  font-family: "Lato", sans-serif;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0.5px;
  color: #94bec5;
  text-transform: uppercase;
}
.reservation .reservation-item .btn-container > p a:hover {
  text-decoration: underline;
}
.reservation .reservation-item .success-firstname {
  color: #FFF;
  margin: 20px 0 0 0;
  background: #86d788;
  padding: 6px;
}

.contact {
  padding-top: 60px;
}
.contact-submit {
  flex-direction: column;
}
.contact-submit .btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 10px;
}
.contact .p-subtitle {
  order: unset;
  display: block;
}
.contact .hideform {
  display: none;
}
.contact .form-submit {
  margin-top: 15px;
  color: black;
}
.contact .reservation-item-container .btn-submit {
  margin: 25px auto 60px auto;
}
.contact .contact-ticket a {
  text-decoration: underline;
  font-weight: bold;
}
.contact .contact-ticket-header-list {
  margin: 20px 0;
  font-weight: bold;
}
.contact .contact-ticket-list {
  list-style-type: circle;
  margin: 0 0 20px 40px;
  line-height: 1.5;
}
.contact .contact-ticket-ref {
  font-size: 10px;
}
.contact .contact-ticket .btn-submit {
  margin-top: 30px;
}
.contact .contact-type-2-1 input, .contact .contact-type-1-4 input {
  max-width: 320px;
}
.contact .contact-type-2-1 .form-group, .contact .contact-type-1-4 .form-group {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;
}
.contact .contact-message .form-group {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;
}
.contact .label-helper {
  font-size: 10px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  color: #94bec5;
  letter-spacing: normal;
  text-transform: none;
}

.gab-error {
  padding: 80px;
}
.gab-error .upper {
  margin-top: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  color: black;
}
.gab-error .btn-trans {
  display: inline-block;
  margin-top: 120px;
}