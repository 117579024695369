/* ==========================================================================
   Layout / Footer
   ========================================================================== */

.footer{
  margin-top: 3px;
}

.footer .footer-1 {
  width: 100%;

  background-color: $dark-grey;
  padding: 32px 0 50px 0;

  .footer-title{
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: 4px;
    text-align: center;
    text-transform: uppercase;
    color: $flat-blue;
  }

  &-left{
    .social-icons{
      display: flex;
      margin-top: 32px;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 767px) {
        margin-top: 20px;
      }
    }

    .icon{
      margin-right: 15px;
      margin-left: 15px;
      fill: white;
    }

    .icon-facebook{
      width: 12px;
      height: 25px;
    }

    .icon-twitter{
      width: 25px;
      height: 20px;
    }

    .icon-instagram{
      width: 25px;
      height: 25px;
    }

    .icon-linkedin{
      width: 25px;
      height: 25px;
    }
  }

  &-right{

    @media screen and (max-width: 767px) {
      margin-top: 65px;
    }

    /*.button-newsletter-container{
      display: flex;
      align-items: center;
      justify-content: center;
    }*/

    .email-response{
      color: white;
      padding: 10px 0;
      display: none;
    }
    a.button-newsletter {
      display: none;
    }
    /*a.button-newsletter{
      margin: 24px auto 10px auto;
      padding: 0 50px 0 50px;
      height: 50px;
      border-radius: 30px;
      border: solid 2px white;
      font-size: 15px;
      font-weight: bold;
      color: white;
      text-transform: uppercase;
      text-align: center;
      line-height: 50px;
      letter-spacing: 1px;

      @media screen and (min-width: 768px) {
        //display: none;
      }

      @media screen and (max-width: 359px) {
        letter-spacing: normal;
      }
    }*/

    form.show{
      .form-check-container-checkbox{
        display: flex;
      }
      .parsley-errors-list{
        display: block;
      }
    }

    .form-check-container-checkbox{
      display: none;
      margin-top: 10px;
      text-align: left;
      color: white;
      font-size: 12px;
      max-width: 300px;

      .form-check-label{
        padding-left: 30px;
      }
    }

    .parsley-errors-list{
      text-align: left;
      display: none;
    }

    .form-input {
      border: 0;
      border-bottom: 1px solid $grey;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      @media screen and (max-width: 767px) {
        margin: 0 20px;
      }

      input[type=email] {
        width: 100%;
        margin-top: 32px;
        font-size: 16px;
        font-weight: 300;
        color: white;
        background-color: transparent;
        border: 0;
        font-family: $font-stack-common;

        &::placeholder {
          font-size: 16px;
          font-weight: 300;
          color: white;
          letter-spacing: 0.3px;
        }
      }

      button[type=submit] {
        width: 18px;
        height: 18px;
        background-image: url('../../front/images/arrow-right.svg');
        background-position: center;
        background-color: transparent;
        background-size: 18px 18px;
        border: 0;
        cursor: pointer;
      }
    }
  }

  &-container{
    margin-top: 42px;
    display: flex;
    justify-content: space-around;
    text-align: center;
    align-items: center;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    .footer-title-right{
      padding: 0 20px 0 20px;
    }

    .footer-title-right, .footer-title-left{
      font-size: 22px;
      font-weight: 300;
      color: white;

      b{
        font-weight: 500;
      }
    }
  }

}

.footer-2{
  margin-top: 5px;
  background-color: #dfe5e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 47px (196px / 1440px * 100%) 45px (196px / 1440px * 100%);

  @media screen and (max-width: 1199px) {
    flex-direction: column;
  }

  .nav-footer-container{
    display: flex;
    align-items: center;

    @media screen and (max-width: 767px){
      flex-direction: column;
    }

    > img{
      width: 120px;
      height: 120px;

      @media screen and (max-width: 767px){
        width: 82px;
        height: 82px;
      }
    }

    .nav-footer-logo {
      min-width: 150px;
      text-align: center;

      > span {
        display: block;
        font-size: 14px;
        padding: 15px 0;
        font-weight: bold;
        letter-spacing: 0.5px;
        text-align: center;
      }
    }

    .nav-footer{
      margin: 0 92px 0 92px;
      display: flex;
      font-size: 13px;
      font-weight: normal;
      color: black;
      text-transform: uppercase;
      letter-spacing: 1px;

      @media screen and (max-width: 767px){
        margin: 24px 20px 0 20px;
        font-weight: bold;
      }

      @media screen and (max-width: 559px){
        flex-direction: column;
        text-align: center;
      }

      ul li{
        padding: 4px 0;
        a {
          display: inline-flex;
          padding: 4px;
        }
      }

      ul:last-child{
        margin-left: 52px;

        @media screen and (max-width: 559px){
          margin-left: 0;
        }
      }
    }
  }

  .labels{
    text-align: center;

    @media screen and (max-width: 1199px) {
      margin-top: 26px;
    }

    .payment-methods-text{
      text-transform: uppercase;
      margin-top: 39px;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 5px;
      color: $dark-grey;
    }

    .badges{
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
      }

      img {
        width: auto;
        @media screen and (max-width: 767px) {
          height: 64px;
          width: auto;
          margin: 10px;
        }
      }

      &-pass-nantes{
        margin: 0 20px 0 20px;
      }

      &-lavelodyssee{
        margin: 0 0 0 20px;
      }

    }
  }
}

.payment-methods{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;

  img{
    width: 66px;
    height: 43px;

    @media screen and (max-width: 767px){
      width: 51px;
      height: 33px;
    }

    &.method-visa{
      margin: 0 20px 0 20px;
    }
  }
}

.footer-3{
  padding: 20px 0 6px 0;
  margin: 0;

  ul{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    li{
      padding: 0 14px;
      margin-bottom: 14px;

      a {
        font-size: 14px;
        font-weight: normal;
        color: $dark-grey;
        text-decoration: none;
        padding: 6px;
      }

    }

  }
}
