.creation-compte{
  padding: 60px (90px / 1440px * 100%) 25px (90px / 1440px * 100%);
  .h2-title{
    padding-bottom: 30px;
    text-align: center;
  }

  .btn-submit{
    margin: 0 auto 25px auto;
    padding: 0 35px;

    span{
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  &-success{
    font-family: $font-open;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    color: $green;
    padding: 10px 0;
  }
  .navigation-product {
    position: absolute;
    top: -70px;
    left: 0;
  }

}

.form-section{
  margin-bottom: 60px;

  &-title{
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 4px;
    color: $flat-blue;
    text-align: left;
    text-transform: uppercase;
  }

  &-block, &-flex{
    margin-top: 30px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
  }

  &-flex{
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;

    .btn-full{
      margin-top: 30px;
      align-self: center;
      margin-bottom: 20px;
      //max-width: 100%;
      padding: 0 20px;
      @media screen and (min-width: 769px) {
        margin-top: 10px;
        margin-right: 55px;
        align-self: flex-end;
      }

      @media screen and (max-width: 767px){
        //margin: 10px (90px/ 1440px * 100%) 0 (90px/ 1440px * 100%);
      }
    }
  }

  &-block{
    padding: 40px 55px 40px 55px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;

    @media screen and (max-width: 767px) {
      padding: 40px (90px / 1440px * 100%) 10px (90px / 1440px * 100%);
    }

    .btn-full{
      margin-top: 30px;
      align-self: center;
      margin-bottom: 20px;
      //max-width: 100%;
      padding: 0 20px;
      @media screen and (min-width: 769px) {
        margin-top: 10px;
        align-self: flex-end;
      }
    }
  }

  &-block-inner{
    max-width: 520px;
    @media screen and (min-width: 1124px) {
      margin-bottom: -30px;
    }
  }

  &-flex-inner{
    padding: 40px 55px 0 55px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin: -20px -20px 0 -20px;

    @media screen and (max-width: 767px) {
      padding: 40px (90px / 1440px * 100%) 10px (90px / 1440px * 100%);
    }

    > div{
      max-width: 520px;
      flex: 1 1 auto;

      margin: 20px 20px 0 20px;

      .form-group{
        flex: 1 1 auto;
      }
    }

    @media screen and (min-width: 1039px) {
      .mt-exc {
        margin-top: 36px;
        margin-bottom: 14px;
      }
    }

  }

  &.order-history{

    .order-history-ordernum{
      font-weight: normal;
    }

    .order-history-price{
      color: $purple;
      min-width: 90px;
      justify-content: flex-end;
      sup{
        margin-left: 2px;
      }
    }

    ul{
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: -10px;
    }

    li{
      width: 100%;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      background-color: white;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 10px 0;
      flex-wrap: wrap;

      @media screen and (max-width: 767px) {
        padding: 0 15px;
      }

      > div{
        display: flex;
        flex-direction: row;
        align-items: center;
        @media screen and (max-width: 767px) {
          flex-wrap: wrap;
          height: auto;
        }
      }

      > div > div, > div > p{
        margin: 0 30px;
        height: 70px;
        display: flex;
        align-items: center;

        @media screen and (max-width: 767px) {
          margin: 0 15px;
        }
      }

      > div:first-child{
        font-family: $font-open;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        justify-content: flex-start;
        color: $dark-grey;
      }

      > div:last-child{
        font-family: $font-stack-common;
        font-size: 15px;
        font-weight: bold;
        font-style: normal;
        letter-spacing: 0.8px;
        color: $flat-blue;
        text-transform: uppercase;
        > div {
          min-width: 200px;
        }

        a{
          display: flex;
          align-items: center;

          &:hover{
            text-decoration: underline;
          }
        }
      }

      .icon-container{
        width: 36px;
        height: 36px;
        flex: 0 0 auto;
        margin-right: 30px;
        .icon-dl{
          width: 20px;
          height: 20px;
        }
      }
    }

  }

  &-banner{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 45px;
    line-height: 25px;
    font-family: $font-open;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    color: black;
    background-color: $light-grey;
    padding: 10px 30px;

    .form-check-container-checkbox{
      .form-check-checkmark{
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .form-group-inline{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;


    @media screen and (max-width: 767px) {
      margin: 0;
    }

    .form-group{
      margin: 0 20px 30px 0;
      justify-content: flex-end;
      flex: 1 1 0%;

      @media screen and (max-width: 767px) {
        flex: 1 1 100%;
        margin: 0 0 30px 0;
      }

      &:last-child{
        margin-right: 0;
      }

      select.form-control,
      input.form-control{
        width: 100%;
        padding: 0 25px;
      }

      &.form-group-delivery-phone, &.form-group-fact-phone{
        flex: 3 1 0;
      }
      &.form-group-fact-phone{
        z-index: 1000;
      }
    }
  }
}

.form-group{
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  font-family: $font-open;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;

  #account-birthdate{
    max-width: 300px;
  }

  &-helper{
    font-size: 12px;
    font-style: italic;
    color: $dark-grey;

    &-fake{
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  label{
    color: black;
  }

  .label-helper{
    font-size: 10px;
    font-weight: bold;
    color: $flat-blue;
  }

  input.form-control, textarea.form-control,select.form-control{
    font-family: $font-open;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    color: $dark-grey;
    width: 100%;
    height: 50px;
    border: solid $grey 2px;
    margin-top: 8px;

    &::placeholder{
      font-family: $font-open;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      color: $grey;
    }
  }

  input.form-control{
    line-height: 46px;
    padding: 0 25px;
  }

  textarea.form-control{
    height: 160px;
    resize: none;
    padding: 15px 25px;
  }

  &.form-check{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .form-check-container-radio{
    margin-left: 40px;

    @media screen and (max-width: 767px) {
      margin-left: 20px;
    }

    @media screen and (max-width: 359px) {
      margin-left: 5px;
    }

    .form-check-label{
      @media screen and (max-width: 767px) {
        padding-left: 25px;
      }
    }
  }

  .civility-error{
    margin-left: 10px;
  }

  .input-container{
    display: flex;
    flex-direction: column;
    position: relative;

    input.parsley-success ~ .input-validation{
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      flex-shrink: 0;
      top: calc(50% - 10px);
      right: -35px;
      background-color: $green;

      &:after{
        content: "";
        position: absolute;
        display: block;
        left: 7px;
        top: 4px;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }

    input.parsley-error ~ .input-validation{
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      flex-shrink: 0;
      top: calc(50% - 10px);
      right: -35px;
      background-color: #d35b5b;
      transform: rotate(45deg);

      &:after{
        content: "";
        position: absolute;
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border: 0;
        width: 12px;
        height: 2px;
        background-color: white;
      }

      &:before{
        content: "";
        position: absolute;
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 2px;
        height: 12px;
        background-color: white;
      }
    }
  }
}

.creation-compte .checkboxs-section .form-check {
  margin-bottom: 40px;
}

.checkboxs-section{

  .parsley-errors-list {
    padding-left: 40px;

    .parsley-required {
      font-weight: 800;
      font-size: 13px;
      color: #e14242;
    }
  }

  .form-check{
    font-family: $font-open;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    //margin-bottom: 40px;
    //max-width: 770px;

    a{
      font-weight: bold;
      color: $flat-blue;

      &:hover{
        text-decoration: underline;
      }
    }
  }

  .g-recaptcha {
    display: flex;
    justify-content: center;

    @media screen and (min-width: 425px) {
      display: block;
    }
  }

  &-title{
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    color: $flat-blue;
    margin-bottom: 15px;
  }
}