.reservation-header{
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding: 75px (90px / 1440px * 100%);

  @media screen and (max-width: 884px) {
    display: none;
  }

  > ul{
    width: 100%;
    max-width: 1040px;
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    > li{
      height: 100%;
      flex: 1 1 25%;

      &:first-child > ul:before{
        display: none;
      }

      &:last-child > ul:after{
        display: none;
      }

      > ul{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;
        height: 100%;

        &:before{
          content: "";
          display: block;
          position: absolute;
          height: 6px;
          width: calc(50% - 11px);
          background-color: $light-grey;
          top: 11px;
          left: 0;
          transform: translateY(-50%);
        }

        &:after{
          content: "";
          display: block;
          position: absolute;
          height: 6px;
          width: calc(50% - 11px);
          background-color: $light-grey;
          top: 11px;
          right: 0;
          transform: translateY(-50%);
        }

        li:first-child{
          position: relative;
          .timeline-check{
            position: relative;
            width: 22px;
            height: 22px;
            background-color: $grey;
            border-radius: 50%;
            z-index: 50;
          }
        }

        > li:nth-child(2){
          margin-top: 6px;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 14px;
          font-weight: 300;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: 1px;
          text-align: center;
          color: $dark-grey;
        }

        > li:last-child{
          margin-top: 6px;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-family: $font-second;
          font-size: 24px;
          font-weight: normal;
          font-style: normal;
          color: $grey;
        }
      }

      &.done ul{

        &:before, &:after {
          background-color: $purple;
        }

        li:first-child {
          .timeline-check {
            position: relative;
            width: 22px;
            height: 22px;
            background-color: $purple;
            border-radius: 50%;
          }

          &:after {
            content: "";
            position: absolute;
            display: block;
            left: 8px;
            top: 4px;
            width: 4px;
            height: 9px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            z-index: 99;
          }
        }

        li:last-child {
          color: $dark-grey;
          font-weight: normal;
        }

        &:hover {
          li:nth-child(2) {
            color: $purple;
          }
          li:last-child {
            color: $purple;
            font-weight: bold;
          }
        }
      }

      &.active ul{

        &:before
        {
          background-color: $purple;
        }

        &:after{
          background-color: $light-grey;
        }

        li:first-child {
          .timeline-check {
            position: relative;
            width: 22px;
            height: 22px;
            background-color: $purple;
            border-radius: 50%;
          }

          &:before{
            content: "";
            position: absolute;
            display: block;
            width: 7px;
            height: 7px;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            border-radius: 50%;
            background-color: white;
            z-index: 99;
          }
        }
        li:nth-child(2){
          color: $purple;
        }
        li:last-child{
          color: $purple;
          font-weight: bold;
        }
      }
    }
  }
}

.reservation{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0 110px 5%;

  @media screen and (max-width: 884px) {
    padding: 60px (90px/1440px*100%);
  }

  @media screen and (max-width: 884px) {
    flex-direction: column;
    justify-content: flex-start;
  }

  &-left{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 60px;
    //width: (896px / 1440px * 100vw);
    width: 63.75%;
    flex: 1 1 auto;

    @media screen and (max-width: 884px) {
      width: 100%;
      padding-right: 0;
    }

    .p-subtitle{
      order: -1;
      @media screen and (min-width: 885px) {
        display: none;
      }
    }

    &-title{
      text-transform: uppercase;
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 4px;
      color: $flat-blue;
    }

    .h2-title{
      padding-bottom: 30px;
    }
  }

  .recap-addresses{
    display: flex;
    flex-direction: row;
    margin: -30px -10px 35px 0;
    flex-wrap: wrap;
    width: 100%;

    > div{
      margin: 30px 10px 0 0;
      display: flex;
      flex-direction: column;
      flex: 1 1 0%;
      max-width: 410px;
      width: 100%;

      .addresses-block{
        margin-top: 30px;
        padding: 35px 20px 20px 35px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        justify-content: space-between;

        p{
          font-family: $font-open;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          color: black;
          margin-bottom: 5px;
        }

        p.addresses-block-name{
          font-weight: bold;
          margin-bottom: 20px;
        }

        .btn-container{
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          justify-items: flex-end;

          .btn-trans{
            text-transform: uppercase;
            padding: 0 35px;
            margin-left: auto;
          }
        }
      }
    }
  }

  &-right{
    display: flex;
    position: relative;
    background-color: white;
    max-width: 454px;
    min-width: 354px;
    //width: (454px / 1440px * 100vw);
    width: 31%;
    flex: 1 0 auto;

    &.bottom{
      @media screen and (min-width: 885px) {
        align-self: flex-end;
      }
    }

    @media screen and (max-width: 884px) {
      max-width: 100%;
      min-width: auto;
      width: 100%;
    }

    &-inner{
      position: relative;
      top: auto;
      right: auto;
      max-width: 454px;
      min-width: 354px;
      width: (454px / 1440px * 100vw);
      padding-right: 20px;
      background-color: white;

      @media screen and (max-width: 884px) {
        max-width: 100%;
        min-width: auto;
        width: 100%;
        padding-right: 0;
      }

      &.sticky{
        @media screen and (min-width: 885px) {
          position: fixed;
          top: 0;
          right: 0;
        }
      }
    }
  }

  &-ticket{
    width: 100%;
    background-color: #2d2f38;
    padding: 20px 40px 40px 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    &-recap {
      width: 100%;
      display: flex;
      flex-direction: column;
      color: white;
      font-family: $font-open;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;

      &-title {
        font-family: $font-open;
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        color: white;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 40px;
      }

      &-list {
        ul {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;
          width: 100%;

          li:last-child {
            text-align: right;
            white-space: nowrap;
          }

          &:last-child {
            font-size: 24px;
            font-weight: bold;
            border-top: 1px solid $medium-grey;
            padding: 25px 0;
            margin-top: 15px;
          }
        }
      }
    }

    .btn-submit{
      margin-top: 15px;
      padding: 0 65px;
      &:hover {
        background-color: $whiteColor;
        border-color: $whiteColor;
      }
    }

    .btn-edit{
      font-family: $font-open;
      margin-top: 40px;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      text-align: center;
      color: white;
      text-transform: uppercase;

      &:hover{
        text-decoration: underline;
      }
    }
  }

  &-payment-methods{
    width: 100%;
    padding: 40px 40px 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    background-color: $light-grey;

    &-title{
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: 2.3px;
      color: $dark-grey;
      text-transform: uppercase;
    }

    &-subtitle{
      margin-top: 10px;
      font-family: $font-open;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      color: black;
    }

    .payment-methods {
      margin-bottom: 30px;
    }

    .client{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 15px;

      .icon-container{
        width: 35px;
        height: 35px;
        margin: 0 10px;

        .icon-phone{
          width: 19px;
          height: 20px;
        }

        .icon-mail{
          width: 19px;
          height: 15px;
        }

        .icon-faq{
          width: 19px;
          height: 19px;
        }
      }
    }
  }

  &.paiement{

    .reservation-center {
      margin: auto;
      padding-right: 5%;
      .reservation-left-title {
        margin-bottom: 30px;
      }
    }

    .form-group{
      flex-wrap: wrap;
      margin: 10px auto;

      @media screen and (max-width: 884px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 10px 0;
      }

      .form-check-container-radio{
        margin: 20px 40px;

        @media screen and (max-width: 884px) {
          margin: 20px 0;
        }

        .form-check-label{
          padding-left: 40px;

          img:last-child{
            margin-left: 10px;
          }

        }
      }
    }
  }

  &.confirmation{
    padding: 0 (90px/1440px*100%) 110px (90px/1440px*100%);

    @media screen and (max-width: 884px) {
      padding: 60px (90px/1440px*100%);
    }

    .reservation-left{
      align-items: center;
      margin: 0;

      @media screen and (max-width: 884px) {
        align-items: flex-start;
      }

      .h2-title{
        max-width: 500px;
        text-align: center;

        @media screen and (max-width: 884px) {
          text-align: left;
        }
      }

      &-subtitle{
        font-family: $font-open;
        font-size: 18px;
        font-weight: 300;
        text-align: center;
        font-style: normal;
        line-height: 1.7;
        letter-spacing: 0.5px;
        margin: 25px 0 30px 0;
        color: $dark-grey;

        @media screen and (max-width: 884px) {
          text-align: left;
        }
      }

      .btn-container{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: -10px 0;

        .btn-trans, .btn-full{
          margin: 10px 10px;
          padding: 0;
          width: 218px;
        }
        .btn-full {
          padding: 0 10px;
          width: auto;
          min-width: 218px;
        }

        &:last-child{
          margin: 20px 0 -10px 0;
        }
      }
    }
  }

  .reservation-item-container{
    margin-top: 40px;
    width: 100%;

    .btn-container{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @media screen and (min-width: 884px) {
        //justify-content: flex-end;
      }
      max-width: 900px;

      .btn-trans-purple{
        padding: 0 80px;
      }

      .btn-blue {
        padding: 0 75px;
        background-color: $flat-blue;

        &:hover {
          color: $flat-blue;
          background-color: transparent;
          border: 2px solid $flat-blue;
        }
      }

      .date-picker-container {
        flex: 1 0 100%;
        margin-top: 20px;
        margin-bottom: 20px;

        #date-picker-disponibilite {

          @media screen and (min-width: 884px) {
            max-width: 70%;
          }
        }

        .datepicker {
          margin-right: 0;
          width: 100%;

          &--content {
            .datepicker--cell {
              margin: 10px;
              min-height: 32px;
              color: $whiteColor;

              @media screen and (min-width: 884px) {
                margin: 10px 20px;
              }

              &.-disabled- {
                background-color: $light-grey;
                color: $flat-blue;
              }
            }

            .datepicker--cell-day {
              width: calc(100% / 7 - 20px);

              @media screen and (min-width: 884px) {
                width: calc(100% / 7 - 40px);
              }
            }
          }
        }

        #legend-disponibilite {
          margin-top: 6px;
          margin-bottom: 24px;
        }

        #legend-disponibilite ul {

          @media screen and (min-width: 884px) {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
          li {
            padding-left: 18px;
            position: relative;
            margin-right: 10px;

            &:after {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              width: 12px;
              height: 12px;
              border-radius: 50%;
            }

            &.unavailable:after {
              background-color: $light-grey;
            }

            &.available:after {
              background-color: $flat-blue;
            }

            &.promotion:after {
              background-color: $yellow;
            }
          }
        }

        /*.selected-date {
          color: $medium-grey;
          font-size: 18px;
          font-weight: 600;

          input#date-picker {
            color: $purple;
            font-size: 18px;
            font-weight: 600;
          }
        }*/
      }
    }

    .update-reservation {
      @media screen and (max-width: 884px) {
        margin: 0 auto 60px;
      }
    }
  }
#display-option{
  padding-top: 15px;
}
  .reservation-item{
    padding-bottom: 60px;

    &-text{
      margin-top: 15px;
      font-family: $font-open;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      color: black;
    }

    .grid-container{
      margin: 30px 0 0 0;

      .grid{
        margin: 0;
      }

      .form-group-resa {
        .quantity-button {
          span {
            margin-top: -6px;
          }
        }
      }
    }

    .grid-item{
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    }

    .form-group{
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
    }
    #option-scintillant {
      @media screen and (max-width: 884px) {
        max-width: 100%;

        .btn-trans {
          max-width: 100%;
          font-size: 13px;
        }
      }

      .btn-trans {
        margin-top: 0;
        //margin-bottom: 60px;
      }
    }

    &.promo-item{
      padding-bottom: 30px;
      @media screen and (max-width: 884px) {
        padding-bottom: 0;
      }
      .form-group {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        input.form-control{
          max-width: 300px;
        }

        label{
          flex: 1 1 auto;
        }
      }
    }

    .parsley-error-text,
    .alert-danger {
      color: #d35b5b;
      & + .input-validation {
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        flex-shrink: 0;
        top: calc(50% - 10px - 8px);
        right: -35px;
        background-color: #d35b5b;
        transform: rotate(45deg);
      }
      & + .input-validation:before {
        content: "";
        position: absolute;
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 2px;
        height: 12px;
        background-color: white;
      }
      & + .input-validation:after {
        content: "";
        position: absolute;
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: 0;
        width: 12px;
        height: 2px;
        background-color: white;
      }
    }


    &-element{
      margin-top: 30px;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      text-transform: none;
      letter-spacing: 1px;
      color: $medium-grey;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      /*background-color: white;*/
      width: 100%;

      &.select-custom{
        max-width: 900px;
      }

      /* &.reservation-sub-type{
         display: none;
       }*/

      &:not(select){
        padding: 0 20px;
      }

      &#date-picker{
        height: 70px;
        padding: 0 86px 0 20px;
        max-width: 320px;

        background-image: url('../../front/images/calendar.svg');
        background-position: calc(100% - 20px) 50%;
        background-size: 46px 46px;
        background-repeat: no-repeat;

        &:after{
          content: "";
          display: block;
        }
      }

      &.quantity{
        display: flex;
        align-items: center;
        flex-direction: row;
        /*justify-content: flex-end;*/
        max-width: 900px;

        .quantity-inner{
          display: flex;
          /*align-items: center;*/
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;
          margin: 0 -65px;
          width: 100%;
          flex: 1;

          @media screen and (max-width: 1110px) {
            flex-direction: column;
            align-items: flex-end;
            margin: 0;
          }
        }

        .form-group-resa{
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: flex-end;
          padding: 0 65px;
          height: auto;
          min-height: 70px;
          flex: 1 1 50%;

          &.hidden{
            display: none;
          }
          @media screen and (max-width: 1110px) {
            padding: 0;
            flex: 1 1 auto;
          }
        }

        .quantity-number{
          font-family: $font-open;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          color: $grey;
          margin: 0 20px;
          max-width: 30px;
          text-align: center;

          &:disabled{
            background: #fff;
          }
        }

        .quantity-label{
          position: relative;
          margin-right: 30px;

          .tooltip{
            position: absolute;
            top: 0;
            right: -5px;
            transform: translate(100%, -50%);
            width: 15px;
            height: 15px;
            background-image: url('../../front/images/info.svg');
            background-position: center;
            background-color: transparent;
            background-size: 15px 15px;
          }
        }

        .quantity-button{
          user-select: none;
          cursor: pointer;
          flex: 0 0 auto;
          position: relative;
          width: 35px;
          height: 35px;

          .icon{
            width: 35px;
            height: 35px;
            fill: $flat-blue;
            position: absolute;
            z-index: -1;
          }
        }
      }


    }

    &.results-item{
      margin-top: 30px;
      max-width: 900px;

      .reservation-item-text{
        font-weight: bold;
        color: $dark-grey;
        margin-top: 30px;

        span{
          color: $purple;
        }
      }

      .reservation-item-empty{
        margin-top: 30px;
        padding: 25px 20px;
        color: white;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        letter-spacing: 1px;
        background-color: $purple;
        margin-bottom: 40px;
      }

      .results-item-element{
        display: flex;
        flex-direction: column;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        letter-spacing: 1px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
        margin-top: 20px;

        a{
          text-decoration: underline;
        }

        &.show{
          .results-item-element-heading-hidden{
            display: flex;
          }
          .infos-toggle{
            a.more-info{
              display: none;
            }
            a.less-info{
              display: flex;
            }
          }
        }

        .infos-toggle a{
          text-align: right;

          &.more-info{
            display: flex;

            &:after {
              transform: rotate(90deg);
            }
          }
          &.less-info{
            display: none;

            &:after {
              transform: rotate(-90deg);
            }
          }

          flex-direction: row;
          align-items: center;
          &:after {
            content: "";
            background-image: url('../../front/images/arrow-white.svg');
            background-position: center;
            background-color: transparent;
            background-size: 8px 12px;
            display: block;
            margin: 0 15px;
            width: 8px;
            height: 12px;

          }
        }

        &-heading{
          background: $flat-blue url('../../front/images/calendar-white.svg') 20px center/18px auto no-repeat;
          color: white;
          height: 70px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px 0 50px;
          cursor: pointer;
        }

        &-heading-hidden{
          background-color: $flat-blue;
          color: white;
          padding: 0 20px 20px 20px;
          line-height: 1.8;
          display: none;
        }


        &-radio{
          padding: 20px 95px 45px 20px;
          background-color: white;
          color: $dark-grey;
        }

        .form-group.form-check{
          margin: 30px -60px -20px -60px;
          font-weight: bold;
          font-family: $font-stack-common;
          justify-content: flex-start;
          flex-wrap: wrap;

          @media screen and (max-width: 359px) {
            margin: 30px 0 -20px 0;
          }

          .form-check-container-radio{
            margin: 0 60px 20px 60px;

            @media screen and (max-width: 359px) {
              margin: 0 0 20px 0;
            }

            input:checked + .form-check-checkmark{
              background-color: $purple;
            }

            .icon-container {
              position: absolute;
              right: -30px;
              width: 20px;
              height: 20px;

              &:before {
                background-color: $whiteColor;
                color: $blackColor;
                min-width: 180px;
                text-align: left;
                padding: 20px;
                font-family: $font-open;
                line-height: 20px;
                font-size: 13px;
                box-shadow: 1px 2px 10px 0px grey;

                @media screen and (min-width: 1100px) {
                  min-width: 240px;
                }
              }

              &:after {
                border-top-color: $whiteColor;
              }

              .icon {
                width: 13px;
                height: 13px;
              }
            }
          }

          label{
            padding-left: 40px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .item-price{
              color: $purple;
            }

            .item-promo{
              background-color: $crazy-blue;
              color: white;
              padding: 4px 10px;
              font-size: 12px;
              letter-spacing: 1px;
              position: absolute;
              top: 0;
              right: -5px;
              transform: translateX(100%);
            }
          }

        }
      }
      .btn-container {
        margin: 30px 0 -20px;
        flex-wrap: wrap;
        .form-group {
          margin-right: 30px;
          flex: 1 1 40%;
        }
        @media screen and (min-width: 1100px) {
          flex-wrap: nowrap;

        }
        > *{
          margin: 0 30px 20px;

          @media screen and (max-width: 884px) {
            margin: 0 0 20px 0;
          }

        }
        .form-group:last-child,
        .select-cruise {
          margin-right: 0;
        }
        p {
          margin: auto;
          margin-bottom: 20px;
          flex: 1 1 100%;
          @media screen and (min-width: 1100px) {
            flex: 1 1 auto;

          }
        }
      }
    }

    .btn-container{
      margin: 30px -50px -20px -50px;
      justify-content: flex-end;
      flex-wrap: wrap;

      @media screen and (max-width: 884px) {
        margin: 30px 0 -20px 0;
      }

      > *{
        margin: 0 50px 20px 50px;

        @media screen and (max-width: 884px) {
          margin: 0 0 20px 0;
        }

      }

      > p{
        font-family: $font-open;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.7;
        color: black;
        flex: 1 1 35%;

        @media screen and (max-width: 884px) {
          flex: 1 1 auto;
        }

        a{
          font-family: $font-lato;
          font-size: 13px;
          font-weight: bold;
          font-style: normal;
          letter-spacing: 0.5px;
          color: $flat-blue;
          text-transform: uppercase;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .success-firstname {
      color: #FFF;
      margin: 20px 0 0 0;
      background: #86d788;
      padding: 6px;
    }
  }
}