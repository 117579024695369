.rendez-vous{
  //padding-top: 80px;
  overflow: hidden;
  padding: 80px 90px;
  background-color: $dark-grey;

  @media screen and (max-width: 990px) {
    padding: 80px 45px;
  }

  @media screen and (max-width: 465px) {
    padding: 80px 14px;
  }

  &-title{
    /*margin-left: 439px / 1440px * 100%;*/

    @media screen and (max-width: 767px) {
      margin: 0 20px;
      text-align: center;
    }
  }

  &-subtitle{
    color: white;
    /*margin-left: 543px / 1440px * 100%;*/

    @media screen and (max-width: 767px) {
      margin: 15px 20px 0 20px;
      text-align: center;
    }
  }

  .button-promo-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  a.button-promo{
    margin: 50px 0 80px 0;

    @media screen and (max-width: 767px) {
      margin: 50px 0 60px 0;
    }

    @media screen and (max-width: 359px) {
      padding: 0 20px;
    }

  }

  &:not(.evenements-pros) {
    .grid-container {
      @media screen and (max-width: 901px) {
        display: none;
      }
    }
  }
}

.slider-rdv-container {
  width: 100%;
  max-height: 800px;
  position: relative;
  left: 0;
  top: 0;
  margin-top: 50px;
  //padding-bottom: 61px;
  //background-color: white;

  /*@media screen and (min-width: 902px) {
    display: none;
  }*/

  @media screen and (max-width: 465px) {
    margin: 26px 0 0!important;
    padding: 0!important;
  }

  .slider-arrow {
    display: none;
    position: absolute;
    top: -100px;
    right: 0;
    background: transparent;
    padding: 15px 15px 11px 15px;
    flex-direction: row;
    align-items: center;
    z-index: 35;

    &.active {
      display: flex;

      @media screen and (max-width: 767px) {
        width: calc(100% + 60px + 7px);
        right: -33px;
        top: 55%;
        transform: translateY(-50%);
        justify-content: space-between;
      }
    }

    p {
      font-family: $font-open;
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: 2.5px;
      margin: 0 15px;
    }

    .slick-arrow {
      height: 40px;
      width: 40px;
      padding: 8px;
      border: solid 1px #fff;
      border-radius: 50%;
      margin: 0 5px;

      @media screen and (max-width: 767px) {
        background: #000;
        box-shadow: 0px 0px 20px 3px #000;
      }
      /*&.slick-prev:not(:first-of-type), &.slick-next:not(:first-of-type) {
        display: none;
        visibility: hidden;
      }*/
    }

    .slick-prev .icon-arrow {
      transform: rotate(90deg);
    }

    .slick-next .icon-arrow {
      transform: rotate(-90deg);
    }

    .icon-arrow {
      width: 22px;
      height: 22px;
      fill: $dark-grey;
      background-image: url('../../front/images/arrow-down.svg');
      background-position: center;
      background-color: transparent;
      background-size: 22px 22px;
    }
  }

  .slick-list {

    @media screen and (max-width: 767px) {
      overflow: visible;
    }
  }

  .slick-slide {
    padding: 0 10px;
    @media screen and (max-width: 767px) {
      transition: ease-in-out .3s;

      &:not(.slick-current) {
        opacity: 0.3;
      }
    }
  }

  .grid-item {
    float: none!important;
    width: 100%;
    margin-bottom: 0;
  }
}

.grid-container {
  margin: 50px (90px / 1440px * 100%) 0 (90px / 1440px * 100%);

  @media screen and (max-width: 767px) {
    margin: 26px (90px / 1440px * 100%) 0 (90px / 1440px * 100%);
  }

  @media screen and (max-width: 465px) {
    margin: 26px 20px 0 20px;
  }

  text-align: center;

  .grid-header{
    max-width: 1259px;
    margin: 0 auto;
  }

  .grid{
    margin: 0 auto;
    min-height: 600px;

    .grid-item,
    .grid-sizer{
      width: 413px;

      @media screen and (max-width: 1439px) {
        width: 390px;
      }

      @media screen and (max-width: 465px) {
        width: 320px;
      }

      @media screen and (max-width: 359px) {
        width: 100%;
      }

    }
  }
}

.grid-item{
  float: left;
  background-color: white;
  margin-bottom: 10px;

  @media screen and (max-width: 465px) {
    margin-bottom: 20px;
  }

  .item-thumbnail-container{
    position: relative;
    width: 100%;
    height: 302px;
    @media screen and (max-width: 465px) {
      height: 257px;
    }
    overflow: hidden;
  }

  img.item-thumbnail{
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    min-width: 100%;
    min-height: 100%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .item-info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 30px;
    text-align: left;
    position: relative;

    &-title{
      font-size: 31px;
      line-height: 32px;
      font-weight: 300;
      font-style: normal;
      color: $dark-grey;
      text-transform: uppercase;
      //padding-right: 110px;
      max-width: calc(100% - 125px);
      &.xlarge {
        padding-right: 0;
        max-width: 100%;
      }
    }

    .item-date{
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      color: $dark-grey;
      margin-top: 8px;
      text-transform: uppercase;
    }

    .item-text{
      font-family: $font-lato;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      margin-top: 14px;
      color: $dark-grey;
      line-height: 1.5;
      letter-spacing: 0.5px;
      max-width: 100%;
    }

    .btn-container{
      //margin-top: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;

      a.btn-full, a.btn-trans{
        margin-top: 20px;
      }
    }

    a.btn-full{
      margin-top: 36px;
      padding: 0 45px;

      @media screen and (max-width: 465px) {
        padding: 0 25px;
      }

      @media screen and (max-width: 359px) {
        padding: 0 13px;
      }
    }

    a.btn-trans{
      margin-top: 36px;
      padding: 0 35px;

      @media screen and (max-width: 465px) {
        padding: 0 20px;
      }

      @media screen and (max-width: 359px) {
        padding: 0 10px;
      }
    }

    .icon-container{
      width: 56px;
      height: 56px;
      position: absolute;
      top: 0;
      right: 10px;
      transform: translateY(-50%);
    }

    .icon-calendar{
      width: 30px;
      height: 30px;
      fill: white;
    }

  }

  .item-promo{
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 4px;
    color: white;
    background-color: $crazy-blue;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-50%);
    padding: 8px;
  }

  .item-quantity{
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 -20px 0;

    > * {
      margin: 20px 0;
    }

    > div:first-child{
      display: flex;
      align-items: center;
      flex-direction: row;
      flex: 1;
    }

    .icon-container{
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      transform: none;
      width: 35px;
      height: 35px;
      font-family: $font-open;
      font-size: 26px;
      font-weight: normal;
      font-style: normal;
      color: white;
      user-select: none;
      cursor: pointer;
      flex: 0 0 auto;
    }

    .quantity-number{
      font-family: $font-open;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      color: $grey;
      margin: 0 20px;
      max-width: 30px;
      text-align: center;
    }

    .item-price{
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      margin: 0;
    }
  }

  .item-price{
    position: absolute;
    top: 30px;
    right: 15px;
    display: flex;
    flex-direction: row;
    text-align: right;
    justify-content: flex-start;
    align-items: flex-end;

    &.item-discount{
      font-family: $font-open;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      color: $purple;
      max-width: 120px;
      margin-top: 10px;
    }

    &, &.item-discount{
      @media screen and (max-width: 767px) {
        position: relative;
        margin-top: 10px;
        text-align: left;
        max-width: 100%;
        top: 0;
        right: 0;
      }
    }

    &-text{
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      letter-spacing: 1.5px;
      color: $dark-grey;
      margin-right: 30px;
      text-transform: uppercase;
    }

    &-amount{
      font-family: $font-open;
      font-size: 27px;
      font-weight: bold;
      font-style: normal;
      color: $purple;
      .per_person{
        font-size:12px
      }
    }
  }

}
