.slider-section-rich-gab{
  margin-bottom: -57px;

  @media screen and (max-width: 767px) {
    margin-bottom: 0;
  }
}

.reservation-devis{
  background-color: $dark-grey;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 9px 0;

  &.sticky{
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
  }

  &.bottom{
    position: relative;
    order: 999;
    margin-top: 40px;
  }

  &-title{
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 4px;
    color: $light-grey;
    text-transform: uppercase;
    text-align: center;
    margin: 9px 20px;

    @media screen and (max-width: 991px) {
      font-size: 20px;
      margin: 0 10px;
    }

    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }

  &-buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 9px 0;

    #reservation-devis-buttons-tel-mobile{
      display: block;
      @media screen and (min-width: 768px)
      {
        display: none;
      }
    }

    #reservation-devis-buttons-tel{
      display: none;
      @media screen and (min-width: 768px)
      {
        display: block;
      }
      span:first-child{
        display: inline;
      }
      span:last-child{
        display: none;
        font-size: 18px;
        line-height: 18px;
      }
      &.active{
        span:first-child{
          display: none;
        }
        span:last-child{
          display: inline;
        }
      }
    }

    a.btn-full{
      padding: 0 67px;
      margin: 0 20px;
      height: 40px;
      line-height: 40px;

      @media screen and (max-width: 991px) {
        padding: 0 40px;
        margin: 0 10px;
      }

      @media screen and (max-width: 767px) {
        padding: 0 25px;
        margin: 0 10px;
      }

      @media screen and (max-width: 359px) {
        padding: 0 15px;
      }

      &:hover{
        background-color: white;
        border: solid 2px white;
      }
    }
  }
}

.product-content{
  padding: 0 (90px / 1440px * 100%);

  > .experience-item:first-child{
    padding-top: 60px;
  }

  .h2-title{
    padding: 5px 0 30px 0;
    text-align: center;
  }

  .img-container {
    width: 100%;
    max-height: 600px;

    @media screen and (max-width: 767px) {
      max-height: 300px;
    }

    img {
      display: block;
      margin: auto;
      max-height: 600px;
      max-width: 100%;
    }

  }

  .slider-sync-for{
    .slide-img-container{
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 600px;

      @media screen and (max-width: 767px) {
        height: 300px;
      }

      img{
        position: absolute;
        width: auto !important;
        max-height: 100%;
        max-width: unset;
        height: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .slider-sync{
    .slick-slide > div{
      position: relative;
      overflow: hidden;
      padding-top: 9 / 16 * 100%;

      img{
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .img-caption{
    width: 100%;
    font-family: $font-open;
    font-size: 16px;
    font-weight: normal;
    font-style: italic;
    color: black;
    text-align: center;
    padding-top: 15px;
  }

  &-text{
    padding: 40px (270px / 1440px * 100%) 0 (270px / 1440px * 100%);

    @media screen and (max-width: 767px) {
      padding: 40px (90px / 1440px * 100%) 0 (90px / 1440px * 100%);
    }
  }

  &-image{
    padding-top: 40px;

    .product-content-subtitle{
      padding: 10px 0 45px 0;
      text-align: center;
    }
  }

  &-slider{
    padding-top: 40px;
    .product-content-subtitle{
      padding: 10px 0 45px 0;
      text-align: center;
    }
  }

  &-title{
    font-family: $font-stack-common;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 4px;
    color: $flat-blue;
    text-transform: uppercase;
    margin: 10px 0 20px 0;
  }

  &-subtitle {
    font-family: $font-lato;
    font-size: 22px;
    font-weight: 300;
    font-style: normal;
    color: $dark-blue;
    margin-bottom: 17px;
  }

  h4{
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    color: $dark-grey;
    font-family: $font-stack-common;
  }

  &-content{
    padding-bottom: 40px;

    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: 0.5px;
    color: black;

    a{
      color: $flat-blue;

      &:hover{
        text-decoration: underline;
      }
    }

    a.btn-underline{
      color: black;
      &:hover{
        text-decoration: none;
        color: $purple;
      }
    }

    p{
      padding: 10px 0;
    }

    b{
      font-weight: bold;
    }

    i{
      font-style: italic;
    }

    ul{
      padding: 10px 0;
      margin-left: 19px;
      font-family: $font-open;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      color: $dark-blue;
      list-style-type: circle;
      line-height: 1.8;
    }
  }
}

.slider-sync{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .slick-list{
    width: 100%;
    margin: 25px 0 40px 0;

    @media screen and (max-width: 767px) {
      margin: 20px 0;
      visibility: hidden;
    }

    .slick-slide{
      margin: 0 5px;
      cursor: pointer;
    }
  }

  .slick-prev,
  .slick-next
  {
    font-size: 0;
    line-height: 0;

    position: absolute;

    display: block;

    width: 40px;
    height: 40px;
    padding: 0;

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus
  {
    color: transparent;
    outline: none;
    background: transparent;
  }
  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before
  {
    opacity: 1;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before
  {
    opacity: .25;
  }

  .slick-prev:before,
  .slick-next:before
  {
    font-family: 'slick';
    font-size: 40px;
    line-height: 1;

    opacity: .75;
    color: $flat-blue;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-prev
  {
    top: 50%;
    left:  -(45px / 1260px * 100%);
    transform: translate(-50%,-50%);

    @media screen and (max-width: 767px) {
      left: 0;
      transform: translate(0,-50%);
    }
  }
  .slick-prev:before
  {
    content: '←';
  }
  .slick-next
  {
    top: 50%;
    right: -(45px / 1260px * 100%);
    transform: translate(50%,-50%);

    @media screen and (max-width: 767px) {
      right: 0;
      transform: translate(0,-50%);
    }
  }
  .slick-next:before
  {
    content: '→';
  }
}

.youtube-embed{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;

  .product-content-subtitle{
    padding: 10px 0 45px 0;
  }

  .video-outer{
    position: relative;
    width: 100%;

    &:before{
      content: "";
      padding-top: (315px / 560px * 100%);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    iframe{
      position: absolute;
      top: 0;
      left: 0;
      border: 0;
      height: 100%;
      width: 100%;
    }
  }

  &-caption{
    font-family: $font-open;
    align-self: flex-start;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    color: $dark-grey;
    padding: 20px 0;
    display: block;
  }
}