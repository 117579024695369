.gab-error {

  padding: 80px;

  .upper {
    margin-top: 15px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    color: black;
  }
  .btn-trans {
    display: inline-block;
    margin-top: 120px;
  }
}