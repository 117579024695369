  /*-- RESET ALL --*/
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, font, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td, select, option {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-weight:inherit;
    font-style:inherit;
    font-size:100%;
    font-family:inherit;
    vertical-align:baseline;
  }

  * {
    box-sizing: border-box;
  }

  input, textarea{
    box-sizing: border-box;
    border: 0;
  }

  html{
    scroll-behavior: smooth;
  }

  body {
    display: block;
    width: 100%;
    min-height: 100vh;
    background-color: $backgroundLight;
    font-family: $font-stack-common;
    font-size: $base-font-size;
    font-weight: 300;
    line-height: $line-height;
    overflow-x: hidden;
  }

  b, strong {
    font-weight: 600;
  }

  ol, ul {
    list-style: none;
  }

  em, i {
    font-style: italic;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  sup{
    position: relative;
    bottom: 1px;
    font-size: .65em;
    line-height: .65em;
    vertical-align: super;
    text-decoration: none;
  }

  .hidden,
  .print-only {
    display: none;
  }

  .text-nowrap{
    white-space: nowrap;
  }
  
  //placeholder clearfix
  %clearfix {
    &:after {
    content: "";
    display: table;
    clear: both;
    }
  }

  //a11y = accessibility
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
  }
  
  .sr-only-focusable {
    &:active,
    &:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
    clip-path: none;
    }
  }

  #page-content{
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    @media screen and (min-width: 991px) {
      padding-top: 0;
    }
  }

  // Top banner message
  .message-banner {
    position: relative;

    &.floating {
      @media screen and (max-width: 767px) {
        position: absolute;
        top: 110px;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        background: #fff;
        z-index: 1000;
        padding: 16px;
        padding-right: 38px;
        box-shadow: 1px 2px 10px 0px grey;
      }

      .container-edito {
        @media screen and (max-width: 767px) {
          text-align: left;
          padding: 0;
        }
      }
    }

    .container-edito {
      color: #2D2D2D;
      font-family: $font-open;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.42px;
      line-height: 21px;
      text-align: center;
      padding: 10px;

      strong {
        color: $purple;
      }

      a {
        display: inline-flex;
        font-family: Lato,sans-serif;
        font-weight: 400;
        font-style: normal;
        color: #94bec5;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .close-banner {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      padding: 20px;

      @media screen and (max-width: 767px) {
        float: none;
        padding: 0;
        position: absolute;
        top: 10px;
        right: 10px;
        transform: none;
      }
    }

    &:not(.floating) {
      width: 100%;
      background: #fff;
      padding: 28px;

      .container-edito {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.42px;
        text-align: left;
        padding: 0;
      }
    }
  }

  //Popup

  .popup_content{
    display: block;
    background-color: white;
    padding: 20px;

    section:not(.form-section) p,
    section:not(.form-section) h4{
      padding: 10px 0;
    }

    p a:not(.btn-underline){
      color: $flat-blue;
      text-decoration: none;

      &:hover{
        text-decoration: underline;
      }
    }

    h4 {
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 4px;
      color: $flat-blue;
      text-transform: uppercase;
    }

    ul {
      margin: 0 0 0 36px;
      padding: 10px 0;
      font-family: $font-open;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      color: $dark-blue;
      list-style-type: circle;
      line-height: 1.8;
    }

  }

  .popup_close{
    position: absolute;
    top: 0;
    right: 0;
  }

  //Back to top

  #back-to-top{
    z-index: 1000;
    position: fixed;
    display: none;
    right: 20px;
    bottom: 100px;
    height: 60px;
    width: 60px;

    @media screen and (max-width: 767px) {
      height: 40px;
      width: 40px;
      right: 10px;
      bottom: 120px;
    }

    .icon-arrow{
      fill: white;
      height: 25px;
      width: 25px;
      transform: rotate(-90deg);

      @media screen and (max-width: 767px) {
        height: 15px;
        width: 15px;
      }
    }
  }

  //JS List

  .js-list{
    width: 100%;
  }

  @media screen and (max-width: 559px) {
    .iti.iti--container {
      z-index: 1000000
    }
  }
