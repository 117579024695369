.liste-cards{
  padding: 60px 0 0 0;

  &-subtitle{
    margin: 30px 0;
  }

  .h2-title {
    &:not(.rendez-vous-title) {
    text-align: center;
    }
  }

  .grid-container{
    margin-top: 10px;
    margin-bottom: 60px;
  }


  @media screen and (min-width: 1024px) {
    .grid-header {
      form p.liste-cards-subtitle:first-child {
        margin: 0 27.77777778% 60px;
      }
    }
  }

  .grid-item{
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  }

  &-selection{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 40px;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    color: $medium-grey;
    letter-spacing: 1px;

    @media screen and (max-width: 767px) {
      display: none;
    }

    > div{
      padding: 25px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      margin: 20px 10px 0 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .form-check-container-radio input:checked + .form-check-checkmark{
      background-color: $purple;
    }
    .form-check-container-radio input:checked ~ .form-check-label{
      color: $purple;
    }
  }

  select.select-custom{
    margin-top: 60px;
    width: 100%;
    display: none;

    @media screen and (max-width: 767px) {
      display: block;
    }
  }
}