.slider-section{
  position: relative;
  top: -57px;
  left: 0;
  z-index: 30;
  height: 763px;
  overflow: hidden;

  @media screen and (max-width: 991px) {
    height: 556px;
    top: 0;
    left: 0;
  }

  @media screen and (max-width: 767px) {
    height: 490px;
  }

  .video-background{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 45;
    overflow: hidden;

    .video-foreground, .video-foreground iframe{
      position: absolute;
      pointer-events: none;
      z-index: 45;
      top: 0;
      left: 0;
    }

    .video-foreground{
      height: 150%;
      width: 150%;
      top: -25%;
      left: -25%;

      iframe{
        border: 0;
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: 991px) {
      display: none;
    }

    & ~ .hero{
      &:after {
        visibility: visible;
      }
    }
  }

  .hero-img{
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    min-height: 100%;
    min-width: 100%;
    z-index: 40;

    @media screen and (max-width: 991px) {
      display: none;
    }

    &-mobile {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      min-height: 100%;
      min-width: 100%;
      z-index: 40;

      @media screen and (min-width: 991px) {
        display: none;
      }
    }
  }

  .hero {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 763px;
    z-index: 50;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));

    @media screen and (max-width: 991px) {
      height: 556px;
    }

    @media screen and (max-width: 767px) {
      height: 490px;
    }

    &:after{
      visibility: hidden;
      content: "";
      display: block;
      width: 2px;
      height: 42px;
      background-color: white;
      margin: 0 auto;
    }

    &-tripadvisor{
      position: absolute;
      left: 50%;
      bottom: 70px;
      transform: translateX(200px);
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    &-text{
      display: flex;
      flex-direction: column-reverse;
      max-width: 590px;

      &-title{
        color: white;
        text-align: center;
        font-size: 92px;
        font-family: $font-second;
        font-weight: normal;
        font-style: normal;

        @media screen and (max-width: 991px) {
          font-size: 45px;
        }
      }

      &-subtile{
        margin-top: 20px;
        font-size: 26px;
        font-weight: 500;
        font-style: italic;
        color: white;
        text-align: center;

        @media screen and (max-width: 991px) {
          font-size: 20px;
        }
      }
    }

    .button-book-container{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a.button-book{
      pointer-events: auto;
      margin: 30px 0 0 0;
      padding: 0 70px;
    }
  }
}

.experience-section
{
  width: 100%;
  padding: 18px 0 80px 0;

  @media screen and (max-width: 767px) {
    padding: 50px 0 0 0;
  }

  &-title{
    margin-left: 223px / 1440px * 100%;

    @media screen and (max-width: 767px) {
      margin: 0 20px;
    }

  }

  &-subtitle{
    margin-left: 331px / 1440px * 100%;

    @media screen and (max-width: 767px) {
      margin: 15px 20px;
    }
  }

  .experience-pictures{
    margin-top: 50px;
    width: 100%;
    display: flex;

    @media screen and (max-width: 767px) {
      margin-top: 20px;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    .experience-left, .experience-right{
      width: 50%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .experience-item-container-1, .experience-item-container-2{
      margin-right: 5px;
    }

    .experience-item-container-2{
      margin-left: 195px / 715px * 100%;
    }

    .experience-item-container-3{
      margin-right: 195px / 715px * 100%;
    }

    .experience-item-container-3, .experience-item-container-4{
      margin-left: 5px;
    }

    .experience-item-container {
      position: relative;
      margin-bottom: 10px;
      overflow: hidden;

      @media screen and (max-width: 767px) {
        margin: 0 0 10px 0;
      }

      .experience-item-img{
        position: absolute;
        width: 100%;
        height: auto;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        transition: all .4s;

        @media screen and (max-width: 430px) {
          min-height: 100%;
          min-width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }

      &:hover {

        .experience-item-img {
          filter: blur(8px);
          transform: translateY(-50%) scale(1.1);

          @media screen and (max-width: 430px) {
            transform: translate(-50%,-50%) scale(1.1);
          }
        }

        .experience-item-book {
          display: block;
        }

        .experience-item-title{
          .title{
            display: none;
          }
          .name{
            display: block;
          }
        }
      }

      .experience-item-inside{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(10, 10, 10, 0.4);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .experience-item-title{
        .title{
          display: block;
          font-size: 30px;
          font-weight: 500;
          font-style: italic;
          text-transform: none;
          max-width: 60%;
          margin-left: auto;
          margin-right: auto;
          letter-spacing: normal;
          line-height: normal;
        }
        .name{
          display: none;
        }

        font-family: $font-stack-common;
        text-transform: uppercase;
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        letter-spacing: 2px;
        color: white;
        text-align: center;
        margin: 0;
        width: 100%;

        @media screen and (max-width: 767px) {
          padding: 0;
        }
      }

      .experience-item-book{
        display: none;
        font-size: 15px;
        font-weight: bold;
        font-style: normal;
        letter-spacing: 5px;
        color: white;
        text-align: center;
        padding-bottom: 6px;
        margin-top: 30px;
        border-bottom: 3px solid $purple;
        text-transform: uppercase;
        line-height: normal;
      }

    }

    .experience-item-1{
      padding-top: 381px / 716px * 100%;

      @media screen and (max-width: 767px) {
        padding-top: 0;
        height: 280px;
      }
    }

    .experience-item-2{
      padding-top: 320px / 520px * 100%;

      @media screen and (max-width: 767px) {
        padding-top: 0;
        height: 280px;
      }
    }

    .experience-item-3{
      padding-top: 284px / 520px * 100%;

      @media screen and (max-width: 767px) {
        padding-top: 0;
        height: 280px;
      }
    }

    .experience-item-4{
      padding-top: 333px / 715px * 100%;

      @media screen and (max-width: 767px) {
        padding-top: 0;
        height: 280px;
      }
    }

  }
}

.customer-review-container{
  margin: 80px (90px / 1440px * 100%);
  overflow: hidden;

  @media screen and (max-width: 767px) {
    margin: 80px 20px 60px 20px;
  }
}

.customer-review {
  margin-bottom: -20px;
  background-color: white;
  display: flex;
  flex-direction: column;

  &-title{
    margin-left: 395px / 1440px * 100%;
    order: -1;

    @media screen and (max-width: 767px) {
      margin: 0;
    }
  }

  &-subtitle{
    margin-left: 471px / 1440px * 100%;

    @media screen and (max-width: 767px) {
      margin: 0;
    }
  }

  &-embed {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 50px -20px -50px -20px;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      flex-direction: column;
    }

    > div{
      display: flex;
      flex-direction: column;
      flex: 1 1 0;
      align-items: center;
      justify-content: flex-start;
      margin: 0 20px 50px 20px;
    }

    #review-container{
      min-width: 468px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        width: 50%;
        /*flex-direction: row;*/
      }
      @media screen and (max-width: 540px) {
        min-width: auto;
        width: 100%;
        flex: 1 1 100%;
      }

      > div {
        display: block;
        width: 100%;

        .romw .romw-source-logo img {
          display: table;
          width: 25px;
          height: 25px;
          position: relative;
          right: 80px;
        }

        .romw .romw-text {
          display: block;
          width: 85%;
        }
      }
    }
  }
}

.TA_selfserveprop.mobile{
  display: flex;
  @media screen and (min-width: 541px) {
    display: none;
  }
}

.TA_selfserveprop.desktop{
  display: flex;
  padding-bottom: 45px;
  
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 50%;
    flex: 1 1 50%;
  }
  @media screen and (max-width: 540px) {
    display: none;
  }
}

.TA_certificateOfExcellence{
  position: absolute;
  right: 0;
  bottom: 0;
}

#TA_selfserveprop920 #CDSWIDSSP .widSSPData,
#TA_selfserveprop367 #CDSWIDSSP .widSSPData {
  border: none !important;
  box-shadow: 0 0 20px 0 rgba(0,0,0,.25);
  padding: 20px;

  .widSSPBranding dt.widSSPTagline,
  .widSSPReviews .widSSPH11,
  .widSSPH11,
  .widSSPInformation .widSSPPopIdx b {
    font-size: 13px;
  }

  .widSSPOneReview .widSSPBullet li span {
    font-size: 14px;
  }
  .widSSPAll a,
  .widSSPOneReview .widSSPBullet li a,
  .widSSPTrvlRtng .widSSPOverall div {
    font-size: 12px;
  }
  .widSSPTrvlRtng .widSSPOverall div {
    margin-top: 3px;
  }
}

#TA_selfserveprop920 #CDSWIDSSP {
  overflow: visible;
}