/* ==========================================================================
   Components / Icons
   ========================================================================== */

  .icon-container {
    background-color: $flat-blue;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      display: block;
      fill: white;
    }
  }

.icon-cross{
  width: 15px;
  height: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
  background-image: url('../../front/images/cross.svg');
  background-size: 15px 15px;
  background-repeat: no-repeat;
}

/* LOADER PRINCIPAL */
.loading:before {
  content: '';
  background-color: rgba(0,0,0,.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
}
.loading:after {
  content: '';
  width: 60px;
  height: 40px;
  background-color: #9ec6cc;
  border-radius: 0 6px;
  margin: calc(50vh - 20px) auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
